import React, { FC, useEffect } from 'react';
import styles from './styles.module.css';
import { Layout } from 'antd';
import Loader from '../Loader';
import { ProjectHeader } from './ProjectHeader';
import Websites from './Websites';
import Assets from './Assets';
import { useGuestProjectState } from './GuestProjectProvider';
import { Props as SharingProps } from '../../share_project/types';
import { ShareProject } from '../../share_project';
import { WelcomeModal } from './WelcomeModal';

const { Content } = Layout;

type Props = {
  sharingProps?: SharingProps;
};

export const GuestProject: FC<Props> = ({ sharingProps }) => {
  const {
    loading,
    data,
    isNoLoginGuestExperienceEnabled,
    container
  } = useGuestProjectState();

  useEffect(() => {
    const html = document.getElementsByTagName('html')[0];
    if (html.style) html.style.backgroundColor = '#f5eeed';

    return () => {
      html.style.backgroundColor = 'unset';
    };
  }, []);

  if (loading) return <Loader />;
  if (!data?.currentUser?.userProject) return null;

  return (
    <div className={styles.container}>
      <Layout className={styles.layout}>
        <div className={styles.wrapper}>
          <ProjectHeader />
          <Content>
            {sharingProps && (
              <ShareProject
                {...sharingProps}
                isNoLoginGuestExperienceEnabled={
                  isNoLoginGuestExperienceEnabled
                }
                container={container}
              />
            )}
            <Websites />
            <Assets />
            {isNoLoginGuestExperienceEnabled && <WelcomeModal />}
          </Content>
        </div>
      </Layout>
    </div>
  );
};
