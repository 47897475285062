import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  NavLink,
  useLocation
} from 'react-router-dom';

import { Notifications } from './notifications';
import Organization from './organization';
import UserProfile from './profile';
import Menu from './menu';
import styles from './index.module.css';
import { ApolloProvider } from '@apollo/client';
import client from 'api/apollo/client';
import { ContainerProvider, ContainerType } from './containerContext';
import { Lock } from 'lucide-react';

const getContainer = () => document.getElementById('settings_container');

export enum UserKey {
  SCHEDULE = 'schedule',
  NEWSLETTER = 'newsletter',
  ENABLE_PROJECT_UPDATES = 'enableProjectUpdates',
  ENABLE_TASK_UPDATES = 'enableTaskUpdates',
  ENABLE_TASK_COMMENT_UPDATES = 'enableTaskCommentUpdates',
  ENABLE_UNASSIGNED_TASK_COMMENT_UPDATES = 'enableUnassignedTaskCommentUpdates',
  ENABLE_NEW_TASK_UPDATES = 'enableNewTaskUpdates',
  ENABLED_TASK_DONE_UPDATES = 'enableTaskDoneUpdates'
}

export type UserSettings = {
  schedule: number;
  newsletter: number;
  enableProjectUpdates: boolean;
  enableTaskUpdates: boolean;
  enableTaskCommentUpdates: boolean;
  enableUnassignedTaskCommentUpdates: boolean;
  enableNewTaskUpdates: boolean;
  enableTaskDoneUpdates: boolean;
  isGuest: boolean;
};

const Settings: React.FC<{
  organizations: any[];
  access_to: any;
  propHandler: { listen: () => void; destory: () => void };
  avatarUrl: string;
  authenticityToken: string;
  user: any;
}> = props => {
  const { access_to, propHandler, authenticityToken, user: userProp } = props;
  const {
    notify_frequency,
    newsletter,
    task_notify,
    task_notify_comment,
    task_notify_comment_unassigned,
    new_task_notify,
    task_notify_done,
    isGuest
  } = userProp;

  useEffect(() => {
    propHandler?.listen();
    return () => {
      propHandler.destory();
    };
  }, [propHandler]);

  const container = document.getElementById(
    'settings_container'
  ) as ContainerType;

  const [organizations, setOrganizations] = useState(props.organizations || []);
  const [user, setUser] = useState<UserSettings>({
    schedule: notify_frequency,
    newsletter: newsletter || 0,
    enableProjectUpdates: notify_frequency !== 0,
    enableTaskUpdates: task_notify,
    enableTaskCommentUpdates: task_notify_comment,
    enableUnassignedTaskCommentUpdates: task_notify_comment_unassigned,
    enableNewTaskUpdates: new_task_notify,
    enableTaskDoneUpdates: task_notify_done,
    isGuest: isGuest
  });
  const [saving, setSaving] = useState<boolean>(false);

  const handleUpdateUser = (attributes: { [key: string]: any }) => {
    setUser({ ...user, ...attributes });
  };

  const handleOrganizationChange = (id: number, patch: any) => {
    const orgs = organizations.slice(0);
    const organization = orgs.find(org => org.organization.id === id);
    Object.keys(patch).forEach(key => {
      organization[key] = patch[key];
    });

    setOrganizations(orgs);
  };

  const _location = useLocation();

  useEffect(() => {
    if (
      _location.pathname === '/notifications' ||
      _location.pathname === '/profile' ||
      /\/organizations\/\d+\/\w+/.test(_location.pathname)
    ) {
      // @ts-expect-error
      window.setBreadcrumbsPathname?.(`/settings${_location.pathname}`);
    }
  }, [_location.pathname]);

  return (
    <div className={styles.container}>
      <div className={styles.sidebar}>
        <h3 className={styles.submenuHeading}>Your Account</h3>
        <Menu />

        <h3 className={styles.submenuHeading}>Organizations</h3>
        <ul className={styles.submenu}>
          {organizations.map(
            org =>
              // Make sure the org has an endpoint so we know they have access to making changes
              org?.endpoints && (
                <li key={org.organization.id}>
                  <NavLink
                    className={styles.organizationLink}
                    to={`/organizations/${org.organization.id}/edit`}
                    style={{ fontWeight: 'bold' }}
                  >
                    {org.organization.name}
                    {access_to.find(
                      (value: { organizationId: number; edit?: boolean }) =>
                        value.organizationId === org.id
                    )?.edit && <Lock className={styles.lock} />}
                  </NavLink>
                </li>
              )
          )}
        </ul>
      </div>
      <div className={styles.content}>
        <h2 className={styles.heading}>Settings</h2>
        <ContainerProvider value={container}>
          <div className={styles.internalContent}>
            <Routes>
              <Route path="/" element={<Navigate to="/notifications" />} />
              <Route
                path="/notifications"
                element={
                  <Notifications
                    authenticityToken={authenticityToken}
                    user={user}
                    handleUpdateUser={handleUpdateUser}
                    saving={saving}
                    setSaving={setSaving}
                    isGuest={isGuest}
                  />
                }
              />
              <Route path="/profile" element={<UserProfile {...props} />} />
              <Route
                path="/organizations/:id/*"
                element={
                  <Organization
                    {...props}
                    container={getContainer}
                    onOrganizationChange={handleOrganizationChange}
                  ></Organization>
                }
              />
            </Routes>
          </div>
        </ContainerProvider>
      </div>
    </div>
  );
};

const SettingsWrapper = (props: any) => {
  return (
    <ApolloProvider client={client}>
      <Router basename="/settings">
        <Settings {...props} />
      </Router>
    </ApolloProvider>
  );
};

export default SettingsWrapper;
