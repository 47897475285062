import React, { FC, MouseEventHandler } from 'react';
import { Button } from 'antd';
import styles from './styles.module.css';
import cx from 'classnames';
import { Share } from 'lucide-react';

type Props = {
  className?: string;
  onClick: MouseEventHandler<HTMLElement> | (() => void);
};

const ShareButton: FC<Props> = ({ className, onClick }) => (
  <Button
    type="default"
    className={cx(styles.shareButton, { [className || '']: className })}
    icon={<Share className={styles.shareIcon} />}
    onClick={onClick}
  />
);

export default ShareButton;
