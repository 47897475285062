import { gql } from '@apollo/client';
import { DesignAsset } from 'appJS/../clients/design_assets/types';
import { Organization } from 'appJS/models/Organization';
import { ProjectSite } from 'appJS/models/Project';
import { Task } from 'appJS/models/Task';

import Config from 'models/Config';

export type UserProject = {
  id: string | number;
  pusherChannelName: string;
  name: string;
  shareKey: string | null;
  guestSites: ProjectSite[];
  devurl: string | null;
  organization: Organization;
  assets: DesignAsset[];
  guestTasks: Task[];
  bugherdProxyHost?: string;
  apiKey?: string;
};

export interface GetUserFeedbackData {
  config: Config;
  currentUser: {
    id: string;
    userProject: UserProject;
    name: string;
    email: string;
  };
}

export interface GetUserFeedbackVars {
  projectId: string | number;
}

export const GET_USER_FEEDBACK = gql`
  query getUserFeedback($projectId: ID!) {
    config {
      pusherApiKey
      pusherChannelAuthEndpoint
    }
    currentUser {
      id
      name
      email
      userProject(id: $projectId) {
        id
        name
        pusherChannelName
        bugherdProxyHost
        apiKey
        assets {
          id
          name
          fileType
          url
          createdAt
          updatedAt
          projectId
          isFigma
          groupName
          status
        }
        guestSites {
          id
          url
          updatedAt
          coverUrl
          projectId
          siteVariants {
            scheme
            host
            port
            path
            query
            fragment
            isQuerySignificant
            isFragmentSignificant
          }
        }
        guestTasks {
          site
          url
          status
          id
          localTaskId
          assetId
        }
        shareKey
        organization {
          owner {
            id
            email
            avatarUrl
            name
            role
            firstName
            surname
          }
          experiments
        }
      }
    }
  }
`;
