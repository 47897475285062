import React, { useState, useEffect } from 'react';
import styles from './index.module.css';
import TaskCount from './TaskCount';
import { Spin, message, Button } from 'antd';
import { post } from 'appJS/utils/fetch';
import { formatDistanceStrict, parseISO } from 'date-fns';
export const UNGROUPED = 'Ungrouped';
import { default as WebsitesLogo } from './assets/website_icon.svg';
import { ExternalLink } from 'lucide-react';
import WebsiteMenu from './WebsiteMenu';
import InstallExtension from './InstallExtension';
import { checkForExtension } from 'utils/checkForBugherdExtension';
import AddWebsiteModal from './AddWebsiteModal';
import * as translations from '../design_assets/strings';
import { getLangKey } from 'appJS/models/Application';
import EmptyProjectSite from './assets/empty_project_site.png';
import { useProjectSitesState } from '../../clients/providers/ProjectSites';
import { useOrganizationState } from '../providers/Organization';
import ProxyCompatibilityChecker from './ProxyCompatibilityChecker';
import { useDesignAssetsState } from '../providers/DesignAssets';
import { handleCopy } from 'appJS/views/user_projects/GuestProject/handleCopy';

const strings = translations[getLangKey()];

export const WebsitesScreen = () => {
  const projectBoardProps = window?.project_board_props;
  const project = projectBoardProps?.assetFeedbackProps?.project;
  const projectId = project?.id;
  const [hasExtensionInstalled, setExtensionInstalled] = useState<boolean>(
    false
  );
  const [selectedUrl, setSelectedUrl] = useState<string | null>(null);

  const {
    projectSites,
    handleGetProjectSites,
    proxyHost
  } = useProjectSitesState();
  const {
    onSharingClick,
    viewGuestKanban,
    isNoLoginGuestExperienceEnabled
  } = useDesignAssetsState();
  const { hasOrganizationExperiment } = useOrganizationState();
  const hasNewOnboarding =
    hasOrganizationExperiment('checklist_onboarding_variant') ||
    hasOrganizationExperiment('add_website_onboarding_variant');
  const [loadingCoverUrls, setLoadingCoverUrls] = useState<
    // eslint-disable-next-line no-undef
    Record<string, boolean>
  >({});

  const container =
    document.getElementById('designAssetsBoard') || document.body;
  const apiKey = project?.apiKey;
  const [modalOpenId, setModalOpenId] = useState<string | null>(null);
  const { javascriptInstalled } = project || {};

  useEffect(() => {
    (async () => {
      const exists = await checkForExtension();

      if (exists) {
        setExtensionInstalled(exists);
      }
    })();
  }, [javascriptInstalled]);

  const handleCloseWebsiteModal = () => {
    setModalOpenId(null);
  };

  const handleOpenWebsiteModal = id => {
    setModalOpenId(id);
  };

  const getCoverImage = async (id: string) => {
    try {
      await post(`/projects/${projectId}/generate_cover?site_id=${id}`, {
        headers: { 'Content-Type': 'application/json; charset=utf-8' }
      });
      setLoadingCoverUrls(prevState => ({ ...prevState, [id]: true }));
      message.success(
        'Your cover screenshot is being created, and will appear here shortly'
      );
      setTimeout(() => {
        handleGetProjectSites();
        setLoadingCoverUrls(prevState => ({ ...prevState, [id]: false }));
      }, 6000);
    } catch (e) {
      console.error(e);
      message.error('Something went wrong, please try again.');
    }
  };

  const getTriggerElement = loading => (
    <Button
      icon={<ExternalLink size={20} />}
      iconPosition="end"
      type="primary"
      loading={loading}
    >
      Open website
    </Button>
  );

  const showButtonOrText = (id: string) => {
    if (loadingCoverUrls[id]) {
      return <Spin />;
    } else {
      return (
        <img
          width={130}
          height={100}
          src={EmptyProjectSite}
          alt="Your project site"
        />
      );
    }
  };

  const openTasksCount = undefined;
  const triageTasksCount = undefined;
  let showInstallBh = !javascriptInstalled && !hasExtensionInstalled;

  if (hasNewOnboarding) {
    showInstallBh = false;
  }

  const sortedProjectSites = projectSites.sort(
    (a, b) =>
      new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
  );

  const regex = /^(https?:\/\/)?(.+?)\/?$/i;

  function cleanUrl(url) {
    return url.replace(regex, '$2');
  }

  const handleClick = (url: string) => {
    setSelectedUrl(url);
  };

  return (
    <>
      <div className={styles.websiteWrapper}>
        {showInstallBh && (
          <InstallExtension showJavascriptOption showExtensionOption />
        )}
        <div className={styles.titleWrapper}>
          <WebsitesLogo />
          <h2>Websites</h2>
        </div>
        <div
          className={styles.websiteCards}
          data-cy="websiteCards"
          id="websiteCards"
        >
          {sortedProjectSites.map((site, index) => {
            const {
              id,
              url,
              cover_url: coverUrl,
              updated_at: updatedAt
            } = site;

            return (
              <>
                <div
                  style={{ '--index': index }}
                  key={id}
                  data-index={index}
                  className={`${styles.websiteCard} ${styles.staggerInAnimation}`}
                  onClick={() => handleClick(url)}
                >
                  <div className={styles.loadingWebsiteImage}>
                    {coverUrl ? (
                      <img src={coverUrl} />
                    ) : (
                      <div>{showButtonOrText(id)}</div>
                    )}
                  </div>

                  <div className={styles.cardContent}>
                    <div className={styles.leftCardContent}>
                      <h3>{cleanUrl(url)}</h3>

                      <div className={styles.taskCountUpdated}>
                        <TaskCount
                          container={container}
                          openTasksCount={openTasksCount}
                          triageTasksCount={triageTasksCount}
                        />
                        <p className={styles.lastUpdated}>
                          Updated:{' '}
                          {formatDistanceStrict(
                            new Date(),
                            parseISO(updatedAt)
                          )}{' '}
                          ago
                        </p>
                      </div>
                    </div>
                    <div className={styles.cardFooter}>
                      <span
                        className={styles.websiteMenu}
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        {!viewGuestKanban && (
                          <WebsiteMenu
                            container={container}
                            handleOpenWebsiteModal={() => {
                              handleOpenWebsiteModal(id);
                            }}
                            disabled={false}
                            id={id}
                            projectId={projectId}
                            apiKey={apiKey}
                            handleGetCoverImage={getCoverImage}
                          />
                        )}
                      </span>
                      {isNoLoginGuestExperienceEnabled && (
                        <Button
                          type="default"
                          onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            // eslint-disable-next-line no-unused-expressions
                            viewGuestKanban
                              ? handleCopy({
                                  text: url,
                                  successMessage: 'URL copied to clipboard'
                                })
                              : onSharingClick({ siteId: id });
                          }}
                        >
                          Share
                        </Button>
                      )}

                      <ProxyCompatibilityChecker
                        getTriggerElement={getTriggerElement}
                        projectSiteId={id}
                        projectSiteUrl={url}
                        proxyUrl={`https://${project.apiKey}_${id}.${proxyHost}`}
                        project={project}
                        showProxyPreview={hasNewOnboarding}
                        viewGuestKanban={viewGuestKanban}
                        selectedUrl={selectedUrl}
                      />

                      {!viewGuestKanban && (
                        <span
                          onClick={e => {
                            e.stopPropagation();
                          }}
                        >
                          <AddWebsiteModal
                            container={container}
                            isWebsiteModalOpen={modalOpenId === id}
                            activeItem=""
                            handleCloseWebsiteModal={handleCloseWebsiteModal}
                            title={strings.editWebsiteUrl}
                            projectSiteUrl={url}
                            projectUrlUpdating
                            projectSiteId={id}
                            hideInstallInstructions={hasNewOnboarding}
                          />
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};
