import React, { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { PlaceHolder } from './ComingSoonModal';
import { MainScreen } from './MainScreen';
import { AssetProject } from './types';
import AssetsTab from './AssetsTab';
import {
  DesignAssetsProvider,
  useDesignAssetsState
} from '../providers/DesignAssets';
import { AntdWrapper } from '../shared/AntdWrapper';

export type DesignAssetFeedbackProps = {
  project: AssetProject;
  figmaClientId: string;
  figmaClientSecret: string;
  figmaCallbackURI: string;
  figmaUserToken: string | null;
  figmaRefreshToken: string | null;
  figmaExpiryToken: string | null;
  bugherdUrl: string;
  currentUserId: number;
  container: HTMLDivElement;
  boardContainer: HTMLDivElement;
  AssetNavItem?: ReactNode;
};

type AssetFeedbackProps = {
  AssetNavItem?: ReactNode;
};

const AssetFeedback: React.FC<AssetFeedbackProps> = ({ AssetNavItem }) => {
  const { showAssets, boardContainer } = useDesignAssetsState();
  return (
    <>
      <AssetsTab AssetNavItem={AssetNavItem} />
      <PlaceHolder />
      {boardContainer &&
        showAssets &&
        createPortal(<MainScreen />, boardContainer)}
    </>
  );
};

const DesignAssetFeedback: React.FC<DesignAssetFeedbackProps> = ({
  project,
  figmaClientId,
  figmaClientSecret,
  figmaCallbackURI,
  figmaUserToken,
  figmaRefreshToken,
  figmaExpiryToken,
  bugherdUrl,
  currentUserId,
  container,
  boardContainer,
  AssetNavItem
}) => {
  return (
    <DesignAssetsProvider
      project={project}
      figmaClientId={figmaClientId}
      figmaClientSecret={figmaClientSecret}
      figmaCallbackURI={figmaCallbackURI}
      figmaUserToken={figmaUserToken}
      figmaRefreshToken={figmaRefreshToken}
      figmaExpiryToken={figmaExpiryToken}
      bugherdUrl={bugherdUrl}
      container={container}
      boardContainer={boardContainer}
      currentUserId={currentUserId}
    >
      <AssetFeedback AssetNavItem={AssetNavItem} />
    </DesignAssetsProvider>
  );
};

export default (props: DesignAssetFeedbackProps) => (
  <AntdWrapper>
    <DesignAssetFeedback {...props} />
  </AntdWrapper>
);
