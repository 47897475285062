import React, { FC, MutableRefObject, KeyboardEvent, ReactNode } from 'react';
import cx from 'classnames';
import Avatar from '../../../../../../../../shared/Avatar';
import { User } from '../../../../../../../models/User';
import { canUserViewPrivateComments } from '../../permissions';
import { Mention, MentionsInput, SuggestionDataItem } from 'react-mentions';
import * as translations from './strings';
import { getLangKey } from '../../../../../../../models/Language';
import styles from './styles.module.css';

const strings = translations[getLangKey()];

type Props = {
  mentionRef: MutableRefObject<HTMLInputElement | null>;
  users: User[];
  comment: string;
  isPrivate: boolean;
  handleOnChange: (value: string) => void;
  onSubmit: () => void;
  handleOnFocus: () => void;
  onWrongShortcut: () => void;
  maxLength: number;
  guestVisible: boolean;
};

const AtMention: FC<Props> = ({
  mentionRef,
  users,
  comment,
  isPrivate,
  handleOnChange,
  onSubmit,
  handleOnFocus,
  onWrongShortcut,
  maxLength,
  guestVisible
}) => {
  const mentionsClassName: string = isPrivate ? 'membersOnly' : 'allUsers';

  const submitShortcut = (e: KeyboardEvent) =>
    (e.keyCode === 10 || e.keyCode === 13) && (e.ctrlKey || e.metaKey);

  const submitShortcutRetired = (e: KeyboardEvent) =>
    (e.keyCode === 10 || e.keyCode === 13) && e.shiftKey;

  const handleInputChange = (value: string) => {
    if (comment.trim().length < maxLength) {
      handleOnChange(value);
    }
  };

  const handleKeyUp = (event: KeyboardEvent) => {
    if (submitShortcut(event)) {
      event.preventDefault();
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (submitShortcutRetired(event)) {
      onWrongShortcut();
    }
    if (submitShortcut(event)) {
      event.preventDefault();
      onSubmit();
    }
  };

  const filterMentions = (query: string) =>
    users
      .filter(
        (user: User) =>
          (user.role !== 'guest' || guestVisible) &&
          (!isPrivate || canUserViewPrivateComments(user))
      )
      .map(({ id, name }: User) => ({ id, display: name }))
      .filter((user: { id: string | number; display: string }) => {
        const lowerCaseDisplay = user.display.toLowerCase();
        const startOfLowerCaseDisplay = lowerCaseDisplay.slice(0, query.length);
        return startOfLowerCaseDisplay === query.toLowerCase();
      });

  const renderSuggestion = (
    suggestion: SuggestionDataItem,
    search: string,
    highlightedDisplay: ReactNode,
    index: number,
    focused: boolean
  ) => (
    <div
      className={cx(styles.user, {
        [styles.selected]: !!focused
      })}
    >
      <div className={styles.avatarContainer}>
        <Avatar
          // @ts-ignore
          className={styles.avatar}
          member={users.find(user => user.id === suggestion.id)}
          size="default"
        />
      </div>
      {highlightedDisplay}
    </div>
  );

  return (
    <MentionsInput
      value={comment}
      onChange={event => handleInputChange(event.target.value as string)}
      className={mentionsClassName}
      classNames={styles}
      onFocus={handleOnFocus}
      placeholder={strings.addComment}
      inputRef={mentionRef}
      onKeyUp={handleKeyUp}
      onKeyDown={handleKeyDown}
      allowSpaceInQuery
      maxLength={maxLength}
    >
      <Mention
        trigger="@"
        data={filterMentions}
        appendSpaceOnAdd
        renderSuggestion={renderSuggestion}
        className={styles.mentions}
        displayTransform={(id, display) => `@${display}`}
      />
    </MentionsInput>
  );
};

export default AtMention;
