const parseBrowserUrl = (
  originalBrowserLocation: string,
  projectUrl?: string
) => {
  let browserLocation = originalBrowserLocation;

  const assetWithZoomAndScaleRegEx = new RegExp(
    /(\/projects\/\d+\/assets\/\d+\?page=\d+&zoom=\d+&scale=\d+)/g
  );

  if (assetWithZoomAndScaleRegEx.test(browserLocation)) {
    browserLocation = browserLocation.replace(/(&zoom=\d+&scale=\d+)/g, '');
  }

  if (!projectUrl) {
    return browserLocation;
  }

  try {
    // This replaces the proxy URL with the project URL
    const projectURL = new URL(projectUrl);
    const projectDomain = projectURL.origin;

    const proxyRegex = new RegExp(
      /http(s?)\:\/\/.*\.proxy\.bugherd\.(carrot|com)/g
    );

    browserLocation = browserLocation.replace(proxyRegex, projectDomain || '');

    return browserLocation;
  } catch (err) {
    console.warn('Could not parse browser URL', err);
    return browserLocation;
  }
};

export default parseBrowserUrl;
