import React from 'react';
import { message } from 'antd';
import PropTypes from 'prop-types';
import Attachments from '../../../../clients/sidebar/views/SidebarPanel/Detailbar/Attachments';
import TaskLocation from '../../../../clients/sidebar/views/SidebarPanel/Detailbar/TaskLocation';
import DueDate from '../../../../clients/sidebar/views/SidebarPanel/Detailbar/DueDate';
import Assignees from '../../../../clients/sidebar/views/SidebarPanel/Detailbar/Assignees';
import AdditionalInfo from '../../../../clients/sidebar/views/SidebarPanel/Detailbar/AdditionalInfo';
import Severity from '../../../../clients/sidebar/views/SidebarPanel/Detailbar/Severity';
import Tags from '../../../../clients/sidebar/views/SidebarPanel/Detailbar/Tags';
import styles from './index.module.css';
import camelCaseKeys from 'camelcase-keys';
import VisibleTo from '../../../../clients/sidebar/views/SidebarPanel/Detailbar/VisibleTo';
import { getProjectMembers } from 'models/Project';
import { v4 as uuidv4 } from 'uuid';

export default class TaskInfo extends React.Component {
  static propTypes = {
    isRecording: PropTypes.bool,
    taskUrls: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    gotoTask: PropTypes.func.isRequired,
    priorityId: PropTypes.number,
    setProperty: PropTypes.func,
    editSeverity: PropTypes.bool,
    editAssignees: PropTypes.bool,
    viewAssignees: PropTypes.bool,
    editTags: PropTypes.bool,
    viewDueDate: PropTypes.bool,
    editDueDate: PropTypes.bool,
    viewSelector: PropTypes.bool,
    attachmentsDisabled: PropTypes.bool,
    assignees: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        email: PropTypes.string,
        avatar: PropTypes.string
      })
    ),
    attachments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        url: PropTypes.string,
        name: PropTypes.string
      })
    ),
    tagIds: PropTypes.arrayOf(PropTypes.number),
    allTags: PropTypes.array,
    dueDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    creator: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        email: PropTypes.string,
        display: PropTypes.string
      })
    ]),
    bugherdUrl: PropTypes.string,
    projectId: PropTypes.number,
    taskId: PropTypes.number,
    browserData: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        browser: PropTypes.string,
        colours: PropTypes.string,
        os: PropTypes.string,
        resolution: PropTypes.string,
        user_agent: PropTypes.string,
        window_size: PropTypes.string
      })
    ]),
    selector: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        path: PropTypes.string
      })
    ]),
    taskMetadata: PropTypes.object,
    isAdminView: PropTypes.bool,
    container: PropTypes.instanceOf(Element),
    isIntro: PropTypes.bool,
    canEditTaskUrl: PropTypes.bool.isRequired,
    projectUrl: PropTypes.string,
    projectDomains: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string)
    ]),
    canManageProject: PropTypes.bool,
    pageUrl: PropTypes.string,
    sites: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        url: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        variants: PropTypes.arrayOf(
          PropTypes.shape({
            fragment: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
            host: PropTypes.string,
            is_fragment_significant: PropTypes.bool,
            is_query_significant: PropTypes.bool,
            path: PropTypes.string,
            port: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
            query: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
            scheme: PropTypes.string
          })
        )
      })
    ),
    guestVisible: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    taskEditVisibility: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
  }

  state = {
    pastedFile: null
  };

  toggleAdditionalInfo = () => {
    const { expanded } = this.state;
    this.setState({
      expanded: !expanded
    });
  };

  handleUpdateTags = ids => {
    const { setProperty, allTags } = this.props;
    setProperty({
      tag_ids: ids,
      tag_names: allTags
        .filter(tag => ids.includes(tag.get('id')))
        .map(tag => tag.get('name'))
    });
  };

  handleCreateTag = newTagName => {
    const { setProperty, tagIds, allTags } = this.props;
    setProperty({
      tag_ids: tagIds.slice().concat(null),
      tag_names: allTags
        .filter(tag => tagIds.includes(tag.get('id')))
        .map(tag => tag.get('name'))
        .concat(newTagName)
    });
  };

  handleRemoveTag = tagId => {
    const { setProperty, tagIds, allTags } = this.props;
    const _tagIds = tagIds.filter(id => id !== tagId);
    setProperty({
      tag_ids: _tagIds,
      tag_names: allTags
        .filter(tag => _tagIds.includes(tag.get('id')))
        .map(tag => tag.get('name'))
    });
  };

  handlePaste = e => {
    const copiedFile = e.clipboardData.files;
    const taskIdAndUid = {
      taskId: this.props.taskId,
      uid: uuidv4()
    };
    const fileObject = copiedFile[0];

    const file = new File([fileObject], fileObject.name, {
      type: fileObject.type,
      lastModified: fileObject.lastModified,
      ...taskIdAndUid
    });

    if (file) {
      this.setState({ pastedFile: file });
      message.success('File pasted successfully.');
    }
  };

  render() {
    const {
      isRecording,
      taskUrls,
      priorityId,
      setProperty,
      assignees,
      tagIds,
      allTags,
      dueDate,
      attachments,
      bugherdUrl,
      projectId,
      taskId,
      browserData,
      selector,
      taskMetadata,
      isAdminView,
      container,
      editSeverity,
      editAssignees,
      viewAssignees,
      editTags,
      viewDueDate,
      editDueDate,
      viewSelector,
      attachmentsDisabled,
      gotoTask,
      canEditTaskUrl,
      canManageProject,
      sites,
      projectUrl,
      projectDomains,
      pageUrl,
      guestVisible,
      taskEditVisibility,
      projectSiteId
    } = this.props;

    const getTags = tag => ({
      id: tag.get('id'),
      name: tag.get('name'),
      active: tag.get('active')
    });

    return (
      <div onPaste={this.handlePaste} className={styles.container}>
        {taskUrls && (
          <TaskLocation
            gotoTask={gotoTask}
            taskPath={taskUrls.taskPath}
            absoluteUrl={taskUrls.absoluteUrl}
            container={container}
            canEditTaskUrl={canEditTaskUrl}
            taskHost={taskUrls.taskHost}
            canManageProject={canManageProject}
            isAdminView={isAdminView}
            sites={sites}
            devurl={projectUrl}
            projectDomains={projectDomains}
            updateSite={site => setProperty({ site })}
            updateUrl={url => setProperty({ url })}
            taskId={taskId}
            pageUrl={pageUrl}
            projectId={projectId}
            bugherdUrl={bugherdUrl}
            projectSiteId={projectSiteId}
          />
        )}

        <Severity
          priorityId={priorityId}
          updateSeverity={value => setProperty({ priority_id: value })}
          canEditSeverity={editSeverity}
        />

        {taskEditVisibility && (
          <VisibleTo
            updateVisibility={visibility =>
              setProperty({ guest_visible: visibility })
            }
            isAdminView={isAdminView}
            guestVisible={!!guestVisible}
          />
        )}

        {(editAssignees || viewAssignees) && (
          <Assignees
            canEditAssignees={editAssignees}
            assignees={assignees}
            updateAssignees={value => setProperty({ assignee_ids: value })}
            assignableUsers={getProjectMembers()}
          />
        )}

        <Tags
          onUpdateTags={this.handleUpdateTags}
          onCreateTag={this.handleCreateTag}
          onRemoveTag={this.handleRemoveTag}
          tags={allTags.filter(tag => tagIds.includes(tag.id)).map(getTags)}
          tagIds={tagIds}
          allTags={allTags.map(getTags)}
          canEditTags={editTags}
        />

        {(editDueDate || viewDueDate) && (
          <DueDate
            dueDate={dueDate}
            container={container}
            canEditDueDate={editDueDate}
            updateDueDate={date => setProperty({ due_at: date })}
          />
        )}

        {!attachmentsDisabled && (
          <Attachments
            attachments={attachments}
            onUpdateAttachments={attachments => setProperty({ attachments })}
            bugherdUrl={bugherdUrl}
            projectId={projectId}
            taskId={taskId}
            isAdminView={isAdminView}
            container={container}
            pastedFile={this.state.pastedFile}
          />
        )}

        <AdditionalInfo
          isRecording={isRecording}
          browserData={camelCaseKeys(browserData, { deep: true })}
          selector={selector}
          absoluteUrl={taskUrls ? taskUrls.absoluteUrl : ''}
          canViewSelector={viewSelector}
          metadata={taskMetadata}
          isAdminView={isAdminView}
        />
      </div>
    );
  }
}
