import * as React from 'react';
import cx from 'classnames';

import Error from 'components/Error';
import RefreshMessage from 'components/RefreshMessage';
import { UserAccess } from 'models/Access';
import { getLangKey } from 'models/Language';
import { MemberRole } from 'models/MemberRole';
import { CurrentUser } from 'models/User';

import { useUserProjectsState } from './state';
import SearchAndAddProject from './SearchAndAddProject';
import ProjectCards from './ProjectCards';
import ProjectPagination from './ProjectPagination';
import EmptyState from './EmptyState';
import styles from './index.module.css';

import RedirectToCreateProject from './RedirectToCreateProject';
import * as translations from './strings';
import { ProjectSetupPendingBanner } from 'jsViews/user_projects/ProjectSetupPendingBanner';
import { Dropdown, Button, Menu, Radio } from 'antd';
import { Filter } from 'lucide-react';

type Props = {
  currentUser: CurrentUser;
  accessTo: UserAccess;
  disabled?: boolean;
  container: HTMLDivElement;
  hasUnreadCommentsExperiment: boolean;
};

const UserProjects = ({
  currentUser,
  accessTo,
  disabled,
  container,
  hasUnreadCommentsExperiment
}: Props) => {
  const strings = translations[getLangKey()];
  const organization = currentUser.organization;

  const {
    pageNumber,
    showRefresh,
    setShowRefresh,
    searchByName,
    setSearchByName,
    sortBy,
    setSortBy,
    userProjects,
    userProjectsCount,
    userProjectsPageSize,
    onPaginationChange,
    getUserProjects: { data, loading, error, refetch }
  } = useUserProjectsState({ organizationId: organization.id });
  const hasChecklistOnboardingExperiment = organization?.experiments?.includes(
    'checklist_onboarding_variant'
  );
  const hasAddWebsiteOnboardingExperiment = organization?.experiments?.includes(
    'add_website_onboarding_variant'
  );
  const hasNewOnboarding = hasChecklistOnboardingExperiment || hasAddWebsiteOnboardingExperiment

  if (error) return <Error error={error} />;

  const emptyUserProjects =
    data && searchByName === '' && data.currentUser.userProjectsCount === 0;

  if (
    currentUser.role === (MemberRole.BILLING_MANAGER || MemberRole.MANAGER) &&
    emptyUserProjects
  ) {
    return <RedirectToCreateProject organizationId={organization.id} />;
  }

  if (emptyUserProjects) {
    return <EmptyState owner={organization.owner} user={currentUser} />;
  }

  let pendingSetupProject;
  let noCompletedSetupProjects = true;
  let showSetupPendingBanner = false;

  if (hasNewOnboarding) {
    pendingSetupProject = userProjects.find(
      project => !project.hasCompletedChecklist
    );
    noCompletedSetupProjects = !userProjects.some(
      project => project.hasCompletedChecklist
    );
    showSetupPendingBanner = noCompletedSetupProjects && !!pendingSetupProject;
  }

  const handleMenuClick = (e: any) => {
    setSortBy(e.key);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="name">
        <Radio checked={sortBy === 'name'}>Alphabetical</Radio>
      </Menu.Item>
      <Menu.Item key="updated_at">
        <Radio checked={sortBy === 'updated_at'}>Updated At</Radio>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={cx(styles.wrapper, { [styles.disabled]: disabled })}>
      {showSetupPendingBanner && (
        <ProjectSetupPendingBanner
          projectLink={`/projects/${pendingSetupProject?.id}/getting-started`}
        />
      )}
      <h2 className={styles.myProject}>{strings.myProjects}</h2>
      <RefreshMessage
        show={showRefresh}
        message={strings.refreshPageMessage}
        onRefresh={() => {
          setShowRefresh(false);
          refetch();
        }}
      />

      <SearchAndAddProject
        organizationId={organization.id}
        searchByName={searchByName}
        setSearchByName={setSearchByName}
        accessTo={accessTo}
      >
        <ProjectPagination
          {...{
            pageNumber,
            userProjectsCount,
            userProjectsPageSize,
            onChange: onPaginationChange,
            position: 'top',
            simple: true
          }}
        />
        <Dropdown
          trigger={['click']}
          overlay={menu}
          getPopupContainer={() => container as HTMLDivElement}
          overlayClassName={styles.sortOverlay}
          placement="bottom"
          className={styles.sortBy}
        >
          <Button
            type="default"
            shape="round"
            icon={<Filter className={styles.filterIcon} />}
          >
            Sort
          </Button>
        </Dropdown>
      </SearchAndAddProject>

      <ProjectCards
        projects={userProjects}
        accessTo={accessTo}
        loading={loading}
        setShowRefresh={setShowRefresh}
        container={container}
        hasUnreadCommentsExperiment={hasUnreadCommentsExperiment}
        currentUserId={Number(currentUser.id)}
      />

      <ProjectPagination
        {...{
          pageNumber,
          userProjectsCount,
          userProjectsPageSize,
          onChange: onPaginationChange,
          position: 'bottom',
          simple: false
        }}
      />
    </div>
  );
};

export default UserProjects;
