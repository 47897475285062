interface CustomEvent extends Event {
  type: string;
  detail: string;
}

export const listen = (
  eventName: string,
  callback: Function
): { callback: Function; remove: () => void } => {
  const cb = (event: CustomEvent) => {
    try {
      const data = JSON.parse(event.detail);

      callback(data);

      // @ts-ignore
      window.removeEventListener(eventName, cb);
    } catch (err) {
      console.warn(err);
    }
  };

  // @ts-ignore
  window.addEventListener(eventName, cb);

  return {
    callback: cb,
    remove: () => {
      // @ts-ignore
      window.removeEventListener(eventName, cb);
    }
  };
};

export const emit = (
  { eventName, responseEventName, eventData = {} },
  onResponse
) => {
  if (responseEventName && onResponse) {
    listen(responseEventName, onResponse);
  }

  const event = window.document.createEvent('CustomEvent');

  event.initCustomEvent(eventName, true, true, JSON.stringify(eventData));
  window.document.dispatchEvent(event);
};

export default {
  listen,
  emit
};
