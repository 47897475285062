import React, { FC, useState } from 'react';
import { Select, Tag } from 'antd';
import styles from '../index.module.css';
import * as translations from '../../strings';
import { getLangKey } from '../../../../../../../javascript/models/Language';
import { User as UserIcon } from 'lucide-react';
import Avatar from '../../../../../../shared/Avatar';
import cx from 'classnames';
import * as Types from '../../types';
import { getProjectMembers } from '../../../../../../../javascript/models/Project';

type Props = {
  assignees: number[];
  onAssigneesChange: (assignees: number[]) => void;
  container: Element;
  inputSize: 'large' | 'middle';
  assignableUsers?: Types.User[];
};

const strings = translations[getLangKey()];
const { Option } = Select;

const Assignees: FC<Props> = props => {
  const {
    assignees,
    onAssigneesChange,
    container,
    inputSize,
    assignableUsers
  } = props;

  const [showPlaceholder, setShowPlaceholder] = useState<boolean>(true);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const _assignableUsers = assignableUsers || getProjectMembers();

  const handleAssigneeFocus = () => {
    if (!assignees.length) {
      setShowPlaceholder(!showPlaceholder);
    }
  };

  const removeAssignee = (id: number) => {
    const updatedAssignees = assignees.filter(
      (assignee: number) => assignee !== id
    );
    onAssigneesChange(updatedAssignees);
  };

  const getAssigneeOptions = (user: Types.User) => (
    <Option
      className={styles.assigneeOption}
      value={user.id}
      label={user.name}
      key={user.id + user.name}
    >
      <Avatar member={user} size="small" />
      <span title={user.name} className={styles.optionText}>
        {user.name}
      </span>
    </Option>
  );

  const getAssigneeTags = (props: any) => (
    <Tag
      icon={<UserIcon className={styles.tagIcon} />}
      closable
      className={styles.tag}
      onClose={() => removeAssignee(props.value)}
    >
      {props.label[1]}
    </Tag>
  );

  const handleAssigneesChange = (assigned: number[]) => {
    onAssigneesChange(assigned);
  };

  return (
    <Select
      mode="multiple"
      className={cx(styles.select, styles.selectAssignees, {
        [styles.hasValue]: assignees.length
      })}
      placeholder={
        showPlaceholder ? (
          <span className={styles.placeHolder}>{strings.assignees}</span>
        ) : null
      }
      suffixIcon={null}
      onChange={handleAssigneesChange}
      getPopupContainer={() => container as HTMLElement}
      value={assignees}
      onFocus={handleAssigneeFocus}
      onBlur={handleAssigneeFocus}
      tagRender={getAssigneeTags}
      size={inputSize}
      open={dropdownOpen}
      onSelect={() => {
        if (dropdownOpen) setDropdownOpen(!dropdownOpen);
      }}
      filterOption={(inputValue, option) =>
        !!option?.label
          ?.toLocaleString()
          .toLocaleLowerCase()
          .includes(inputValue.toLocaleLowerCase())
      }
      onDropdownVisibleChange={setDropdownOpen}
    >
      {_assignableUsers
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(getAssigneeOptions)}
    </Select>
  );
};

export default Assignees;
