import React, {
  useContext,
  useState,
  useEffect,
  createContext,
  ReactNode,
  MouseEvent
} from 'react';

import { useLocation } from 'react-router';
import useNavigation from './hooks/useNavigation';
import { useQuery } from '@apollo/client';
import {
  GET_USER_FEEDBACK,
  GetUserFeedbackData as Data,
  GetUserFeedbackVars as Vars
} from './queries';
import { isTaskLocation } from '../../../../clients/shared/utils/taskLocation';
import {
  browserConfig as browserResources,
  BrowserConfigType
} from 'appJS/components/onboarding/extensionConfig';
import { detect, BrowserInfo } from 'detect-browser';
import { checkForExtension } from 'appJS/utils/checkForBugherdExtension';
import * as translations from './strings';
import { getLangKey } from 'appJS/models/Language';
import { handleCopy } from './handleCopy';
import { ProjectSite } from 'appJS/models/Project';
import usePusherSubscribe from 'appJS/hooks/usePusherSubscribe';
import { getEvents } from './pusherEvents';
import { Subscription } from 'appJS/models/Pusher';
import { handleSharingClick } from '../../../../clients/design_assets/handleSharingClick';
import { useOrganizationState } from 'appClients/providers/Organization';

const strings = translations[getLangKey()];

// @ts-ignore
const GuestProjectStateContext = createContext();

const GuestProjectProvider = ({
  children,
  container,
  bugherdUrl,
  organizationId,
  viewedGuestProjectBoard,
  viewGuestKanban
}: {
  children: ReactNode;
  container: HTMLDivElement;
  bugherdUrl: string;
  organizationId: number;
  viewedGuestProjectBoard: boolean;
  viewGuestKanban: boolean;
}) => {
  useNavigation();
  const location = useLocation();
  // @ts-expect-error
  const browser: BrowserInfo = detect();
  const projectId = Number(location.pathname.split('/').reverse()[0]);

  const { data, loading, refetch, error } = useQuery<Data, Vars>(
    GET_USER_FEEDBACK,
    {
      variables: { projectId }
    }
  );

  const [showShareModal, setShowShareModal] = useState<number | string | false>(
    false
  );
  const [extensionInstalled, setExtensionInstalled] = useState<boolean>(false);

  const browserConfig = browserResources.find(
    browserResource => browserResource.name === browser?.name
  );

  useEffect(() => {
    (async () => {
      const exists = await checkForExtension();

      if (exists && typeof exists === 'boolean') {
        setExtensionInstalled(exists);
      }
    })();
  }, []);

  const handlePusherEvent = () => refetch();

  const pusherParams = () => {
    if (error) {
      // eslint-disable-next-line no-unused-expressions
      window?.bugsnagClient
        ? window.bugsnagClient.notify('Error guest projects provider:', error)
        : console.error(error);
    }

    if (!loading && data?.currentUser.userProject) {
      const { id, pusherChannelName } = data?.currentUser.userProject;
      return {
        config: data?.config,
        projects: [{ id: String(id), pusherChannelName }],
        events: getEvents(handlePusherEvent),
        subscription: Subscription.GUEST_PROJECT
      };
    }
    return undefined;
  };

  usePusherSubscribe({ loading, pusher: pusherParams() });

  const getSiteTaskCount = ({ url, siteVariants }: ProjectSite): number =>
    (data?.currentUser.userProject.guestTasks || []).filter(
      task =>
        !task.assetId &&
        isTaskLocation({
          projectSites: siteVariants,
          taskSite: task.site || '',
          taskPage: task.url || '',
          browserLocation: url
        })
    ).length;

  const getAssetTaskCount = (_assetId: number): number =>
    (data?.currentUser?.userProject?.guestTasks || []).filter(
      ({ assetId }) => assetId && assetId === String(_assetId)
    ).length;

  const hasManagementFeaturesExperiment = true;
  const { hasOrganizationExperiment } = useOrganizationState();

  const isNoLoginGuestExperienceEnabled = hasOrganizationExperiment(
    'no_login_guest_experience'
  );

  const siteIdCallback = (siteId: number | string) => {
    const sites = data?.currentUser.userProject.guestSites;
    const site = sites?.find(({ id }) => id === siteId);
    if (site)
      handleCopy({
        text: site.url,
        successMessage: strings.copySiteSuccess
      });
  };

  const onSharingClick = ({
    assetId,
    siteId
  }: {
    assetId?: number | string;
    siteId?: number | string;
  }) => {
    handleSharingClick({
      isNoLoginGuestExperienceEnabled,
      assetId,
      siteId,
      callback: siteId ? siteIdCallback : setShowShareModal
    });
  };

  return (
    <GuestProjectStateContext.Provider
      value={{
        container,
        bugherdUrl,
        organizationId,
        projectId,
        loading,
        data,
        getSiteTaskCount,
        browserConfig,
        extensionInstalled,
        getAssetTaskCount,
        setShowShareModal,
        showShareModal,
        hasManagementFeaturesExperiment,
        onSharingClick,
        isNoLoginGuestExperienceEnabled,
        viewedGuestProjectBoard,
        refetch,
        viewGuestKanban
      }}
    >
      {children}
    </GuestProjectStateContext.Provider>
  );
};

type GuestProjectState = {
  container: HTMLDivElement;
  bugherdUrl: string;
  organizationId: number;
  projectId: number;
  loading: boolean;
  data?: Data;
  getSiteTaskCount: (projectSite: ProjectSite) => number;
  extensionInstalled: boolean;
  browserConfig?: BrowserConfigType;
  getAssetTaskCount: (_assetId: number) => number;
  hasManagementFeaturesExperiment: boolean;
  showShareModal: number | string | false;
  setShowShareModal: (value: number | string | false) => void;
  onSharingClick: ({
    assetId,
    siteId
  }: {
    assetId?: number | string;
    siteId?: number | string;
  }) => void;
  isNoLoginGuestExperienceEnabled: boolean;
  viewedGuestProjectBoard: boolean;
  refetch: () => void;
  viewGuestKanban: boolean;
};

const useGuestProjectState = (): GuestProjectState => {
  const context = useContext(GuestProjectStateContext);

  if (context === undefined) {
    throw new Error(
      'useGuestProjectState must be used within a GuestProjectProvider'
    );
  }

  // @ts-ignore
  return context;
};

export { GuestProjectProvider, useGuestProjectState, GuestProjectState };
