import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import queryString from 'qs';
import { locationType } from '../../../utils/propTypes';
import track from '../../../utils/analytics';

import styles from './style.module.css';
import sharedStyles from '../../../components/onboarding/shared.module.css';

export default class InstallError extends React.Component {
  static propTypes = {
    ownerEmail: PropTypes.string.isRequired,
    navigate: PropTypes.func,
    location: locationType,
    authenticityToken: PropTypes.string.isRequired,
    project: PropTypes.shape({ id: PropTypes.number.isRequired }).isRequired
  };

  componentDidMount() {
    track('Onboarding alert displayed', { type: 'extension needed' });

    const qs = queryString.parse(this.props.location.search);

    const formData = new FormData();
    formData.append('validation_token', qs.validation_token);
    formData.append('project_id', this.props.project.id);

    fetch('/accept-invite/embed_code_not_installed', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'X-CSRF-TOKEN': this.props.authenticityToken
      },
      body: formData
    });
  }

  render() {
    return (
      <div className={sharedStyles.container}>
        <div className={sharedStyles.header}>
          <div className={styles.container}>
            <h1 className={styles.heading}>
              Uh oh! A Browser extension is needed for this project
            </h1>
            <p>
              Looks like your website isn’t setup to give feedback without the
              BugHerd browser extension.
            </p>
            <p>
              We’ve emailed{' '}
              <a href={`mailto:${this.props.ownerEmail}`}>
                {this.props.ownerEmail}
              </a>{' '}
              with instructions on how to add the BugHerd JavaScript to your
              website.
            </p>
            <p>
              <strong>
                Please follow up with them to make sure they have received our
                email.
              </strong>
            </p>
            <Button type="primary" onClick={() => this.props.navigate(-1)}>
              Back
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
