import { gql } from '@apollo/client';

const PROJECT_QUERY = gql`
  query ProjectQuery($projectId: ID!) {
    currentUser {
      admin
      userProject(id: $projectId) {
        __typename
        id
        name
        hostnames
        devurl
        feedbackUrl
        allowEmailTasks
        allowEmailComments
        shortcutToShowWidget
        toggleVerticalPosition
        guestPermission
        apiKey
        isPublic
        organizationId
        basicAuthUsername
        basicAuthPassword
        ignoredQuerystrings
        allowProjectSummaryEmail
        allowTaskDoneEmail
        showNewSidebar
        experiments
        createTagsViaNewTask
        allowGuestsChangeTaskStatus
        sites {
          __typename
          id
          url
        }
      }
    }
  }
`;

const UPDATE_PROJECT = gql`
  mutation UpdateProject(
    $projectId: ID!
    $name: String
    $allowEmailComments: Boolean
    $allowEmailTasks: Boolean
    $guestPermission: String
    $isPublic: Boolean
    $shortcutToShowWidget: String
    $toggleVerticalPosition: Int
    $basicAuthUsername: String
    $basicAuthPassword: String
    $ignoredQuerystrings: [String]
    $showNewSidebar: Boolean
    $createTagsViaNewTask: Boolean
  ) {
    updateProject(
      input: {
        projectId: $projectId
        name: $name
        allowEmailComments: $allowEmailComments
        allowEmailTasks: $allowEmailTasks
        guestPermission: $guestPermission
        isPublic: $isPublic
        shortcutToShowWidget: $shortcutToShowWidget
        toggleVerticalPosition: $toggleVerticalPosition
        basicAuthUsername: $basicAuthUsername
        basicAuthPassword: $basicAuthPassword
        ignoredQuerystrings: $ignoredQuerystrings
        showNewSidebar: $showNewSidebar
        createTagsViaNewTask: $createTagsViaNewTask
      }
    ) {
      project {
        __typename
        id
        name
        allowEmailComments
        allowEmailTasks
        guestPermission
        isPublic
        shortcutToShowWidget
        toggleVerticalPosition
        basicAuthUsername
        basicAuthPassword
        ignoredQuerystrings
        showNewSidebar
        experiments
        createTagsViaNewTask
      }
    }
  }
`;

export { PROJECT_QUERY, UPDATE_PROJECT };
