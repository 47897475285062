import React, { FC, useState, useEffect } from 'react';
import { checkForExtension } from 'utils/checkForBugherdExtension';
import InstallModal from './InstallModal';
import styles from './index.module.css';
import { Space } from 'antd';
import track from 'jsUtilities/analytics';
import { fetchWithTimeout } from './util';
import { useOrganizationState } from 'appClients/providers/Organization';

type Props = {
  proxyUrl: string;
  projectSiteId: number;
  projectSiteUrl: string;
  getTriggerElement: (loading: boolean) => React.ReactNode;
  project: any;
  showProxyPreview: boolean;
  apiKey?: string;
  showGuestExtensionModal?: boolean;
  onSharingClick?: () => void;
  openInNewTab?: boolean;
  viewGuestKanban?: boolean;
  openWebsite?: boolean;
  selectedUrl?: string | null;
};

const ProxyCompatibilityChecker: FC<Props> = ({
  proxyUrl,
  projectSiteUrl,
  projectSiteId,
  getTriggerElement,
  project,
  showProxyPreview,
  apiKey,
  showGuestExtensionModal,
  openInNewTab,
  selectedUrl
}) => {
  const container =
    document.getElementById('designAssetsBoard') || document.body;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasExtensionInstalled, setExtensionInstalled] = useState<boolean>(
    false
  );
  const [showInstallModal, setShowInstallModal] = useState<boolean>(false);
  const { javascriptInstalled, widgetCode, id: projectId } = project || {};
  const showInstallBh = !javascriptInstalled && !hasExtensionInstalled;

  const { hasOrganizationExperiment } = useOrganizationState();
  const isLoginlessFeedbackEnabled = hasOrganizationExperiment(
    'no_login_guest_experience'
  );

  const checkIsCompatibleSite = async () => {
    const compatibleEndpoint = `${proxyUrl}/compatibility?url=${projectSiteUrl}`;
    try {
      const response = await fetchWithTimeout(compatibleEndpoint);
      const { compatible } = await response.json();
      return !!compatible;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const showModalOrWebsite = (url: string) => {
    if (!isLoginlessFeedbackEnabled && openInNewTab) {
      return window.open(url, '_blank');
    }
    if (showInstallBh) {
      setShowInstallModal(true);
      track('Shown install modal');
    } else {
      track('Opened Project Site', {
        viaProxy: false
      });
      window.open(url, '_blank');
    }
  };

  const handleTriggerClick = async (url: string) => {
    setIsLoading(true);
    try {
      if (!showProxyPreview || !(await checkIsCompatibleSite())) {
        showModalOrWebsite(url);
      } else {
        const endpoint = `/projects/${projectId}/sites/${projectSiteId}?apikey=${project?.apiKey ||
          apiKey}`;
        const response = await fetch(endpoint);
        if (!response.ok) {
          showModalOrWebsite(url);
          throw new Error(`An error has occured: ${response.status}`);
        }

        track('Opened Project Site', {
          viaProxy: true
        });

        window.location.href = endpoint;
      }
    } catch (error) {
      showModalOrWebsite(url);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!selectedUrl || !projectSiteUrl) return;
    // This is to handle the case where the user clicks on the website card;
    // todo: Allow the parent component to trigger the handleTriggerClick method instead of relying on selectedUrl check
    if (selectedUrl === projectSiteUrl) {
      handleTriggerClick(selectedUrl);
    }
  }, [selectedUrl]);

  useEffect(() => {
    (async () => {
      const exists = await checkForExtension();

      if (exists) {
        setExtensionInstalled(exists);
      }
    })();
  }, []);

  return (
    <>
      <Space className={styles.cardHoverInfoContainer} direction="vertical">
        <div onClick={() => handleTriggerClick(projectSiteUrl)}>
          {getTriggerElement(isLoading)}
        </div>
      </Space>
      <InstallModal
        container={container}
        open={showInstallModal}
        closeInstallModal={() => setShowInstallModal(false)}
        apiKey={apiKey}
        widgetCode={widgetCode}
        url={projectSiteUrl}
        showGuestExtensionModal={showGuestExtensionModal}
      />
    </>
  );
};

export default ProxyCompatibilityChecker;
