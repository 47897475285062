import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.css';
import { Steps, Button, message } from 'antd';
import EmbedCode from '../../../components/embed_code';
import { post } from '../../../utils/fetch';
import VerificationError from '../VerificationError';
import { pollInstallJsVerified } from '../../../utils/installVerificationListener';
import { getLangKey } from '../../../models/Application';
import * as translations from '../strings';

const { Step } = Steps;

const JavascriptTab = ({
  projectEndpoint,
  projectUrl,
  siteErrors,
  lastSiteCheckAt,
  projectId,
  widgetCode,
  javascriptInstalled
}) => {
  const STEP_SUCCESS = 'finish';
  const STEP_PROCESS = 'process';

  const strings = translations[getLangKey()];

  const [isCheckingSiteForScript, setIsCheckingSiteForScript] = useState(false);
  const [responseError, setResponseError] = useState(false);
  const [isVerifiedSuccess, setIsVerifiedSuccess] = useState(
    javascriptInstalled
  );

  const [siteErrorsState, setSiteErrorsState] = useState(siteErrors || []);

  message.config({
    top: 100,
    duration: 5,
    maxCount: 3
  });

  useEffect(() => {
    setIsCheckingSiteForScript(false);
  }, [lastSiteCheckAt]);

  const verifyJavascriptIsInstalled = async () => {
    setIsCheckingSiteForScript(true);
    setResponseError(false);
    setIsVerifiedSuccess(false);

    const response = await post(
      projectEndpoint + '/validate_site_works_with_bugherd',
      {}
    );

    if (response?.status) {
      message.info(strings.inProgressMessage);

      const isEmbedJsVerified = await pollInstallJsVerified();

      setIsCheckingSiteForScript(false);
      if (isEmbedJsVerified) {
        const { siteErrors, javascriptInstalled } = isEmbedJsVerified;
        if (siteErrors) setSiteErrorsState(siteErrors);
        setIsVerifiedSuccess(!!javascriptInstalled);
        if (isEmbedJsVerified.siteErrors?.filter(x => !x.resolved).length > 0) {
          setIsCheckingSiteForScript(false);
        } else if (isEmbedJsVerified.javascriptInstalled) {
          message.info(strings.successMessage);
          setIsCheckingSiteForScript(false);
        }
      } else {
        message.error(strings.errorMessage);
        setResponseError(true);
      }
    } else {
      setResponseError(true);
      setIsCheckingSiteForScript(false);
    }
  };

  const hasScriptErrors =
    siteErrorsState.filter(x => !x.resolved).length > 0 &&
    !isCheckingSiteForScript;

  return (
    <div className={styles.javascriptOuter}>
      <div className={styles.emptyJavascript}>
        <div className={styles.aboutOuter}>
          <p className={styles.javascriptAbout}>
            Save your clients from needing to install the browser extension by
            adding this Javascript code to your site. Note, BugHerd will only
            appear on your website for people who have access to this project.
          </p>
        </div>
        <div>
          <Steps direction="vertical">
            <Step
              title={strings.installJsTitle}
              description={
                <div className={styles.stepOne}>
                  <p className={styles.instruction}>
                    Add this project's Javascript code to the{' '}
                    <code>{`<head>`}</code> of your website.
                  </p>
                  <EmbedCode widgetCode={widgetCode} />
                </div>
              }
            />
            {hasScriptErrors || responseError ? (
              <Step
                status="error"
                title={strings.verifyFailTitle}
                icon={2}
                description={
                  <VerificationError
                    projectId={projectId}
                    projectUrl={projectUrl}
                    siteErrors={siteErrorsState}
                    responseError={responseError}
                  />
                }
              />
            ) : (
              <Step
                icon={2}
                status={isVerifiedSuccess ? STEP_SUCCESS : STEP_PROCESS}
                title={
                  isVerifiedSuccess
                    ? strings.verifiedSuccess
                    : strings.verifyCodeTitle
                }
                description={
                  isVerifiedSuccess ? strings.verfiedSuccessDescription : ''
                }
              />
            )}
          </Steps>
          <Button
            className={styles.verify}
            type="primary"
            loading={isCheckingSiteForScript}
            onClick={verifyJavascriptIsInstalled}
          >
            {isVerifiedSuccess
              ? strings.verifyAgainButton
              : strings.verifyButton}
          </Button>
        </div>
      </div>
    </div>
  );
};

JavascriptTab.propTypes = {
  projectEndpoint: PropTypes.string,
  projectUrl: PropTypes.string,
  siteErrors: PropTypes.array,
  lastSiteCheckAt: PropTypes.string,
  projectId: PropTypes.number,
  widgetCode: PropTypes.string,
  javascriptInstalled: PropTypes.bool
};

JavascriptTab.defaultProps = {
  siteErrors: []
};

export default JavascriptTab;
