import React, { useState, useEffect, useCallback, MouseEvent, FC } from 'react';
import { Virtuoso } from 'react-virtuoso';
import groupSchema from 'jsUtilities/getGroupSchema';
import styles from './index.module.css';
import { fetchBulkRequests } from 'jsApi/CommandApi';
import { accessTo } from 'jsModels/Access';
import BulkArchived from 'jsViews/bulk_archived';
import TaskListItem from './task_list_item';
import { ProjectProvider } from 'appJS/../clients/providers/Project';
import BulkActionsBar from '../bulk_actions_bar';
import {
  BulkAction,
  SELECT_TASK_GROUP,
  SELECT_TASK_RANGE,
  getMultiSelectList,
  wasMultiSelectKeyUsed,
  wasToggleInSelectionGroupKeyUsed
} from '../multiSelect/Util';
import { MAX_NUMBER_OF_TASKS } from '../Cards';
import { AntdWrapper } from '../../../../clients/shared/AntdWrapper';
import { CurrentUserProvider } from '../../../../clients/providers/CurrentUser';
import usePusherSubscribe from '../../../hooks/usePusherSubscribe';
import { Event, Subscription } from '../../../models/Pusher';

type Props = {
  collection: any;
  moveToTaskBoard: (id: number) => void;
  activeTask: number;
  sortAttribute: string;
  sortAscending: boolean;
  isAdminView: boolean;
  isArchiveViewActive: boolean;
  getContainer: HTMLDivElement;
  bulkActions: any;
  closeDetails: () => void;
  handleTaskClick: (taskId: string) => void;
  users?: {
    get: (key: number) => any;
    models: any[];
  };
  taskEditStatus: boolean;
  projectId: number;
  pusherApiKey: string;
  pusherChannelName: string;
  isGuest: boolean;
  subscription?: Subscription;
};

const TaskList: FC<Props> = props => {
  const {
    collection,
    moveToTaskBoard,
    activeTask,
    sortAttribute,
    sortAscending,
    isAdminView,
    isArchiveViewActive,
    getContainer,
    bulkActions,
    closeDetails,
    users,
    taskEditStatus,
    projectId
  } = props;

  const [archiveBulkRequests, setArchiveBulkRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTaskList, setSelectedTaskList] = useState<string[]>([]);

  const fetchCompleted = useCallback(() => {
    setLoading(true);
    fetchBulkRequests({
      projectId: projectId,
      status: 'completed',
      objectAction: 'archive',
      relatedBulkRequestId: ''
    }).then(payload => {
      setArchiveBulkRequests(payload.data);
      setLoading(false);
    });
  }, [projectId]);

  useEffect(() => {
    if (isArchiveViewActive) {
      fetchCompleted();
    }
  }, [isArchiveViewActive, fetchCompleted]);

  const unSelectAll = () => {
    if (selectedTaskList.length > 0) {
      setSelectedTaskList([]);
    }
  };

  const onWindowClick = event => {
    if (event.defaultPrevented) return;
    unSelectAll();
  };

  const onWindowKeyDown = event => {
    if (event.defaultPrevented) return;
    if (event.key === 'Escape') unSelectAll();

    if (
      activeTask &&
      selectedTaskList.length < 1 &&
      (wasMultiSelectKeyUsed(event) || wasToggleInSelectionGroupKeyUsed(event))
    ) {
      setSelectedTaskList([activeTask.toString()]);
    }
  };

  useEffect(() => {
    window.addEventListener('click', onWindowClick);
    window.addEventListener('keydown', onWindowKeyDown);

    return () => {
      window.removeEventListener('click', onWindowClick);
      window.removeEventListener('keydown', onWindowKeyDown);
    };
  }, []);

  const _collection = collection.find(task => !task.isVisible())
    ? collection.filter(task => task.isVisible())
    : collection;

  const collectionClone = _collection.map(task => task.toJSON());

  const groupedTasks = () => {
    const schema = groupSchema(_collection, sortAttribute).find(s =>
      s.attributes.includes(sortAttribute)
    );
    // @ts-expect-error
    const { grouper } = schema;
    // @ts-expect-error
    const groups = JSON.parse(JSON.stringify(schema.groups)); // deep clone array
    collectionClone
      .filter(task => task.id)
      .forEach(task => {
        for (var i = 0; i < groups.length; i++) {
          const group = groups[i];
          const addToGroup = grouper(group, task);

          if (addToGroup) {
            group.tasks.push(task);
            break;
          }
        }
      });

    return groups;
  };

  const sortedTasks = groupedTasks();

  if (!sortAscending) {
    sortedTasks.reverse();
  }

  const multiSelect = (taskId: string, action: BulkAction) => {
    const selectedList = selectedTaskList;
    const columnTaskIdList = collection.map(model =>
      model.get('id').toString()
    );

    if (selectedList.length > MAX_NUMBER_OF_TASKS) {
      setSelectedTaskList(selectedTaskList.slice(0, MAX_NUMBER_OF_TASKS));
    }

    if (
      selectedList.length !== 0 &&
      selectedList.length < MAX_NUMBER_OF_TASKS
    ) {
      const selectedIdList = getMultiSelectList(
        action,
        taskId.toString(),
        selectedList,
        columnTaskIdList
      );
      setSelectedTaskList([...selectedIdList]);
    } else if (selectedList.length < MAX_NUMBER_OF_TASKS) {
      setSelectedTaskList([...selectedTaskList, taskId]);
    }
  };

  const handleTaskClick = (event: MouseEvent | InputEvent, taskId: string) => {
    event.preventDefault();
    // @ts-expect-error
    const isCheckboxChecked = event.target?.checked;

    if (!isCheckboxChecked && selectedTaskList.length < 2) {
      props.handleTaskClick(taskId);
    }

    //TODO: when a task is open and you click on shift you should be able to multiselect as well, this works on the task board
    //Probably have to look at the onWindowKeyDown on line 66 too

    if (wasMultiSelectKeyUsed(event)) {
      multiSelect(taskId.toString(), SELECT_TASK_RANGE);
    } else if (wasToggleInSelectionGroupKeyUsed(event) || isCheckboxChecked) {
      setSelectedTaskList([...selectedTaskList, taskId]);
      multiSelect(taskId.toString(), SELECT_TASK_GROUP);
    } else if (!isCheckboxChecked) {
      const withoutRemovedTask = selectedTaskList.filter(
        currentTaskId => currentTaskId !== taskId
      );
      setSelectedTaskList(withoutRemovedTask);
    } else {
      unSelectAll();
    }
  };

  // limiting the number of multiple selections cause we dont know what the effect will be when we bulk update a tonne of tasks
  useEffect(() => {
    const selectedTotal = selectedTaskList.length;
    if (!isArchiveViewActive) {
      if (selectedTotal > MAX_NUMBER_OF_TASKS) {
        setSelectedTaskList([
          ...selectedTaskList.slice(0, MAX_NUMBER_OF_TASKS)
        ]);
      }
      if (selectedTotal > 1) {
        closeDetails();
      }
    }
  }, [selectedTaskList]);

  const isSelected = (taskId: string): boolean =>
    selectedTaskList.includes(taskId);

  const showBulkArchived =
    archiveBulkRequests.length > 0 && isArchiveViewActive && taskEditStatus;

  const taskActive = selectedTaskList.length >= 1;

  const sortedTasksList = sortedTasks
    .filter(group => group.tasks.length)
    .flatMap(group => [{ type: 'header', title: group.title }, ...group.tasks]);

  const renderCard = (index: number, data) => {
    if (data.type === 'header') {
      return (
        <div className={styles.separatorOuter} key={data.title}>
          <div className={styles.listSeparator} key={data.title}>
            <span className={styles.separatorName}>{data.title}</span>
          </div>
        </div>
      );
    }

    return (
      <TaskListItem
        {...{
          key: data.id,
          task: data,
          index,
          taskEditStatus,
          handleTaskClick,
          moveToTaskBoard,
          isAdminView,
          activeTaskId: activeTask,
          taskActive,
          isArchiveViewActive,
          isSelected: isSelected(data.id?.toString()),
          // @ts-expect-error
          assignedUsers: users
            //@ts-expect-error
            .filter(user => data.assignee_ids.includes(user.get('id')))
            .map(user => ({ ...user.toJSON(), role: user.get('role') }))
        }}
      />
    );
  };

  return (
    <div className={styles.taskListOuter}>
      {!isArchiveViewActive && isAdminView && (
        <BulkActionsBar
          selectedTasks={selectedTaskList}
          getContainer={() => getContainer}
          onClose={unSelectAll}
          bulkActions={bulkActions}
          permissions={{
            editTaskStatus: taskEditStatus,
            editTaskAssignees: accessTo('task_edit_assignees'),
            editTaskDueDate: accessTo('task_edit_due_date'),
            editTaskTags: accessTo('task_edit_tags'),
            editTaskSeverity: accessTo('task_edit_severity')
          }}
        />
      )}
      {showBulkArchived && (
        <BulkArchived
          {...{
            singleRequest: archiveBulkRequests.length === 1,
            getContainer,
            loading,
            setLoading,
            archiveBulkRequests,
            fetchCompleted
          }}
        />
      )}
      <Virtuoso
        data={sortedTasksList}
        totalCount={sortedTasksList.length}
        itemContent={renderCard}
      />
    </div>
  );
};

export default (props: Props) => {
  const {
    pusherApiKey,
    pusherChannelName,
    subscription = Subscription.TRIAGE,
    taskEditStatus: editTaskStatus,
    projectId,
    isGuest
  } = props;
  const [taskEditStatus, setTaskEditStatus] = useState<boolean>(editTaskStatus);

  const handleGuestEditTaskStatusUpdate = (
    isGuestUser: boolean,
    pusherData: any
  ) => {
    if (isGuestUser) {
      setTaskEditStatus(pusherData.guest_edit_task_status);
    }
  };

  usePusherSubscribe({
    pusher: {
      config: {
        pusherApiKey,
        pusherChannelAuthEndpoint: '/pusher/auth'
      },
      projects: [{ id: String(projectId), pusherChannelName }],
      events: [
        {
          name: Event.GUEST_EDIT_STATUS_UPDATED,
          onUpdate: handleGuestEditTaskStatusUpdate.bind(null, isGuest)
        }
      ],
      subscription
    },
    loading: false
  });

  return (
    <AntdWrapper>
      <CurrentUserProvider apiDomain="">
        <ProjectProvider id={`${props.projectId}`} apiDomain="">
          <TaskList {...{ ...props, taskEditStatus }} />
        </ProjectProvider>
      </CurrentUserProvider>
    </AntdWrapper>
  );
};
