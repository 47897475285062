export const en = {
  welcomeHeader: 'Welcome to your project board.',
  sharedWithYou:
    'Here you will find all the website links and design assets shared with you in this project.',
  useBugHerd: 'You can use BugHerd to give feedback on any of these items.',
  getStarted:
    'To get started, simply click on the item you wish to give feedback on.',
  linksHeader: 'Quicklinks',
  owner: 'Owner',
  contact: 'Contact',
  shareProject: 'Share this project with your team:',
  copyShareUrl: 'Copy Share URL',
  copyInvitationSuccess: 'Asset Share URL has been copied to your clipboard.',
  copy: 'Copy',
  websites: 'Websites',
  extensionMessage:
    "Not seeing BugHerd appear on your website? You'll need to install the BugHerd browser extension",
  copySiteSuccess: 'Project Site URL has been copied to your clipboard.',
  creatingCover:
    'Your cover screenshot is being created, and will appear here shortly',
  wentWrong: 'Something went wrong, please try again.',
  getCoverScreenshot: 'Get cover screenshot',
  getCover: 'Get cover',
  website: 'WEBSITE',
  awaitingScreenshot: 'Awaiting screenshot...',
  updated: (readableTime: string) => `Updated: ${readableTime} ago`,
  designAssets: 'Design assets',
  getNewCover: 'Refresh cover',
  share: 'Share'
};

export type Translations = typeof en;
