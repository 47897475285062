import React, { FC } from 'react';
import Avatar from '../../../../../../shared/Avatar';
import { format } from 'date-fns';
import { Log } from '../../../../../models/Task';
import cx from 'classnames';
import styles from './styles.module.css';

const Logs: FC<{ logs: Log[] }> = ({ logs }) => {
  const getDateTime = (date: string): number => new Date(date).getTime();
  return (
    <ul
      className={cx(styles.list, 'eventList')}
      onScroll={event => event.stopPropagation()}
    >
      {logs
        .filter(log => log.changesHtml) // getting rid of empty logs
        .sort((a, b) => getDateTime(b.createdAt) - getDateTime(a.createdAt))
        .map((log: Log) => (
          <li key={log.id} className={styles.listItem}>
            <Avatar size="small" member={log.user} />
            <div className={styles.itemInner}>
              <p className={styles.itemInfo} title={log.createdAt}>
                {format(new Date(log.createdAt), "MMM d 'at' h:mma")}
              </p>
              <p className={styles.item}>
                <span className={styles.userName}>{log.user.name}</span>
                {log.changesHtml}
              </p>
            </div>
          </li>
        ))}
    </ul>
  );
};

export default Logs;
