import React, { FC } from 'react';
import styles from './styles.module.css';
import Emoji from 'appJS/components/Emoji';
import * as translations from '../../strings';
import { getLangKey } from 'appJS/models/Language';

const strings = translations[getLangKey()];

type Project = {
  id: number | string;
};

type Props = {
  project: Project;
};

const WelcomeBanner: FC<Props> = ({ project }) => {
  const messageParts = strings.useBugHerd.split(
    /({feedbackLink}|{taskBoardLink})/
  );

  return (
    <div className={styles.welcome}>
      <div className={styles.welcomeAccent} />
      <h2 className={styles.welcomeHeader}>
        <span className={styles.waveHand}>
          <Emoji symbol="👋" />
        </span>
        {strings.welcomeHeader}
      </h2>
      <p>{strings.welcomeMessage}</p>
      <p>{strings.sharedWithYou}</p>
      <p>
        {' '}
        {messageParts.map((part, index) => {
          if (part === '{feedbackLink}') {
            return (
              <a key={index} href={`/projects/${project.id}/triage`}>
                Feedback
              </a>
            );
          } else if (part === '{taskBoardLink}') {
            return (
              <a key={index} href={`/projects/${project.id}/kanban`}>
                Task Board
              </a>
            );
          } else {
            return <span key={index}>{part}</span>;
          }
        })}
      </p>
    </div>
  );
};

export default WelcomeBanner;
