import React, { FC, ReactNode } from 'react';
import * as Types from '../types';
import { Button } from 'antd';
import * as translations from '../strings';
import { getLangKey } from '../../../../../../javascript/models/Language';
import { Loader, XCircle, X as Close, Info } from 'lucide-react';
import animationStyles from './animation.module.css';
import cx from 'classnames';
import styles from './index.module.css';
import UAParser from 'ua-parser-js';

const parser = new UAParser(navigator.userAgent);

type Props = {
  overlay: Types.Overlay;
  setOverlay: (overlay: Types.Overlay) => void;
  closeModal: () => void;
  subscriptionUrl: string;
  extensionsUrl: string;
  uploadProgress?: number;
};

type Action = {
  text: string;
  url?: string;
  onClick?: () => void;
};

type Back = {
  text: string;
  onClick: () => void;
};

type Content = {
  icon?: ReactNode;
  header?: string;
  message?: string;
  content?: ReactNode;
  close?: ReactNode;
  progress?: number;
  action?: Action;
  back?: Back;
} | null;

const strings = translations[getLangKey()];

const Overlay: FC<Props> = ({
  overlay,
  setOverlay,
  closeModal,
  subscriptionUrl,
  extensionsUrl,
  uploadProgress
}) => {
  const os = parser.getOS().name + ' ' + parser.getOS().version;
  const isMac: boolean = os.toLowerCase().includes('mac');

  const shortcuts = [
    {
      shortcut: `${strings[isMac ? 'command' : 'control']} + ${strings.return}`,
      label: strings.submitTask
    },
    {
      shortcut: strings.esc,
      label: strings.closeModal
    },
    {
      shortcut: strings.emojiShortcut(isMac),
      label: strings.addEmoji
    }
  ];

  const content = (): Content => {
    switch (overlay) {
      case 'shortcuts':
        return {
          header: strings.keyboardShortcuts,
          content: (
            <div className={styles.content}>
              {shortcuts.map(({ shortcut, label }, index) => (
                <div
                  className={cx(styles.wrapper, { [styles.macWrapper]: isMac })}
                  key={index + shortcut}
                >
                  <div className={styles.codeContainer}>
                    <code>{shortcut}</code>
                  </div>
                  <span>{label}</span>
                </div>
              ))}
            </div>
          ),
          close: (
            <div
              className={styles.closeContainer}
              onClick={() => setOverlay(null)}
            >
              <Close className={styles.closeIcon} />
            </div>
          )
        };
      case 'loading':
        return {
          icon: (
            <Loader
              className={cx(styles.loaderIcon, animationStyles.loaderIcon)}
            />
          ),
          message: strings.creatingTask
        };
      case 'uploading':
        return {
          icon: (
            <Loader
              className={cx(styles.loaderIcon, animationStyles.loaderIcon)}
            />
          ),
          message: strings.videoUploading,
          progress: uploadProgress
        };
      case 'error':
        return {
          icon: <XCircle className={styles.errorIcon} />,
          message: strings.couldntCreate,
          action: {
            text: strings.tryAgain,
            onClick: () => setOverlay(null)
          }
        };
      case 'accountInUse':
        return {
          icon: <Info className={styles.infoIcon} />,
          message: strings.accountInUse,
          action: {
            text: strings.close,
            onClick: () => {
              setOverlay(null);
              closeModal();
            }
          }
        };
      case 'extensionlessUpgrade':
        return {
          header: strings.premiumFeature,
          message: strings.extensionlessUnavailable,
          action: {
            text: strings.upgrade,
            url: subscriptionUrl
          },
          back: {
            text: strings.close,
            onClick: () => setOverlay(null)
          }
        };
      case 'screenshotGenerating':
        return {
          header: strings.screenshotPending,
          message: strings.pendingMessage,
          action: {
            text: strings.bugherdExtensions,
            url: extensionsUrl
          },
          back: {
            text: strings.close,
            onClick: () => setOverlay(null)
          }
        };
      default:
        return null;
    }
  };

  const overlayContent = content();

  if (!overlayContent) {
    return null;
  } else {
    const {
      icon,
      header,
      message,
      action,
      back,
      progress,
      content,
      close
    } = overlayContent;

    return (
      <div
        className={cx(styles.overlayOuter, {
          [styles.shortcuts]: overlay === 'shortcuts'
        })}
      >
        {icon}
        {header && <h3 className={styles.moreInfoHeader}>{header}</h3>}
        <div className={styles.messageOuter}>
          {message && <p className={styles.moreInfoMessage}>{message}</p>}
          {typeof progress === 'number' && progress >= 0 && (
            <div className={styles.progressBar}>
              <div
                style={{ width: `${progress}%` }}
                className={cx(styles.progressInner, {
                  [styles.almostComplete]: progress >= 80
                })}
              />
            </div>
          )}
          {content}
        </div>
        <div className={styles.actionsContainer}>
          {action && (
            <Button
              type="primary"
              className={styles.action}
              onClick={() => action.onClick && action.onClick()}
              href={action && action.url}
            >
              {action.text}
            </Button>
          )}
          {back && (
            <Button className={styles.goBack} onClick={() => back.onClick()}>
              {back.text}
            </Button>
          )}
        </div>
        {close && close}
      </div>
    );
  }
};

export default Overlay;
