import React, { FC } from 'react';
import Comment from './Comment';
import { Comment as CommentType } from '../../../../../../models/Comment';
import Loader from '../../../../../../components/Loader';
import styles from './styles.module.css';
import {
  CommentsState,
  useCommentsState
} from '../../../../../../../providers/Comments';

const CommentStream: FC = () => {
  const {
    comments,
    commentsLoading,
    canViewPrivateComments
  } = useCommentsState() as CommentsState;

  if (commentsLoading) {
    return (
      <div className={styles.loaderContainer}>
        <Loader useDarkStyles className={styles.loaderIcon} />
      </div>
    );
  }

  const getSortedComments = () => {
    return (comments || [])
      .slice(0)
      .sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
  };

  return (
    <div className="commentsList" onScroll={event => event.stopPropagation()}>
      {getSortedComments()
        .filter(comment => canViewPrivateComments || !comment.isPrivate)
        .map(
          ({
            id,
            userId,
            commitUrl,
            user,
            text,
            createdAt,
            isPrivate,
            readByUsers
          }: CommentType) => (
            <Comment
              key={id}
              id={id}
              userId={userId}
              commitUrl={commitUrl}
              text={text}
              createdAt={createdAt}
              isPrivate={isPrivate}
              commenter={user}
              readByUsers={readByUsers}
            />
          )
        )}
    </div>
  );
};

export default CommentStream;
