import React, { FC, useState, useEffect } from 'react';
import styles from './index.module.css';
import { FileText, Image, PlusCircle } from 'lucide-react';
import { Button, Upload, Form, message, Tooltip } from 'antd';
import { useDesignAssetsState } from '../providers/DesignAssets';
import { default as FigmaLogo } from './assets/figma_logo.svg';
import { validateUrl } from 'utils/validateUrls';
import ProjectUrlInput from '../../javascript/components/projectUrlInput';
import track from 'jsUtilities/analytics';
import { useProjectSitesState } from '../providers/ProjectSites';
import { addProjectSites } from './ProjectSites';
import { post } from '../../../javascript/utils/fetch';
import { fetchWithTimeout } from './util';
import { useOrganizationState } from '../providers/Organization';
import Tour from 'reactour';

type Props = {
  project: any;
};

type Step = {
  selector: string;
  content: React.JSX.Element;
  action?: (node: Node) => void;
  showButtons?: boolean;
};

const addWebsiteInputElement = '#addWebsiteForm';

const AssetsHeader: FC<Props> = ({ project }) => {
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [projectUrl, setProjectUrl] = useState<string>('');

  const { handleOpenFigma, onUploadChange } = useDesignAssetsState();
  const { handleGetProjectSites, proxyHost } = useProjectSitesState();
  const { hasOrganizationExperiment } = useOrganizationState();
  const [form] = Form.useForm();

  const { apiKey, id: projectId, showAddWebsiteOnboarding } = project;
  const hasNoProjectSites = !project?.websites || !project?.websites.length;
  const hasChecklistOnboardingExperiment = hasOrganizationExperiment(
    'checklist_onboarding_variant'
  );
  const hasAddWebsiteOnboardingExperiment = hasOrganizationExperiment(
    'add_website_onboarding_variant'
  );
  const hasOnboardingExperiment =
    hasChecklistOnboardingExperiment || hasAddWebsiteOnboardingExperiment;

  const tourSteps: Step[] = [
    {
      selector: addWebsiteInputElement,
      content: (
        <>
          <h2>Enter a website for your project</h2>
          <p>Click add website to start using BugHerd</p>
        </>
      ),
      action: node => {
        node.focus();
        setIsTourOpen(true);
      }
    }
  ];

  const handleUrlChange = (value: string) => {
    setProjectUrl(value);
  };

  const completeAddWebsiteOnboarding = async () => {
    try {
      await post(`/projects/${projectId}/complete_add_website_onboarding`, {
        project_id: project.id
      });
      localStorage.setItem('completed_add_website_onboarding', 'true');
    } catch (error) {
      console.error(error);
    }
  };

  const checkIsCompatibleSite = async projectSite => {
    const proxyUrl = `https://${project.apiKey}_${projectSite.site.id}.${proxyHost}`;
    const compatibleEndpoint = `${proxyUrl}/compatibility?url=${projectSite.site.url}`;

    try {
      const response = await fetchWithTimeout(compatibleEndpoint);
      const { compatible } = await response.json();
      return !!compatible;
    } catch (error) {
      console.error(error);
      // eslint-disable-next-line no-undef
      window?.bugsnagClient?.notify(error);
      return false;
    }
  };

  const shouldRedirectToPreview = async response => {
    if (!hasNoProjectSites || !hasOnboardingExperiment) {
      return false;
    }

    const isCompatible = await checkIsCompatibleSite(response);
    return isCompatible;
  };

  const handleSuccessfulResponse = async response => {
    if (response?.project_site) {
      message.info('Your website has been updated');
      setProjectUrl(response.project_site.url);
      return;
    }

    setProjectUrl('');
    form.setFieldsValue({ WebsiteURL: '' });
    message.success('Website added successfully');
    track('Add Website');

    if (hasAddWebsiteOnboardingExperiment && showAddWebsiteOnboarding) {
      completeAddWebsiteOnboarding();
    }

    if (await shouldRedirectToPreview(response)) {
      const endpoint = `/projects/${projectId}/sites/${response.site.id}?apikey=${apiKey}`;
      window.location.href = endpoint;
    } else {
      setIsTourOpen(false);
    }
  };

  const handleSubmitUrl = async () => {
    try {
      setIsSubmitting(true);
      await form.validateFields(['WebsiteURL']);
      const { status } = await validateUrl(projectUrl);

      if (status !== 'success' && status !== 'warning') {
        return;
      }

      const response = await addProjectSites(apiKey, projectUrl, projectId);
      await handleSuccessfulResponse(response);
      handleGetProjectSites();
    } catch (error) {
      console.error('Error submitting URL:', error);
      message.error('Failed to add website');
    } finally {
      setIsSubmitting(false);
      setProjectUrl('');
    }
  };

  const disabledWebsiteButton =
    !projectUrl.length || projectUrl === 'http://' || projectUrl === 'https://';

  useEffect(() => {
    if (!hasAddWebsiteOnboardingExperiment) return;
    const hasCompletedTour =
      localStorage.getItem('completed_add_website_onboarding') === 'true' ||
      !showAddWebsiteOnboarding;
    setIsTourOpen(!hasCompletedTour);
  }, [hasAddWebsiteOnboardingExperiment, showAddWebsiteOnboarding]);

  return (
    <div className={styles.deliverablesWrapper}>
      <h2>Websites & Files</h2>
      <div className={styles.buttonsWrapper}>
        <div className={styles.addWebsiteWrapper}>
          <Form
            form={form}
            className={styles.addWebsiteForm}
            onFinish={handleSubmitUrl}
            id="addWebsiteForm"
          >
            <ProjectUrlInput
              fieldName={['WebsiteURL']}
              currentUrl={projectUrl}
              onChange={handleUrlChange}
              isUrlRequired={false}
              placeHolder="Enter website URL"
              checkUrlOnEnter
              shouldValidateDNS
            />
            <Tooltip
              title={
                !projectUrl.length
                  ? "First enter a website URL, then click '+ Add website'"
                  : ''
              }
            >
              <Button
                type="primary"
                htmlType="submit"
                className={styles.saveButton}
                loading={isSubmitting}
                disabled={disabledWebsiteButton}
              >
                + Add website
              </Button>
            </Tooltip>
          </Form>
        </div>

        <span className={styles.orDivider}>or</span>

        <Upload
          name="file"
          onChange={onUploadChange}
          showUploadList={false}
          multiple
          customRequest={() => {}}
          accept=".pdf"
        >
          <Button
            className={styles.uploadDeliverable}
            icon={<FileText />}
            type="primary"
          >
            PDF
            <PlusCircle className={styles.circlePlus} />
          </Button>
        </Upload>
        <Upload
          name="file"
          onChange={onUploadChange}
          showUploadList={false}
          multiple
          customRequest={() => {}}
          accept="image/*"
        >
          <Button
            className={styles.uploadDeliverable}
            icon={<Image />}
            type="primary"
          >
            Image
            <PlusCircle className={styles.circlePlus} />
          </Button>
        </Upload>
        <Button
          onClick={handleOpenFigma}
          className={`${styles.uploadDeliverable} ${styles.figmaButton}`}
          icon={<FigmaLogo />}
          type="primary"
        >
          {'Figma'}
          <PlusCircle className={styles.circlePlus} />
        </Button>
        <Tour
          steps={tourSteps}
          isOpen={isTourOpen}
          accentColor="#3f71e0"
          closeWithMask={false}
          disableInteraction={false}
          disableDotsNavigation
          disableFocusLock
          lastStepNextButton={<Button type="primary">Finish</Button>}
          rounded={12}
          scrollDuration={500}
          showNavigation={false}
          showCloseButton={false}
          showNumber={false}
          showButtons={false}
        />
      </div>
    </div>
  );
};

export default AssetsHeader;
