import * as React from 'react';
import { App, ConfigProvider } from 'antd';

import { antdTheme } from '../../../clients/shared/theme';
import { getCurrentProjectName, getCurrentProjectUrl } from 'models/Project';
import {
  getCurrentProjectId,
  getCurrentOrganizationId,
  getCurrentUserId
} from 'models/Organization';

import Cards from './Cards';
import Onboarding from './Onboarding';
import { KanbanProps as Props } from './types';
import ProjectTour from './ProjectTour';

import styles from '../../../clients/shared/overrides.module.css';
import { CurrentUserProvider } from '../../../clients/providers/CurrentUser';
import { Provider } from 'react-redux';

const Kanban: React.FC<Props> = props => {
  const {
    showOnboarding,
    isJavascriptInstalled,
    kanbanLoading,
    isExtensionInstalled,
    widgetCode,
    siteErrors,
    lastSiteCheckAt,
    isTaskCreated,
    experiments,
    closeTask
  } = props;

  const hasNewOnboardingExperiment =
    window?.project_board_props?.assetFeedbackProps?.project
      ?.hasNewOnboardingExperiment;
  const hasChecklistOnboardingExperiment = experiments.includes(
    'checklist_onboarding_variant'
  );
  const hasAddWebsiteOnboardingExperiment = experiments.includes(
    'add_website_onboarding_variant'
  );
  const hasNewOnboarding =
    hasNewOnboardingExperiment ||
    hasChecklistOnboardingExperiment ||
    hasAddWebsiteOnboardingExperiment;

  const loadOnboardingDialog = showOnboarding;
  const showProjectTour = !kanbanLoading && showOnboarding && hasNewOnboarding;
  return (
    <CurrentUserProvider
      apiDomain={''}
      projectId={Number(getCurrentProjectId())}
    >
      <ConfigProvider theme={antdTheme}>
        <Provider store={window.bugherdStore}>
          <App className={styles.antdOverrides}>
            <Cards {...props} />
            {showProjectTour && (
              <ProjectTour
                projectId={getCurrentProjectId()}
                closeTask={closeTask}
              />
            )}
            {!kanbanLoading && loadOnboardingDialog && !hasNewOnboarding && (
              <Onboarding
                showOnboarding={showOnboarding}
                isWebsiteVisited={isTaskCreated}
                isJavascriptInstalled={isJavascriptInstalled}
                projectName={getCurrentProjectName()}
                projectUrl={getCurrentProjectUrl()}
                projectId={getCurrentProjectId()}
                userId={getCurrentUserId()}
                organizationId={getCurrentOrganizationId()}
                isExtensionInstalled={isExtensionInstalled}
                widgetCode={widgetCode}
                siteErrors={siteErrors}
                lastSiteCheckAt={lastSiteCheckAt}
                experiments={experiments}
              />
            )}
          </App>
        </Provider>
      </ConfigProvider>
    </CurrentUserProvider>
  );
};

export default Kanban;
