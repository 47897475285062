import { useState } from 'react';
import { post } from '../../../javascript/utils/fetch';
import { message } from 'antd';
import { getFileInfo } from '../../../javascript/utils/fileListOperations';
import { UploadFile } from 'antd/lib/upload/interface';
import { UploadChangeParam } from 'antd/lib/upload';
import { FileType } from '../../../javascript/utils/fileListOperations';
import uploadToS3 from '../../../javascript/utils/uploadToS3';
import { UploadAsset } from '../../../clients/design_assets/types';

const MAX_FILE_SIZE = 20485760;

const upLoadAssets = project => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const uploadAsset: UploadAsset = params => {
    const { onComplete } = params;
    uploadToS3({
      ...params,
      onComplete: async (
        file: FileType,
        _S3URL: string,
        key: string,
        callbackUrl: string,
        is_figma?: boolean
      ) => {
        onComplete();
        setIsComplete(true);
        setIsLoading(false);
        try {
          const response = await post(callbackUrl, {
            key,
            file_name: file.name,
            is_figma: !!is_figma
          });
          if (response?.id) {
            window.location.href = `/projects/${project.id}/assets/${response.id}?new-onboarding=true`;
          }
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  const onUploadChange = (
    event: UploadChangeParam<any>,
    is_figma: boolean
  ): void => {
    const file = event?.file?.originFileObj as UploadFile;
    if (!file) {
      message.error('Unrecognized file type uploaded');
      return;
    }
    if (!file.size || file.size > MAX_FILE_SIZE) {
      message.error(
        `Sorry, the file (${file.name}) is too large. Maximum file size is 20mb.`
      );
      return;
    }
    const _file = getFileInfo(file);

    const uploadParameters = {
      file: _file,
      remove: () => {},
      endpoint: `/projects/${project.id}/assets/new`,
      additionalQueryParams: {
        file_name: _file.name,
        is_figma: is_figma
      },
      feedback: message.error,
      onStart: () => {
        setIsLoading(true);
      },
      onProgress: progressEvent => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadProgress(percentCompleted);
      },
      onError: () => {},
      onComplete: () => {}
    };
    uploadAsset(uploadParameters);
  };

  return {
    onUploadChange,
    isLoading,
    isComplete,
    uploadProgress
  };
};

export { upLoadAssets };
