import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import KanbanCard from '../../card';

import styles from './style.module.css';

interface DNDCardProps {
  id: number;
  due_at: string;
  local_task_id: number;
  asset_id: number | object;
  tag_ids: number[];
  tags: object;
  description: string;
  priority_id: number;
  index: number;
  canDrag: boolean;
  setActiveTask: () => void;
  moveTaskToFeedback: () => void;
  moveTaskToArchive: () => void;
  getAssignees: () => void;
  isActive: boolean;
  action: string;
  multiSelect: () => void;
  unSelectAll: () => void;
  selectedTaskList: any[];
  asset_type?: string;
  comments: { mention_ids: number[]; read_by_user_ids: number[] }[];
  hasScreenRecording: boolean;
  disableDrag?: boolean;
  viewGuestKanban: boolean;
}

const DNDCard: React.FC<DNDCardProps> = ({
  id,
  due_at,
  local_task_id,
  asset_id,
  tag_ids,
  tags,
  description,
  priority_id,
  index,
  canDrag,
  setActiveTask,
  moveTaskToFeedback,
  moveTaskToArchive,
  getAssignees,
  isActive,
  action,
  multiSelect,
  unSelectAll,
  selectedTaskList,
  asset_type,
  comments,
  hasScreenRecording,
  disableDrag,
  viewGuestKanban
}) => {
  return (
    <Draggable
      draggableId={`${id}`}
      key={`${id}`}
      index={index}
      isDragDisabled={!canDrag || disableDrag}
    >
      {(provided, snapshot) => {
        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            className={snapshot.isDragging ? styles.dragging : null}
          >
            <KanbanCard
              id={id}
              due_at={due_at}
              local_task_id={local_task_id}
              asset_id={asset_id}
              asset_type={asset_type}
              tag_ids={tag_ids}
              tags={tags}
              description={description}
              priority_id={priority_id}
              setActiveTask={setActiveTask}
              action={action}
              isActive={isActive}
              moveTaskToFeedback={moveTaskToFeedback}
              moveTaskToArchive={moveTaskToArchive}
              getAssignees={getAssignees}
              dragHandleProps={provided.dragHandleProps}
              multiSelect={multiSelect}
              unSelectAll={unSelectAll}
              selectedTaskList={selectedTaskList}
              comments={comments}
              hasScreenRecording={hasScreenRecording}
              viewGuestKanban={viewGuestKanban}
              canDrag={canDrag}
            />
          </div>
        );
      }}
    </Draggable>
  );
};

export default DNDCard;
