import React, { useRef, useLayoutEffect, useState } from 'react';
import cx from 'classnames';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import Bowser from 'bowser';
import { Virtuoso } from 'react-virtuoso';
import { isMobile } from 'react-device-detect';

import { getCurrentProjectTags } from 'jsModels/Project';

import Card from '../card/DNDCard';
import KanbanCard from '../../card/index';
import ArchiveAllCard from '../../archive_all_card';
import ColumnHeader from '../../column_header';
import ArchiveSelected from '../../column_header/ArchiveSelected';

import 'simplebar/dist/simplebar.min.css';
import styles from './style.module.css';

import {
  AMOUNT_OF_ARCHIVED_ITEMS,
  ARCHIVE_TASK_LIMIT
} from '../../column_header/types';
import { DesignAsset } from '../../../../../clients/design_assets/types';
import { Task } from 'jsModels/Task';

const browser = Bowser.getParser(window.navigator.userAgent);
const isSafari = browser.satisfies({ safari: '>1' });

const isSafariMoreThan1403 = browser.satisfies({ safari: '>=14.0.3' });

// Virtuoso's resize observer can have this error,
// which is caught by DnD and aborts dragging.
window.addEventListener('error', e => {
  if (
    e.message ===
      'ResizeObserver loop completed with undelivered notifications.' ||
    e.message === 'ResizeObserver loop limit exceeded'
  ) {
    e.stopImmediatePropagation();
  }
});

const isTaskListInColumn = (taskList: string[], cardList: any[]): boolean => {
  // You can only multi select in one column, so we just need to check the first value
  return cardList.find(card => card.id === taskList[0]);
};

const HeightPreservingItem = ({ children, ...props }) => {
  const [size, setSize] = useState(0);
  const knownSize = props['data-known-size'];
  const ref = useRef(null);

  useLayoutEffect(() => {
    setSize(prevSize => {
      return knownSize === 0 ? prevSize : knownSize;
    });
  }, [knownSize]);

  useLayoutEffect(() => {
    if (ref.current) {
      ref.current.style.setProperty('--child-height', `${size}px`);
    }
  }, [size]);

  return (
    <div {...props} ref={ref} className={styles.heightPreservingContainer}>
      {children}
    </div>
  );
};

interface Props {
  id: number;
  index: number;
  cards: any[];
  column: object;
  updateColumnName: () => void;
  updateColumnColor: () => void;
  canDelete: boolean;
  insertFieldForNewColumn: (insertAtIndex: number) => void;
  toggleDeleteModal: () => void;
  toggleArchiveModal: () => void;
  getColumnToDelete: (columnId: any) => void;
  getArchive: (tasksToArchive: any, columnId: any) => void;
  setActiveTask: (activeTask: number) => void;
  activeTaskId: number;
  moveTaskToFeedback: () => void;
  moveTaskToArchive: () => void;
  getAssignees: () => void;
  columnAction: string;
  isActive: boolean;
  multiSelect: (taskId: any, action: any, columnId: any) => void;
  selectedTaskList: any[];
  unSelectAll: () => void;
  closeDetails: (...args: unknown[]) => unknown;
  bulkArchiveTasks: () => void;
  unfilteredTaskCount: number;
  goToArchivePage: (...args: unknown[]) => unknown;
  getCurrentRole: (...args: unknown[]) => unknown;
  assets: DesignAsset[];
  viewGuestKanban: boolean;
  editTaskStatus: boolean;
}

const DNDColumn: React.FC<Props> = ({
  id,
  cards,
  index,
  column,
  updateColumnName,
  updateColumnColor,
  canDelete,
  insertFieldForNewColumn,
  toggleDeleteModal,
  toggleArchiveModal,
  getArchive,
  getColumnToDelete,
  setActiveTask,
  activeTaskId,
  moveTaskToFeedback,
  moveTaskToArchive,
  getAssignees,
  columnAction,
  isActive,
  selectedTaskList,
  unSelectAll,
  closeDetails,
  bulkArchiveTasks,
  unfilteredTaskCount,
  goToArchivePage,
  getCurrentRole,
  assets,
  multiSelect,
  viewGuestKanban,
  editTaskStatus
}) => {
  // Store this variable on the window to be used by web vitals
  window.BUGHERD_VIRTUALISED_KANBAN_LOADED = true;

  const multiSelectWrapper = (taskId, action) => {
    multiSelect(taskId, action, index);
    closeDetails();
  };

  const canManageColumns = window.bugherd?.application.get('access_to')[
    'manage_column'
  ];

  const archiveAllowed = editTaskStatus && columnAction === 'toArchive';

  const tags = getCurrentProjectTags();

  const isManagerOrOwner =
    getCurrentRole() === 'billing_manager' ||
    getCurrentRole() === 'owner' ||
    getCurrentRole() === 'billing' ||
    getCurrentRole() === 'manager';

  const getAsset = (card): DesignAsset | undefined =>
    assets?.find(({ id }) => id === card.props.data.asset_id);

  const renderCard = (index: number, provided: any) => {
    const cardIndex = index;
    const card = cards[cardIndex];

    const cardId = card.props.data.id;
    const asset = getAsset(card);
    const hasScreenRecording =
      !!card.data.screenshot_url?.endsWith('.mp4') ||
      !!card.data.screenshot_url?.endsWith('.webm');

    const commonProps = {
      due_at: card.props.data.due_at,
      local_task_id: card.props.data.local_task_id,
      asset_id: card.props.data.asset_id,
      asset_type: asset?.isFigma ? 'figma' : asset?.fileType,
      tag_ids: card.props.data.tag_ids,
      tags: tags,
      description: card.props.data.description,
      priority_id: card.props.data.priority_id,
      index: index,
      id: cardId,
      canDrag: editTaskStatus,
      setActiveTask: setActiveTask,
      isActive:
        activeTaskId === cardId ||
        (!!cardId &&
          selectedTaskList.length > 0 &&
          selectedTaskList.includes(cardId.toString())),
      moveTaskToFeedback: moveTaskToFeedback,
      moveTaskToArchive: moveTaskToArchive,
      getAssignees: getAssignees,
      action: columnAction,
      multiSelect: multiSelectWrapper,
      unSelectAll: unSelectAll,
      selectedTaskList: selectedTaskList,
      comments: card.props.data.comments || [],
      hasScreenRecording: hasScreenRecording,
      viewGuestKanban
    };

    return provided ? (
      <KanbanCard {...commonProps} dragHandleProps={provided.dragHandleProps} />
    ) : (
      <Card {...commonProps} disableDrag={isMobile} />
    );
  };

  return (
    <Draggable
      draggableId={`${id}`}
      index={index}
      isDragDisabled={!canManageColumns}
    >
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={cx(styles.column, {
            [styles.cardDraggingColumn]: isActive
          })}
        >
          <ColumnHeader
            column={column}
            updateColumnName={updateColumnName}
            updateColumnColor={updateColumnColor}
            isMinColumns={canDelete}
            canManageColumn={canManageColumns}
            insertFieldForNewColumn={() => insertFieldForNewColumn(index + 1)}
            toggleDeleteModal={toggleDeleteModal}
            toggleArchiveModal={toggleArchiveModal}
            getColumnToDelete={getColumnToDelete}
            getArchive={getArchive}
            dragHandleProps={provided.dragHandleProps}
            archiveAllowed={archiveAllowed}
            cards={cards}
            bulkArchiveTasks={bulkArchiveTasks}
            unfilteredTaskCount={unfilteredTaskCount}
          />

          {archiveAllowed &&
            selectedTaskList.length > 1 &&
            isTaskListInColumn(selectedTaskList, cards) && (
              <ArchiveSelected
                selectedTaskList={selectedTaskList}
                columnId={column.id}
                onCompleted={() => {
                  closeDetails();
                  bulkArchiveTasks(
                    selectedTaskList.slice(0, ARCHIVE_TASK_LIMIT)
                  );
                  unSelectAll();
                }}
              />
            )}
          <Droppable
            droppableId={JSON.stringify({
              id: column.id,
              status_id: column.status_id
            })}
            type="card"
            key={`droppable-${JSON.stringify({
              id: column.id,
              status_id: column.status_id
            })}`}
            mode="virtual"
            renderClone={(provided, _snapshot, rubric) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                className="antd-container"
              >
                {renderCard(rubric.source.index, provided)}
              </div>
            )}
          >
            {dropProvided => (
              <div
                className={styles.cardsContainer}
                {...dropProvided.droppableProps}
                ref={dropProvided.innerRef}
                style={(() => {
                  /*
                      There is a bug in how Safari 14.0.3 calculates
                      overflows resulting in visible overflow on this container
                      preventing the child container from scrolling on overflow.
                      This still occurs in safari 16.5
                    */
                  if (isSafariMoreThan1403) {
                    return { display: 'flex' };
                  } else if (isSafari) {
                    return { display: 'flex', overflow: 'visible' };
                  } else {
                    return {};
                  }
                })()}
              >
                {column.children.length > AMOUNT_OF_ARCHIVED_ITEMS &&
                  isManagerOrOwner &&
                  archiveAllowed && (
                    <ArchiveAllCard
                      bulkArchiveTasks={bulkArchiveTasks}
                      column={column}
                      goToArchivePage={goToArchivePage}
                      cards={cards}
                    />
                  )}
                <Virtuoso
                  components={{
                    Item: HeightPreservingItem
                  }}
                  totalCount={cards.length}
                  itemContent={renderCard}
                  scrollerRef={dropProvided.innerRef}
                />
              </div>
            )}
          </Droppable>
        </div>
      )}
    </Draggable>
  );
};

export default DNDColumn;

export interface KanbanColumn {
  columnId: number;
  statusId: string;
  title: string;
  color: string;
  tasks: Task[];
}
