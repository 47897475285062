import React, { FC, useState, useEffect } from 'react';
import { Button, Typography, Modal } from 'antd';

import { getLangKey } from 'models/Language';
import { View } from '../project_nav/types';

import AddWebsiteModal from '../../../../clients/design_assets/AddWebsiteModal';
import Step from './Step';

import * as translations from './strings';
import styles from './styles.module.css';
import UpgradeIcon from './assets/upgrade.svg';
import { trackPage } from 'appJS/utils/analytics';
import gettingStartedThumbnail from './getting-started-thumbnail.png';
import { Play, PlayCircle } from 'lucide-react';

const strings = translations[getLangKey()];

type Props = {
  project: any;
  setView: (view: View | null) => void;
  gettingStartedCheck: {
    hasSeenOnboardingVideo: boolean;
    hasDeliverables: boolean;
    hasManagedFeedback: boolean;
    hasInvitedUsers: boolean;
    hasIntegrations: boolean;
  };
  canAccessBilling: boolean;
  handleChecklistComplete: () => void;
  showAssetsPage: () => void;
  container: HTMLDivElement;
  kanbanCount: number;
  proxyHost: string;
};

const GettingStartedChecklist: FC<Props> = ({
  project,
  setView,
  gettingStartedCheck,
  canAccessBilling,
  handleChecklistComplete,
  showAssetsPage,
  container,
  proxyHost
}) => {
  const [isWebsiteModalOpen, setIsWebsiteModalOpen] = useState(false);
  const [isGettingStartedComplete, setIsGettingStartedComplete] = useState(
    false
  );
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

  const showVideoModal = () => {
    setIsVideoModalOpen(true);
    localStorage.setItem('checklist_onboarding_video_seen', 'true');
  };

  const mandatoryCompletedStepsCount = () => {
    const filteredGettingStartedCheck = Object.fromEntries(
      Object.entries(gettingStartedCheck).filter(
        ([key]) => key !== 'hasIntegrations'
      )
    );
    return Object.values(filteredGettingStartedCheck).filter(Boolean).length;
  };

  useEffect(() => {
    trackPage('View Setup guide page', {
      project_id: project?.id
    });
  }, []);

  useEffect(() => {
    if (isGettingStartedComplete) {
      handleChecklistComplete();
    }
  }, [isGettingStartedComplete]);

  useEffect(() => {
    const completedStepsCount = Object.values(gettingStartedCheck).filter(
      Boolean
    ).length;
    const totalStepsCount = Object.values(gettingStartedCheck).length;

    if (completedStepsCount === totalStepsCount) {
      setIsGettingStartedComplete(true);
    }
  }, [gettingStartedCheck]);

  const handleClickUsersPage = event => {
    event.preventDefault();
    event.stopPropagation();
    window.share_project_props.setRole('member');
    window.share_project_props.setShow(true);
  };

  useEffect(() => {
    // add outline to the first unchecked step/next step to action
    const uncheckedStepContainers: NodeListOf<HTMLDivElement> = document.querySelectorAll(
      `.${styles.stepContainer}:not(.${styles.checkedStep})`
    );

    if (uncheckedStepContainers.length > 0) {
      uncheckedStepContainers[0].style.outline =
        '2px solid var(--colorPrimary)';
    }
  });

  const navigateToKanban = () => {
    setView(View.Kanban);
  };

  const openElevio = e => {
    e.preventDefault();
    //@ts-expect-error
    window._elev?.openHome();
  };

  const isTaskBoardTourFinished =
    project?.projectBoardOnboardingCompleted ||
    localStorage.getItem('finished_project_onboarding');

  const showAlert = () => {
    Modal.info({
      title: 'Add a website or file',
      footer: null,
      content: (
        <span>
          You first have to add a website or file.
          <br />
          <br />
          <Button
            type="primary"
            onClick={() => {
              showAssetsPage();
              Modal.destroyAll();
            }}
          >
            Add
          </Button>
        </span>
      ),
      maskClosable: true,
      closable: true,
      centered: true,
      wrapClassName: 'antd-container'
    });
  };

  const steps = [
    {
      isChecked:
        gettingStartedCheck.hasSeenOnboardingVideo ||
        localStorage.getItem('checklist_onboarding_video_seen'),
      handleClick: showVideoModal,
      title: strings.quickIntroduction,
      description: (
        <div className={styles.quickIntroduction}>
          <p> {strings.quickIntroductionDescription}</p>
          <div className={styles.thumbnailContainer} onClick={showVideoModal}>
            <img
              src={gettingStartedThumbnail}
              className={styles.previewThumbnail}
              alt="getting_started_thumbnail"
            />
            <div className={styles.playCircle}>
              <div className={styles.innerCircle}>
                <Play className={styles.playIcon} />
              </div>
            </div>
          </div>
        </div>
      ),
      actionButtons: (
        <>
          <Button
            type="primary"
            icon={<PlayCircle size={20} />}
            onClick={showVideoModal}
          >
            {strings.watchNow}
          </Button>
        </>
      )
    },
    {
      isChecked: gettingStartedCheck.hasDeliverables,
      handleClick: showAssetsPage,
      title: strings.addADeliverableTitle,
      description: strings.addADeliverableDescription,
      actionButtons: (
        <Button type="primary" onClick={showAssetsPage}>
          {strings.add}
        </Button>
      ),
      elevioArticleId: '84997'
    },
    {
      isChecked: isTaskBoardTourFinished,
      handleClick: () => navigateToKanban,
      title: strings.manageFeedback,
      description: strings.manageFeedbackDescription,
      actionButtons: (
        <>
          <Button
            type="primary"
            onClick={
              gettingStartedCheck.hasDeliverables ? navigateToKanban : showAlert
            }
          >
            {strings.giveFeedback}
          </Button>
        </>
      )
    },
    {
      isChecked: gettingStartedCheck.hasInvitedUsers,
      handleClick: e => handleClickUsersPage(e),
      title: strings.inviteTeamTitle,
      description: strings.inviteTeamDescription,
      actionButtons: (
        <>
          <Button type="primary" onClick={handleClickUsersPage}>
            {strings.share}
          </Button>
        </>
      ),
      elevioArticleId: '84997'
    },
    {
      isChecked: gettingStartedCheck.hasIntegrations,
      handleClick: () => setView(View.Integrations),
      title: strings.setupIntegrationTitle,
      description: strings.setupIntegrationDescription,
      actionButtons: (
        <>
          <Button type="primary" onClick={() => setView(View.Integrations)}>
            {strings.setup}
          </Button>
        </>
      ),
      elevioArticleId: '84997',
      showSkip: mandatoryCompletedStepsCount() === 4
    }
  ];

  const handleUpgradePlan = () => {
    // todo: if user has access to billing, show settings pricing page
    if (canAccessBilling) {
      window.location.href = `/settings/organizations/${project?.organizationId}/subscription`;
    } else {
      window.open('https://bugherd.com/pricing');
    }
  };

  return (
    <div className={styles.container} data-hideLegacyHeader="true">
      <div className={styles.headerAndContentWrapper}>
        <h1>{strings.setupYourProject}</h1>
        <p>{strings.setupIntro}</p>
        <div className={styles.contentWrapper}>
          <div className={styles.stepsContainer}>
            {steps.map(step => {
              return (
                <Step
                  isChecked={step.isChecked}
                  handleClick={step.handleClick}
                  title={step.title}
                  description={step.description}
                  actionButtons={step.actionButtons}
                  showSkip={step.showSkip || false}
                  elevioArticleId={step.elevioArticleId}
                  handleChecklistComplete={handleChecklistComplete}
                />
              );
            })}
          </div>

          <div className={styles.supportContainer}>
            <Typography.Title level={3} className={styles.header}>
              {strings.needHelp}
            </Typography.Title>
            <p>{strings.needHelpDescription}</p>
            <Modal
              open={isVideoModalOpen}
              wrapClassName={`antd-container`}
              className={styles.modal}
              closable
              title={null}
              footer={null}
              centered
              destroyOnClose
              onCancel={() => setIsVideoModalOpen(false)}
            >
              <span className={styles.buttonVideoWrapper}>
                <iframe
                  allow="autoplay; fullscreen; picture-in-picture"
                  src="https://www.loom.com/embed/62d7f4d462c54a90b6d6bdcfd64cf40a?autoplay=1&hide_owner=true&hide_speed=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"
                  frameBorder="0"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                />
              </span>
            </Modal>
            <div className={styles.supportButtons}>
              <Button
                type="default"
                onClick={() => window.open('https://bugherd.com/book-a-demo')}
              >
                {strings.bookADemo}
              </Button>
              <Button
                type="text"
                data-elevio-module="support"
                data-elevio-style="nothing"
                className={styles.button}
              >
                {`-> ${strings.contactSupport}`}
              </Button>
              <Button type="text" onClick={openElevio}>
                {`-> ${strings.readOurSupportDocs}`}
              </Button>
            </div>
            <div
              className={styles.subscriptionPlanContainer}
              onClick={handleUpgradePlan}
            >
              <div className={styles.upgradeBadge}>
                <UpgradeIcon />
                <span>{strings.upgrade}</span>
              </div>
              <Typography.Title level={3} className={styles.header}>
                {strings.chooseTheRightPlan}
              </Typography.Title>
              <span>{strings.chooseTheRightPlanDescription}</span>
              <Button type="default">{strings.exploreOurPlans}</Button>
            </div>
            <AddWebsiteModal
              isWebsiteModalOpen={isWebsiteModalOpen}
              activeItem="javascript"
              container={container}
              handleCloseWebsiteModal={() => setIsWebsiteModalOpen(false)}
              hideInstallInstructions
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GettingStartedChecklist;
