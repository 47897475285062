import React, { FC, useState } from 'react';
import styles from '../styles.module.css';
import { Dropdown } from 'antd';
import { ExternalLink, MoreHorizontal } from 'lucide-react';
import * as translations from '../../../strings';
import cx from 'classnames';
import { getLangKey } from 'appJS/models/Language';
import { useGuestProjectState } from '../../../GuestProjectProvider';

const strings = translations[getLangKey()];

type Props = {
  id: string;
};

export const Menu: FC<Props> = ({ id }) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const { container, onSharingClick } = useGuestProjectState();

  return (
    <Dropdown
      className={styles.siteMenu}
      trigger={['click']}
      getPopupContainer={() => container}
      placement="bottomRight"
      onOpenChange={setIsMenuOpen}
      open={isMenuOpen}
      overlayClassName={styles.overlay}
      menu={{
        items: [
          {
            key: 'share',
            icon: <ExternalLink className={styles.shareIcon} />,
            label: strings.share,
            onClick: () => onSharingClick({ siteId: id })
          }
        ]
      }}
    >
      <MoreHorizontal
        className={cx(styles.moreIcon, { [styles.active]: isMenuOpen })}
      />
    </Dropdown>
  );
};
