import { gql, useQuery } from '@apollo/client';

import { CurrentUser } from 'models/User';
import { Project } from 'models/Project';
import Config from '../../models/Config';

interface GetCurrentUserData {
  currentUser: CurrentUser;
}

interface GetCurrentUserVars {
  organizationId: string;
}

const GET_CURRENT_USER = gql`
  query getCurrentUser($organizationId: ID!) {
    currentUser {
      __typename
      id
      name
      role(organizationId: $organizationId)
      organization(organizationId: $organizationId) {
        __typename
        id
        name
        isActive
        experiments
        owner {
          name
          email
        }
      }
      accessTo(organizationId: $organizationId) {
        viewUserActivities
        createProject
        viewKanban
        disableProject
        deleteProject
        canViewAssets
      }
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation UpdateProject($projectId: ID!, $isActive: Boolean) {
    updateProject(input: { projectId: $projectId, isActive: $isActive }) {
      project {
        __typename
        id
        isActive
      }
    }
  }
`;

export const useGetCurrentUserQuery = ({
  organizationId
}: {
  organizationId: string;
}) =>
  useQuery<GetCurrentUserData, GetCurrentUserVars>(GET_CURRENT_USER, {
    variables: {
      organizationId
    }
  });

export interface GetUserProjectsData {
  config: Config;
  currentUser: {
    id: string;
    userProjectsCount: number;
    userProjects: Project[];
  };
}

export interface GetUserProjectsVars {
  organizationId: string;
  offset: number;
  limit: number;
  filter?: {
    name: string | null;
  };
  sortBy?: string;
}

export const GET_USER_PROJECTS = gql`
  query getUserProjects(
    $organizationId: ID!
    $offset: Int!
    $limit: Int!
    $filter: ProjectFilter
    $sortBy: String!
  ) {
    config {
      pusherApiKey
      pusherChannelAuthEndpoint
    }
    currentUser {
      __typename
      id
      userProjectsCount(
        organizationId: $organizationId
        filter: $filter
        sortBy: $sortBy
      )
      userProjects(
        organizationId: $organizationId
        offset: $offset
        limit: $limit
        filter: $filter
        sortBy: $sortBy
      ) {
        __typename
        id
        name
        pusherChannelName
        isActive
        devurl
        hasCompletedChecklist
        coverScreenshot
        coverUpdateRequestedAt
        updatedAt
        openTasksCount
        triageTasksCount
        organizationId
        assets {
          id
        }
      }
    }
  }
`;

export const GET_UNREAD_COUNT = gql`
  query getUnreadCount($projectId: ID!) {
    currentUser {
      userProject(id: $projectId) {
        __typename
        id
        numberOfUnreadComments
        numberOfUnreadMentions
      }
    }
  }
`;
