export const en = {
  assignedTo: 'ASSIGNED TO:',
  unassigned: 'Unassigned',
  searchForUser: 'Search for a user',
  noResults: 'no results',
  noMatchingUsers: 'No matching users found.',
  assignUser: 'Assign user'
};

export type Translations = typeof en;
