import { useState, useEffect } from 'react';
import { put, deleteItem, post } from '../utils/fetch';
import snakeCaseKeys from 'snakecase-keys';
import camelCaseKeys from 'camelcase-keys';

type Comment = {
  id: number;
  text: string;
};

type Props = {
  taskId: number;
  projectId: number;
  bugherdUrl: string;
  comments: Comment[];
  currentUser: any;
};

type EditCommentEvent = {
  type: 'edit';
  text: 'string';
};

type ReadCommentEvent = {
  type: 'read';
};

export type CommentEvent = EditCommentEvent | ReadCommentEvent;

const useComments = ({
  taskId,
  projectId,
  bugherdUrl,
  comments,
  currentUser
}: Props) => {
  const [_comments, setComments] = useState<Comment[]>([]);

  const endpoint = (commentId?: number) =>
    `${bugherdUrl}/projects/${projectId}/tasks/${taskId}/comments${
      commentId ? '/' + commentId : ''
    }`;

  useEffect(() => {
    setComments(camelCaseKeys(comments, { deep: true }));
  }, [taskId, comments]);

  const deleteComment = (commentId: number) => {
    const commentsClone = _comments.slice();
    const commentsToSet = commentsClone.filter(
      ({ id }: Comment) => commentId !== id
    );
    setComments(commentsToSet);
    deleteItem(endpoint(commentId)).catch(error => {
      console.error(error);
      setComments(commentsClone);
    });
  };

  const updateComment = (commentId: number, event: CommentEvent) => {
    const commentsClone = _comments.slice();
    const commentsToEdit = commentsClone.slice();
    const commentToUpdate = commentsToEdit.find(
      ({ id }: Comment) => commentId === id
    );
    let payload = {};

    if (commentToUpdate && event.type === 'edit') {
      commentToUpdate.text = event.text;
      payload = { text: event.text };
    } else if (commentToUpdate && event.type === 'read') {
      payload = { event: 'read', text: commentToUpdate.text };
    }

    setComments(commentsToEdit);

    put(endpoint(commentId), payload).catch(error => {
      console.error(error);
      setComments(commentsClone);
    });
  };

  const createComment = (commentBody: { isPrivate: boolean; text: string }) => {
    const commentsClone = _comments.slice();
    const commentsToEdit = commentsClone.slice();
    const now = new Date().toISOString();
    const newComment = {
      ...commentBody,
      createdAt: now,
      updatedAt: now,
      id: -1,
      user: currentUser,
      userId: currentUser.id,
      readByUsers: [currentUser.id]
    };
    commentsToEdit.push(newComment);
    setComments(commentsToEdit);
    post(endpoint(), snakeCaseKeys(commentBody, { deep: true }))
      .then(response => {
        const comment = commentsToEdit.find(({ id }: Comment) => id === -1);
        if (!comment) return;
        const {
          id,
          isPrivate,
          updatedAt,
          createdAt,
          text,
          userId,
          user,
          readByUsers
        } = camelCaseKeys(response, { deep: true });
        Object.assign(comment, {
          id,
          isPrivate,
          updatedAt,
          createdAt,
          text,
          userId,
          user,
          readByUsers
        });
        setComments(commentsToEdit);
      })
      .catch(error => {
        console.error(error);
        setComments(commentsClone);
      });
  };

  return {
    createComment,
    deleteComment,
    updateComment,
    comments: _comments
  };
};

export default useComments;
