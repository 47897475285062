import { Event } from 'appJS/models/Pusher';

const events = [
  Event.PROJECT_UPDATE,
  Event.ASSET_CREATE,
  Event.ASSET_UPDATE,
  Event.ASSET_DELETE,
  Event.TASK_CREATE,
  Event.TASK_UPDATE,
  Event.TASK_DELETE,
  Event.PROJECT_SITE_UPDATE
];

export const getEvents = (onUpdate: (pusherData: any) => void) =>
  events.map(event => ({ name: event, onUpdate }));
