import React, { FC } from 'react';
import styles from './styles.module.css';
import * as translations from '../strings';
import { getLangKey } from 'appJS/models/Language';
import { Divider } from 'antd';
import { AssetCard } from '../../../../../clients/design_assets/AssetCard';
import { useGuestProjectState } from '../GuestProjectProvider';
import { ShareModal } from '../../../../../clients/design_assets/ShareModal';
import { DesignAsset } from '../../../../../clients/design_assets/types';
import { UNGROUPED } from '../../../../../clients/design_assets/AssetsScreen';

const strings = translations[getLangKey()];

const Assets: FC = () => {
  const {
    data,
    container,
    bugherdUrl,
    getAssetTaskCount,
    hasManagementFeaturesExperiment,
    setShowShareModal,
    showShareModal,
    projectId,
    onSharingClick,
    viewGuestKanban
  } = useGuestProjectState();

  const assets =
    data?.currentUser?.userProject?.assets?.filter(
      asset => asset.status === 'created'
    ) || [];

  // really weird way to get unique array of folder names
  const groups = assets.reduce(
    (result: string[], currentValue: DesignAsset) => {
      if (currentValue.groupName && !result.includes(currentValue.groupName)) {
        result.unshift(currentValue.groupName);
      }
      return result;
    },
    []
  );

  const groupedAssets = groups.map(group => ({
    name: group,
    assets: assets.filter(asset => asset.groupName === group)
  }));

  const ungroupedAssets = {
    name: UNGROUPED,
    assets: assets.filter(asset => !asset.groupName)
  };

  const Assets = () => (
    <>
      <h1>{strings.designAssets}</h1>
      <Divider />
      <div className={styles.assetsContainer}>
        {assets.map(asset => (
          <AssetCard
            asset={{
              ...asset,
              openTasksCount: getAssetTaskCount(asset.id)
            }}
            container={container}
            bugherdUrl={bugherdUrl}
            key={asset.id}
            viewGuestKanban={viewGuestKanban}
          />
        ))}
      </div>
    </>
  );

  const GroupedAssets = () => (
    <div className={styles.assetsContainer}>
      {[...groupedAssets, ungroupedAssets].map(({ name, assets }) => [
        <h1 key={name} className={styles.assetGroupTitle}>
          {name}
        </h1>,
        <Divider key={`${name}_divider`} />,
        assets.map(asset => (
          <AssetCard
            asset={asset}
            key={asset.id}
            container={container}
            bugherdUrl={bugherdUrl}
            handleSharingClick={() => onSharingClick({ assetId: asset.id })}
            viewGuestKanban={viewGuestKanban}
          />
        ))
      ])}
    </div>
  );

  return assets.length ? (
    <div className={styles.container}>
      <ShareModal
        open={!!showShareModal}
        container={container}
        closeModal={() => setShowShareModal(false)}
        shareURL={`${bugherdUrl}/projects/${projectId}/assets/${showShareModal}`}
      />
      {hasManagementFeaturesExperiment ? <GroupedAssets /> : <Assets />}
    </div>
  ) : null;
};

export default Assets;
