import React, { FC } from 'react';
import * as translations from '../../strings';
import { getLangKey } from 'appJS/models/Language';
import styles from './styles.module.css';
import { ProjectSite } from 'appJS/models/Project';
import TaskCount from '../../../../../../clients/design_assets/TaskCount';
import { Link, ExternalLink } from 'lucide-react';
import defaultCover from 'jsViews/user_projects/images/project.png';
import cx from 'classnames';
import { formatDistanceStrict, parseISO } from 'date-fns';
import { useGuestProjectState } from '../../GuestProjectProvider';
import { ImageWrapper } from './ImageWrapper';
import ProxyCompatibilityChecker from '../../../../../../clients/design_assets/ProxyCompatibilityChecker';
import { Menu } from './Menu';
import ShareButton from '../../ShareButton';
import { Button } from 'antd';

type Props = {
  projectSite: ProjectSite;
  hasNewOnboarding?: boolean;
};

const strings = translations[getLangKey()];

const SiteCard: FC<Props> = ({ projectSite, hasNewOnboarding }) => {
  const { id, url, coverUrl, updatedAt } = projectSite;

  const {
    container,
    getSiteTaskCount,
    onSharingClick,
    isNoLoginGuestExperienceEnabled,
    data
  } = useGuestProjectState();

  const currentUser = data?.currentUser;
  const { userProject } = currentUser;
  const { apiKey, bugherdProxyHost: proxyHost } = userProject;

  return (
    <div className={styles.cardContainer}>
      {isNoLoginGuestExperienceEnabled ? (
        [
          <TaskCount
            key={`task-count-${id}`}
            container={container}
            openTasksCount={getSiteTaskCount(projectSite)}
          />,
          <ImageWrapper
            key={`image-wrapper-${id}`}
            hasNewOnboarding={hasNewOnboarding}
            coverUrl={coverUrl}
            id={id}
            url={url}
          />
        ]
      ) : (
        <ProxyCompatibilityChecker
          getTriggerElement={loading => (
            <div
              className={cx(styles.coverContainer, {
                [styles.defaultCover]: !coverUrl
              })}
            >
              <img className={styles.cover} src={coverUrl || defaultCover} />
              <TaskCount
                container={container}
                openTasksCount={getSiteTaskCount(projectSite)}
              />
              <Button
                type="primary"
                loading={loading}
                icon={<ExternalLink size={20} />}
              >
                Open website
              </Button>
            </div>
          )}
          projectSiteId={Number(id)}
          projectSiteUrl={url}
          proxyUrl={`https://${apiKey}_${id}.${proxyHost}`}
          project={userProject}
          showProxyPreview={!!hasNewOnboarding}
          onSharingClick={() => onSharingClick({ siteId: id })}
          openInNewTab
        />
      )}
      <div className={styles.siteInfo}>
        <span className={styles.url}>{url}</span>
        <span className={styles.updated}>
          {strings.updated(
            formatDistanceStrict(new Date(), parseISO(updatedAt))
          )}
        </span>
        <span className={styles.label}>
          <Link className={styles.linkIcon} />
          {strings.website}
        </span>
        {isNoLoginGuestExperienceEnabled ? (
          <Menu id={id} />
        ) : (
          <ShareButton
            onClick={event => onSharingClick({ siteId: id, event })}
          />
        )}
      </div>
    </div>
  );
};

export default SiteCard;
