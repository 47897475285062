import React, { FC } from 'react';
import styles from '../styles.module.css';
import ProxyCompatibilityChecker from '../../../../../../../clients/design_assets/ProxyCompatibilityChecker';
import { Button } from 'antd';
import { ExternalLink } from 'lucide-react';
import AssetImage from '../../../../../../../clients/design_assets/AssetImage';
import emptyProjectSite from '../../../../../../../clients/design_assets/assets/empty_project_site.png';
import { useGuestProjectState } from '../../../GuestProjectProvider';

type Props = {
  hasNewOnboarding?: boolean;
  coverUrl?: string;
  id: string;
  url: string;
};

export const ImageWrapper: FC<Props> = ({
  hasNewOnboarding,
  coverUrl,
  id,
  url
}) => {
  const {
    data,
    onSharingClick,
    isNoLoginGuestExperienceEnabled
  } = useGuestProjectState();
  const userProject = data?.currentUser.userProject;
  const { apiKey, bugherdProxyHost: proxyHost } = userProject || {};

  return (
    <div className={styles.imageWrapper}>
      <div className={styles.slideInButtonWrapper}>
        <ProxyCompatibilityChecker
          getTriggerElement={loading => (
            <Button
              type="primary"
              loading={loading}
              icon={<ExternalLink size={20} />}
            >
              Open website
            </Button>
          )}
          projectSiteId={Number(id)}
          projectSiteUrl={url}
          proxyUrl={`https://${apiKey}_${id}.${proxyHost}`}
          project={userProject}
          showProxyPreview={!!hasNewOnboarding}
          onSharingClick={() => onSharingClick({ siteId: id })}
          showGuestExtensionModal={isNoLoginGuestExperienceEnabled}
        />
      </div>
      {coverUrl ? (
        <AssetImage
          id={Number(id)}
          projectId={undefined}
          url={coverUrl}
          disabled={false}
          showShareHover={!isNoLoginGuestExperienceEnabled}
          onShareClick={
            isNoLoginGuestExperienceEnabled
              ? () => onSharingClick({ siteId: id })
              : undefined
          }
        />
      ) : (
        <div className={styles.loadingWebsiteImage}>
          <img className={styles.cover} src={emptyProjectSite} />
        </div>
      )}
    </div>
  );
};
