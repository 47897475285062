import React, { ReactNode } from 'react';
import InstallBugherd from './installBugherd';
import General from '../project_settings/forms/general/general';
import Security from '../project_settings/forms/security/security';
import IntegrationSettings from '../integration_settings';
import GuestPermissions from '../guest_permissions';
import JavascriptTab from '../install_bugherd/javascript_tab';
import WordPressTab from '../install_bugherd/wordpress_tab';
import InstructionsTab from '../install_bugherd/instructions_tab';
import BrowserExtension from '../install_bugherd/browser_extension';
import Sidebar from '../project_settings/forms/sidebar';
import EditTags from '../project_settings/forms/tags';
import Advanced from '../project_settings/forms/advanced/advanced';
import CommitHooks from './commitHooks';
import DisableProject from './disableProject';
import * as translations from './strings';
import { getLangKey } from 'appJS/models/Language';
import { GetItemArgs, TabItems } from './types';
import styles from './index.module.css';
import cx from 'classnames';
import Loader from '../../../clients/sidebar/components/Loader';
import ExportBugs from './exportBugs';

const strings = translations[getLangKey()];

export const getInstallItems = ({
  project,
  accessTo,
  bugherdUrl,
  user
}: TabItems) => {
  //@ts-expect-error
  const projectData = window?._bugHerd?.data?.project_data;
  const projectEndpoint = `${bugherdUrl}/projects/${projectData?.id}`;
  const javascriptInstalled =
    projectData?.javascript_installed || projectData?.javascript_verified;
  const isAdmin = user?.admin;
  const apiKey = projectData?.api_key;

  const items: GetItemArgs[] = [
    {
      label: `Install Javascript code`,
      key: 'install_js',
      children: (
        <div className={styles.settingsFormWrapper}>
          <JavascriptTab
            {...{
              projectEndpoint,
              projectUrl: project?.feedbackUrl,
              siteErrors: projectData?.site_errors,
              lastSiteCheckAt: projectData?.last_site_check_at,
              projectId: projectData?.id,
              widgetCode: projectData?.widget_code,
              javascriptInstalled
            }}
          />
        </div>
      )
    }
  ];

  if (isAdmin) {
    items.push({
      label: `Browser Extension`,
      key: 'browser_extension',
      children: <BrowserExtension />
    });
  }

  items.push(
    {
      label: `Wordpress plugin`,
      key: 'install_wordpress_plugin',
      children: <WordPressTab {...{ apiKey }} />
    },
    {
      label: `Send instructions`,
      key: 'send_instructions',
      children: (
        <InstructionsTab
          instructionsEndpoint={`${projectEndpoint}/send_help`}
        />
      )
    }
  );

  return items;
};

export const getMobileItems = handleMobileClick => {
  const mobileLabel = ({ label, key }) => (
    <span onClick={() => handleMobileClick(key)}>{label}</span>
  );
  const items = [
    {
      key: 'general',
      label: strings.generalTitle
    },
    {
      key: 'integrations',
      label: strings.integrationsTitle
    },
    {
      key: 'guest_permissions',
      label: strings.guestPermissions
    },
    {
      key: 'install_bugherd',
      label: strings.installBugherd
    },
    {
      key: 'security',
      label: strings.securityTitle
    },
    {
      key: 'sidebar',
      label: strings.sidebar
    },
    {
      key: 'tags',
      label: strings.tags
    },
    {
      key: 'commit_hook',
      label: strings.commitHook
    },
    {
      key: 'export_bugs',
      label: strings.exportBugs
    },
    {
      key: 'disable_/_delete_project',
      label: strings.disableDelete
    },
    {
      key: 'advanced',
      label: strings.advanced
    }
  ];

  return items.map(({ key, label }) => ({
    key,
    label: mobileLabel({ label, key })
  }));
};

const itemChild = ({
  children,
  title,
  hasParagonLoaded,
  key
}: {
  children: ReactNode;
  title: string;
  hasParagonLoaded?: boolean;
  key?: string;
}): ReactNode => (
  <div
    className={cx(styles.settingsFormWrapper, {
      [styles.guestPermissionsView]: key === 'guest_permissions'
    })}
  >
    <h2 className={styles.settingsTitle}>
      {title}
      {hasParagonLoaded !== undefined && !hasParagonLoaded && (
        <Loader className={styles.loader} />
      )}
    </h2>
    {children}
  </div>
);

export const getTabItems = ({
  project,
  accessTo: {
    guestsSeeGuestsAccess,
    guestsEditTaskStatusAllowed,
    mobileScreenshots
  },
  bugherdUrl,
  integrations,
  user,
  bitBucketHook,
  gitHubHook,
  gitLabHook,
  refetch,
  projectInit,
  hasParagonLoaded,
  authenticityToken,
  showAssetsPage
}: TabItems) => {
  const createTagsViaNewTask = project?.createTagsViaNewTask;
  const isAdmin = user?.admin;

  const items: GetItemArgs[] = [
    {
      label: strings.generalTitle,
      key: 'general',
      children: itemChild({
        title: strings.generalTitle,
        children: (
          <General
            project={project}
            refetch={refetch}
            showAssetsPage={showAssetsPage}
          />
        )
      })
    },
    {
      label: strings.integrationsTitle,
      key: 'integrations',
      children: itemChild({
        title: strings.integrationsTitle,
        children: (
          <IntegrationSettings
            {...integrations}
            hasParagonLoaded={!!hasParagonLoaded}
          />
        ),
        hasParagonLoaded
      })
    },
    {
      label: strings.guestPermissions,
      key: 'guest_permissions',
      children: itemChild({
        title: strings.guestPermissions,
        children: (
          <GuestPermissions
            project={project}
            guestsSeeGuestsAccess={guestsSeeGuestsAccess}
            guestsEditTaskStatusAllowed={guestsEditTaskStatusAllowed}
          />
        ),
        key: 'guest_permissions'
      })
    },
    {
      label: strings.installBugherd,
      key: 'install_bugherd',
      children: (
        <InstallBugherd
          project={project}
          accessTo={{
            guestsSeeGuestsAccess
          }}
          bugherdUrl={bugherdUrl}
          user={user}
          refetch={refetch}
          projectInit={projectInit}
        />
      )
    },
    {
      label: strings.securityTitle,
      key: 'security',
      children: itemChild({
        title: strings.securityTitle,
        children: (
          <Security
            project={project}
            accessTo={{
              mobileScreenshots
            }}
            refetch={refetch}
          />
        )
      })
    },
    {
      label: strings.sidebar,
      key: 'sidebar',
      children: itemChild({
        title: strings.sidebarSettingsTitle,
        children: <Sidebar project={project} />
      })
    },
    {
      label: strings.tags,
      key: 'tags',
      children: itemChild({
        title: strings.editTagsTitle,
        children: (
          <EditTags
            projectId={project?.id}
            createTagsViaNewTask={createTagsViaNewTask}
          />
        )
      })
    },
    {
      label: strings.commitHook,
      key: 'commit_hook',
      children: itemChild({
        title: strings.commitTitle,
        children: (
          <CommitHooks
            projectId={project?.id}
            bitBucketHook={bitBucketHook}
            gitHubHook={gitHubHook}
            gitLabHook={gitLabHook}
            organizationId={project?.organizationId}
          />
        )
      })
    },
    {
      label: strings.exportBugs,
      key: 'export_bugs',
      children: itemChild({
        title: strings.exportBugs,
        children: (
          <ExportBugs
            email={user?.email}
            authenticityToken={authenticityToken}
            projectId={project?.id}
            organizationId={project?.organizationId}
          />
        )
      })
    },
    {
      label: strings.disableDelete,
      key: 'disable_delete_project',
      children: itemChild({
        title: strings.disableTitle,
        children: <DisableProject project={projectInit} />
      })
    },
    {
      label: strings.advanced,
      key: 'advanced',
      children: itemChild({
        title: strings.advancedTitle,
        children: <Advanced project={project} refetch={refetch} />
      })
    }
  ];

  if (isAdmin) {
    items.push({
      label: (
        <a target="_blank" href={`/test/widget?apikey=${project?.apiKey}`}>
          Test project sidebar
        </a>
      ),
      key: 'test_project_sidebar'
    });
  }

  return items;
};
