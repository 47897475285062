// @ts-nocheck

import React, {
  FC,
  useEffect,
  useState,
  useRef,
  MutableRefObject
} from 'react';

import { Button } from 'antd';
import {
  Edit as EditIcon,
  CheckCircle,
  PlayCircle,
  Loader
} from 'lucide-react';

import * as translations from './strings';
import { getLangKey } from '../../../../../../javascript/models/Language';
import { Task } from '../types/task';

import { AccessTo, Severity, Overlay } from '../types';
import getThumbnail from '../getThumbnail';
import cx from 'classnames';
import animationStyles from '../overlay/animation.module.css';

import VideoPreview from '../video_preview';
import ScreenshotPreview from '../../../../../../javascript/components/ScreenshotPreview';
import AnnotateScreenshot from '../annotate_screenshot';

import styles from './index.module.css';
import ScreenshotsUpgrade from 'appClients/sidebar/components/screenshots/ScreenshotsUpgrade';

type Props = {
  task: Task;
  container: HTMLElement;
  accessTo: AccessTo;
  severity: Severity;
  setOverlay: (overlay: Overlay) => void;
  onAnnotationChange: (dataURI: Blob) => void;
  dataURI?: Blob | null;
  owner: {
    email?: string;
    id: number;
  };
  subscriptionUrl: string;
};

const strings = translations[getLangKey()];

const Preview: FC<Props> = props => {
  const {
    task,
    container,
    accessTo,
    severity,
    setOverlay,
    dataURI,
    owner,
    subscriptionUrl,
    onAnnotationChange
  } = props;
  const imgRef: MutableRefObject<null | HTMLDivElement> = useRef(null);
  const containerRef: MutableRefObject<null | HTMLDivElement> = useRef(null);
  const [showVideo, setShowVideo] = useState<boolean>(false);
  const [showAnnotation, setShowAnnotation] = useState<boolean>(false);

  const containerWidth: number = 324;

  useEffect(() => {
    if (task.recording && imgRef.current) {
      getThumbnail(task.recording, imgRef.current);
    }
  }, []);

  const handleWhy = (event: any, overlay: Overlay) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setOverlay(overlay);
  };

  const onSaveAnnotation = (blob: Blob) => {
    if (onAnnotationChange) {
      onAnnotationChange(blob);
    }
  };

  const toggleAnnotation = () => {
    setShowAnnotation(!showAnnotation);
  };

  const overlay = () => (
    <div className={styles.overlay}>
      <div className={styles.previewFooter}>
        <span className={styles.captured}>
          <CheckCircle className={styles.checkCircle} />
          {strings.screenshotCaptured}
        </span>
        <AnnotateScreenshot
          blob={dataURI}
          container={container}
          onComplete={onSaveAnnotation}
          toggleModal={toggleAnnotation}
          showModal={showAnnotation}
          cta={
            <Button
              icon={<EditIcon className={styles.editIcon} />}
              className={styles.previewButton}
              onClick={() => toggleAnnotation()}
            >
              {strings.annotate}
            </Button>
          }
        />
      </div>
    </div>
  );

  return !dataURI ? (
    <div className={styles.previewOuter}>
      {task.recording && (
        <div className={styles.recordingPreview}>
          <div ref={imgRef} className={styles.imgContainer} />
          <div className={styles.overlay}>
            <div className={styles.previewFooter}>
              <span className={styles.captured}>
                <CheckCircle className={styles.checkCircle} />
                {strings.videoCaptured}
              </span>
              <Button
                icon={<PlayCircle className={styles.playIcon} />}
                className={styles.previewButton}
                onClick={() => setShowVideo(true)}
              >
                {strings.reviewVideo}
              </Button>
            </div>
          </div>
          <VideoPreview
            showVideo={showVideo}
            videoBlob={task.recording}
            container={container}
            onClose={() => setShowVideo(false)}
          />
        </div>
      )}
      {!accessTo.mobileScreenshots && !task.recording && (
        <ScreenshotsUpgrade
          hasBillingPermission={accessTo.billing}
          subscriptionUrl={subscriptionUrl}
          owner={owner}
          container={container}
          onShowOverlay={(visible: boolean) =>
            handleWhy(null, visible ? 'extensionlessUpgrade' : null)
          }
        />
      )}
    </div>
  ) : (
    <ScreenshotPreview
      {...{
        container,
        coordinates: task.data.coordinates,
        priorityId: severity,
        isCroppedView: true,
        screenshotKey: task.screenshot_key,
        createTask: overlay,
        containerWidth,
        containerHeight: 90,
        containerRef,
        dataURI,
        onShow: toggleAnnotation,
        showAnnotation
      }}
    />
  );
};

export default Preview;
