import React, { FC, useState } from 'react';
import styles from './styles.module.css';
import { AssetCard } from '../../../../clients/design_assets/AssetCard';
import { ShareModal } from '../../../../clients/design_assets/ShareModal';
import NavBar from '../NavBar';
import { DesignAsset } from '../../../../clients/design_assets/types';
import camelcaseKeys from 'camelcase-keys';
import useMediaQuery from '../../../../../clients/sidebar/views/CreateTask/create_task/useMediaQuery';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { Button, message } from 'antd';
import { Share } from 'lucide-react';
import { GroupNameModal } from '../../../../clients/design_assets/GroupNameModal';
import { put } from 'appJS/utils/fetch';
import usePusherSubscribe from 'appJS/hooks/usePusherSubscribe';
import { Event, Subscription } from 'appJS/models/Pusher';
import AssetBanner from '../AssetBanner';
import { handleSharingClick } from '../../../../clients/design_assets/handleSharingClick';
import { AntdWrapper } from '../../../../clients/shared/AntdWrapper';
import { Props as SharingProps } from '../../share_project/types';
import { ShareProject } from '../../share_project';
import { useOrganizationState } from '../../../../clients/providers/Organization';

type Props = {
  assets: DesignAsset[];
  viewKanbanProject: boolean;
  project: any;
  currentGroupName: string;
  bugherdUrl: string;
  groups: string[];
  pusherApiKey: string;
  pusherChannelAuthEndpoint: string;
  loggedIn: boolean;
  hasAccess: boolean;
  projectOwner: {
    email: string;
    name: string;
    id: number;
  } | null;
  sharingProps: SharingProps;
  viewGuestKanban?: boolean;
};

const AssetGroup: FC<Props> = ({
  assets,
  viewKanbanProject,
  project,
  currentGroupName,
  bugherdUrl,
  groups,
  pusherApiKey,
  pusherChannelAuthEndpoint,
  loggedIn,
  projectOwner,
  hasAccess,
  sharingProps,
  viewGuestKanban
}) => {
  const [showShareModal, setShowShareModal] = useState<number | string | false>(
    false
  );
  const [groupName, setGroupName] = useState<string>('');
  const [isGroupNameModalOpen, setIsGroupNameModalOpen] = useState<
    number | false
  >(false);
  const [data, setData] = useState<DesignAsset[]>(
    camelcaseKeys(assets, { deep: true })
  );
  project = camelcaseKeys(project, { deep: true });
  const container = document.getElementById(
    'asset_group_container'
  ) as HTMLDivElement;
  const { id, pusherChannelName } = project;

  type PusherEventHandlerParams = {
    assetsClone: DesignAsset[];
    matchingGroup: boolean;
    pusherAsset: DesignAsset;
    existingAsset?: DesignAsset;
  };

  const handlePusherUpdate = (
    { asset },
    pusherEventHandler: ({
      assetsClone,
      matchingGroup,
      pusherAsset,
      existingAsset
    }: PusherEventHandlerParams) => DesignAsset[]
  ) => {
    const { id, group_name } = asset;

    const matchingGroup = group_name === currentGroupName;
    const existingAsset = data.find(_asset => _asset.id === id);
    const pusherAsset = camelcaseKeys(asset, { deep: true });

    setData(previousData => {
      const assetsClone = previousData.slice();
      return pusherEventHandler({
        assetsClone,
        matchingGroup,
        pusherAsset,
        existingAsset
      });
    });
  };

  const handleCreate = ({
    assetsClone,
    matchingGroup,
    pusherAsset,
    existingAsset
  }: PusherEventHandlerParams) =>
    !existingAsset && matchingGroup
      ? [...assetsClone, pusherAsset]
      : assetsClone;

  const handleUpdate = ({
    assetsClone,
    matchingGroup,
    pusherAsset,
    existingAsset
  }: PusherEventHandlerParams) => {
    if (existingAsset) {
      if (matchingGroup) {
        Object.assign(existingAsset, pusherAsset);
      } else {
        return assetsClone.filter(({ id }) => id !== existingAsset.id);
      }
    } else if (matchingGroup) return [...assetsClone, pusherAsset];

    return assetsClone;
  };

  const handleDelete = ({
    assetsClone,
    matchingGroup,
    existingAsset
  }: PusherEventHandlerParams) =>
    matchingGroup && existingAsset
      ? assetsClone.filter(({ id }) => id !== existingAsset.id)
      : assetsClone;

  const events = [
    {
      name: Event.ASSET_CREATE,
      onUpdate: pusherData => handlePusherUpdate(pusherData, handleCreate)
    },
    {
      name: Event.ASSET_UPDATE,
      onUpdate: pusherData => handlePusherUpdate(pusherData, handleUpdate)
    },
    {
      name: Event.ASSET_DELETE,
      onUpdate: pusherData => handlePusherUpdate(pusherData, handleDelete)
    }
  ];

  usePusherSubscribe({
    pusher: {
      config: {
        pusherApiKey,
        pusherChannelAuthEndpoint
      },
      projects: [{ id, pusherChannelName }],
      events,
      subscription: Subscription.ASSET_GROUP
    },
    loading: false
  });

  const buttonSize = useMediaQuery('(max-width: 560px)')
    ? 'small'
    : ('default' as SizeType);

  const onAddToGroup = async (groupName: string) => {
    if (groupName) {
      const response = await put(
        `${bugherdUrl}/projects/${project.id}/assets/${isGroupNameModalOpen}`,
        {
          group_name: groupName
        }
      );
      if (response.ok) {
        message.success(`Asset has been added to group ${groupName}`);
        setGroupName('');
      } else {
        message.error('Something went wrong, please try again');
      }
    }
  };

  const assetParameter =
    typeof showShareModal === 'number'
      ? showShareModal
      : `show_group?group_name=${encodeURI(currentGroupName)}`;

  const activeAssets = data.filter(asset => asset.status == 'created');
  const { hasOrganizationExperiment } = useOrganizationState();
  const isNoLoginGuestExperienceEnabled = hasOrganizationExperiment(
    'no_login_guest_experience'
  );

  const onSharingClick = ({
    assetId,
    assetGroupName
  }: {
    assetId?: number | string;
    assetGroupName?: string;
  }) => {
    handleSharingClick({
      isNoLoginGuestExperienceEnabled,
      assetId,
      assetGroupName,
      callback: setShowShareModal
    });
  };

  return (
    <div className={styles.container}>
      <ShareModal
        open={!!showShareModal}
        closeModal={() => setShowShareModal(false)}
        shareURL={`${bugherdUrl}/projects/${project.id}/assets/${assetParameter}`}
        canInviteToProject={viewKanbanProject}
        container={container}
        inviteEndpoint={`/projects/${project.id}/kanban?invite_team=true`}
      />
      <ShareProject
        {...sharingProps}
        isNoLoginGuestExperienceEnabled={isNoLoginGuestExperienceEnabled}
        container={container}
      />
      <GroupNameModal
        onAddToGroup={onAddToGroup}
        setIsGroupNameModalOpen={setIsGroupNameModalOpen}
        setGroupName={setGroupName}
        isGroupNameModalOpen={isGroupNameModalOpen}
        container={container}
        groupName={groupName}
      />
      {(!loggedIn || !hasAccess) && (
        <AssetBanner
          assetIdOrGroup={encodeURI(currentGroupName)}
          container={container}
          projectId={project.id}
          loggedIn={loggedIn}
          owner={projectOwner}
        />
      )}
      <div className={styles.pageHeader}>
        <NavBar
          project={project}
          fileName={currentGroupName}
          viewKanbanProject={viewKanbanProject}
          loggedIn={loggedIn}
          hasAccess={hasAccess}
        />
        <Button
          size={buttonSize}
          title="Share this asset group"
          className={styles.shareButton}
          icon={<Share className={styles.shareIcon} />}
          onClick={() => onSharingClick({ assetGroupName: currentGroupName })}
        >
          Share
        </Button>
      </div>
      <div className={styles.assetsContainer}>
        {activeAssets.map(asset => (
          <AssetCard
            asset={camelcaseKeys(asset, { deep: true })}
            key={asset.id}
            container={container}
            canManageAssets={viewKanbanProject}
            disabled={asset.status === 'archived'}
            setData={setData}
            bugherdUrl={bugherdUrl}
            hasManagementFeaturesExperiment
            handleSharingClick={() => onSharingClick({ assetId: asset.id })}
            onAddToGroup={onAddToGroup}
            openNewGroupModal={() => setIsGroupNameModalOpen(asset.id)}
            existingGroups={groups}
            viewGuestKanban={viewGuestKanban}
          />
        ))}
      </div>
    </div>
  );
};

export default (props: Props) => (
  <AntdWrapper>
    <AssetGroup {...props} />
  </AntdWrapper>
);
