import React, { ChangeEventHandler, FC } from 'react';
import { Input, Space } from 'antd';
import * as Types from '../types';
import styles from './index.module.css';

type Props = {
  show: boolean;
  onNameChange: (name: string) => void;
  onEmailChange: (email: string) => void;
  nameStatus: 'warning' | 'error' | undefined;
  emailStatus: 'warning' | 'error' | undefined;
  user: Types.User;
};

export const LoginlessTaskInfo: FC<Props> = ({
  show,
  emailStatus,
  nameStatus,
  onNameChange,
  onEmailChange,
  user: { firstName, email }
}) => {
  if (!show) return null;

  const handleNameInput: ChangeEventHandler<HTMLInputElement> = event => {
    onNameChange(event.target.value);
  };
  const handleEmailInput: ChangeEventHandler<HTMLInputElement> = event => {
    onEmailChange(event.target.value);
  };

  const emailProps: {
    disabled?: boolean;
    value?: string;
  } = {};

  if (!firstName) {
    emailProps.disabled = true;
    emailProps.value = email;
  }

  return (
    <div className={styles.loginlessInputContainer}>
      <p>Add your info to let the team know who’s giving feedback.</p>
      <Space direction="vertical">
        <Input
          key={'name'}
          placeholder={`First name ${firstName ? '(required)' : ''}`}
          onChange={handleNameInput}
          status={nameStatus}
        />
        <Input
          key={'email'}
          placeholder="Email (required)"
          onChange={handleEmailInput}
          status={emailStatus}
          {...emailProps}
        />
      </Space>
    </div>
  );
};
