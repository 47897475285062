import React, { FC, useEffect, useState } from 'react';
import styles from './styles.module.css';
import { Document, Page } from 'appJS/utils/pdfjs_worker';
import cx from 'classnames';
import camelcaseKeys from 'camelcase-keys';
import { BrowserRouter, Route, useNavigate, Routes } from 'react-router-dom';
import NavBar from './NavBar';
import useDesignFeedback from './useDesignFeedback';
import Controls from './Controls';
import useMediaQuery from '../../../../clients/sidebar/views/CreateTask/create_task/useMediaQuery';
import AssetBanner from './AssetBanner';
import { ShareModal } from '../../../clients/design_assets/ShareModal';
import { ArchivedBanner } from 'jsViews/design_feedback/ArchivedBanner/ArchivedBanner';
import { ShareProject } from '../share_project';
import { Props as SharingProps } from '../share_project/types';
import { AntdWrapper } from '../../../clients/shared/AntdWrapper';
import { Spin } from 'antd';
import { OrganizationProvider } from 'appClients/providers/Organization';

type Props = {
  asset: any;
  project: any;
  viewKanbanProject: boolean;
  bugherdUrl: string;
  page?: string;
  loggedIn: boolean;
  projectOwner: {
    name: string;
    email: string;
    id: number;
  } | null;
  hasAccess: boolean;
  isGuest: boolean;
  sharingProps: SharingProps;
};

const DesignFeedback: FC<Props> = ({
  asset,
  project,
  viewKanbanProject,
  bugherdUrl,
  page,
  loggedIn,
  projectOwner,
  hasAccess,
  isGuest,
  sharingProps
}) => {
  const navigate = useNavigate();
  const isMobileView = useMediaQuery('(max-width: 470px)');
  const container = document.getElementById(
    'feedback_router_container'
  ) as HTMLDivElement;
  const [hasMounted, setHasMounted] = useState<boolean>(false);

  project = camelcaseKeys(project, { deep: true });

  const {
    imageRef,
    canvasRef,
    sidebarClosed,
    useOriginalSize,
    onUseOriginalSize,
    currentPage,
    setCurrentPage,
    totalPages,
    fileName,
    isPdf,
    url,
    handleZoomIn,
    handleZoomOut,
    handleLoadSuccess,
    fitToPage,
    onFitToPage,
    pdfScale,
    isOverflowing,
    inlineImageWidth,
    onImageLoad,
    showShareModal,
    setShowShareModal,
    onSharingClick,
    naturalWidth,
    isNoLoginGuestExperienceEnabled
  } = useDesignFeedback({
    page,
    project,
    asset,
    navigate,
    bugherdUrl,
    isMobileView
  });

  useEffect(() => {
    if (naturalWidth && !hasMounted) setTimeout(() => setHasMounted(true), 400);
  }, [naturalWidth]);

  const isArchived = asset.status === 'archived';
  return (
    <div
      className={cx(styles.designFeedbackContainer, 'designFeedbackContainer', {
        isPdf: isPdf,
        isNotPdf: !isPdf,
        sidebarClosed: sidebarClosed,
        sidebarOpen: !sidebarClosed,
        noSidebar: !loggedIn || !hasAccess
      })}
    >
      <ShareProject
        {...sharingProps}
        isNoLoginGuestExperienceEnabled={isNoLoginGuestExperienceEnabled}
        container={container}
      />
      <ShareModal
        open={showShareModal}
        closeModal={() => setShowShareModal(false)}
        shareURL={`${bugherdUrl}/projects/${project.id}/assets/${asset.id}`}
        canInviteToProject={viewKanbanProject}
        container={container}
        inviteEndpoint={`/projects/${project.id}/kanban?open=invite_team`}
      />
      <div
        className={cx(styles.pageHeader, { [styles.fullHead]: sidebarClosed })}
      >
        <NavBar
          project={project}
          fileName={fileName}
          viewKanbanProject={viewKanbanProject}
          loggedIn={loggedIn}
          hasAccess={hasAccess}
        />
        <Controls
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          container={container}
          useOriginalSize={useOriginalSize}
          onUseOriginalSize={onUseOriginalSize}
          isPdf={isPdf}
          handleZoomIn={handleZoomIn}
          handleZoomOut={handleZoomOut}
          onShareClick={onSharingClick}
          fitToPage={fitToPage}
          onFitToPage={onFitToPage}
        />
      </div>
      <div
        className={cx(styles.assetContainer, {
          [styles.overflowing]: isOverflowing
        })}
      >
        {isPdf ? (
          <Document file={url} onLoadSuccess={handleLoadSuccess} loading={null}>
            <Page
              canvasRef={canvasRef}
              pageNumber={currentPage}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              scale={pdfScale}
            />
          </Document>
        ) : (
          <img
            onLoad={onImageLoad}
            ref={imageRef}
            style={inlineImageWidth}
            className={cx(styles.asset, { [styles.unmounted]: !hasMounted })}
            src={url}
            alt="design_asset_image"
          />
        )}
        {!hasMounted && (
          <Spin
            wrapperClassName={styles.loaderWrapper}
            size="large"
            className={styles.loader}
          >
            <h2>Loading your file...</h2>
            <p>Hold on tight.</p>
          </Spin>
        )}
      </div>
      {!hasAccess && (
        <AssetBanner
          owner={projectOwner}
          assetIdOrGroup={asset.id}
          container={container}
          projectId={project.id}
          loggedIn={loggedIn}
          toProjects
        />
      )}
      {hasAccess && isArchived && (
        <ArchivedBanner
          container={container}
          projectId={project.id}
          isGuest={isGuest}
        />
      )}
    </div>
  );
};

const FeedbackRouter = props => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={`/projects/${props.project.id}/assets/${props.asset.id}`}
          element={
            <AntdWrapper>
              <OrganizationProvider id={props.organization.id}>
                <DesignFeedback {...props} />
              </OrganizationProvider>
            </AntdWrapper>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default FeedbackRouter;
