import React, { FC } from 'react';
import styles from './index.module.css';
import { Modal, Tabs, Typography, Button } from 'antd';
import { ExternalLink } from 'lucide-react';
import { detect } from 'detect-browser';
import { getLangKey } from 'appJS/models/Language';
import GetBrowserExtension from '../../../assets/images/browser_logos/get-browser-extension.svg';
import ChromeLogo from '../../../assets/images/browser_logos/chrome.svg';
import EdgeLogo from '../../../assets/images/browser_logos/edge.svg';
import FirefoxLogo from '../../../assets/images/browser_logos/firefox.svg';
import SafariLogo from '../../../assets/images/browser_logos/safari.svg';
import WordPressIcon from '../../../assets/images/icon-wordpress.svg';
import JavascriptIcon from '../../../assets/images/icon-javascript.svg';
import EmbedCode from '../../javascript/components/embed_code';
import WordPressTab from '../../javascript/views/install_bugherd/wordpress_tab/index';
import * as translations from './strings';
import InstallBugherdExtension from './assets/install_bugherd_extension.svg';

const strings = translations[getLangKey()];

type Props = {
  container: HTMLDivElement | undefined;
  open: boolean;
  closeInstallModal: () => void;
  apiKey: string;
  widgetCode: string;
  url: string;
  showGuestExtensionModal?: boolean;
};

const detectedBrowser = detect();
const browser = detectedBrowser?.name || 'unknown';

const InstallModal: FC<Props> = ({
  container,
  open,
  closeInstallModal,
  apiKey,
  widgetCode,
  url,
  showGuestExtensionModal
}) => {
  const CHROME_BROWSER_NAME = 'chrome';
  const FIREFOX_BROWSER_NAME = 'firefox';
  const EDGE_BROWSER_NAME = 'edge-chromium';
  const SAFARI_BROWSER_NAME = 'safari';

  const browserOptions = [
    {
      id: 'chrome-option',
      href:
        'https://chrome.google.com/webstore/detail/bugherd-plus/popigpemobhbfkhnnkllkjgkaabedgpb',
      logo: ChromeLogo,
      name: CHROME_BROWSER_NAME
    },
    {
      id: 'firefox-option',
      href: 'https://addons.mozilla.org/en-US/firefox/addon/bugherd/',
      logo: FirefoxLogo,
      name: FIREFOX_BROWSER_NAME
    },
    {
      id: 'edge-option',
      href:
        'https://microsoftedge.microsoft.com/addons/detail/nkdboghnnmoenjjmcakndhjdecakkfpn',
      logo: EdgeLogo,
      name: EDGE_BROWSER_NAME
    },
    {
      id: 'safari-option',
      href:
        'https://apps.apple.com/us/app/bugherd-website-feedback-tool/id1545845981',
      logo: SafariLogo,
      name: SAFARI_BROWSER_NAME
    }
  ];

  const width = window.innerWidth * 0.8; // 80% of window's width
  const height = window.innerHeight * 0.8; // 80% of window's height
  const midLeft = window.screenX + window.outerWidth / 2 - width / 2;
  const midTop = window.screenY + window.outerHeight / 2 - height / 2;

  const openExtensionPage = option => {
    window.open(
      option.href,
      'newwindow',
      `width=${width},height=${height},left=${midLeft},top=${midTop}`
    );
    window.focus();
  };

  const currentBrowserOption = browserOptions.find(
    option => option.name === browser
  );
  const Logo = currentBrowserOption?.logo;

  const items: TabsProps['items'] = [
    {
      key: 'extension',
      label: (
        <>
          <Logo
            className={styles.extensionLogos}
            alt={currentBrowserOption?.name}
          />
          <Typography.Title level={3}>
            {strings.getTheExtension}
          </Typography.Title>
        </>
      ),
      children: (
        <div className={styles.extensionContentWrapper}>
          <div>
            <Typography.Title level={4}>
              {strings.extensionInfo}
            </Typography.Title>
            <Button
              icon={
                <Logo
                  className={styles.buttonIcon}
                  alt={currentBrowserOption?.name}
                />
              }
              type="primary"
              onClick={() => {
                openExtensionPage(currentBrowserOption);
              }}
            >
              {strings.getExtension}
            </Button>
          </div>
          <div className={styles.extensionImageWrapper}>
            <GetBrowserExtension />
          </div>
        </div>
      )
    },
    {
      key: 'javascript',
      label: (
        <>
          <JavascriptIcon />
          <Typography.Title level={3}>
            {strings.javascriptInstall}
          </Typography.Title>
        </>
      ),
      children: (
        <div>
          <p>
            <Typography.Title level={3}>
              {strings.installJavascript}
            </Typography.Title>
            {strings.addThisCode}
            <code className={styles.head}>{strings.head}</code>
            {strings.addThisCode2}
            <div className={styles.embedWrapper}>
              <EmbedCode widgetCode={widgetCode} />
            </div>
          </p>
          <Button
            icon={<ExternalLink className={styles.websiteIcon} />}
            type="primary"
            onClick={() => window.open(url, '_blank')}
          >
            {strings.openWebsite}
          </Button>
        </div>
      )
    },
    {
      key: 'wordpress',
      label: (
        <>
          <WordPressIcon />
          <Typography.Title level={3}>
            {strings.wordpressInstall}
          </Typography.Title>
        </>
      ),
      children: (
        <>
          <Typography.Title level={3}>
            {strings.installWordPress}
          </Typography.Title>
          <WordPressTab apiKey={apiKey} />
          <Button
            icon={<ExternalLink className={styles.websiteIcon} />}
            type="primary"
            onClick={() => window.open(url, '_blank')}
          >
            {strings.openWebsite}
          </Button>
        </>
      )
    }
  ];

  const contentGuestInstallExtension = (
    <div className={styles.guestExtensionContentWrapper}>
      <InstallBugherdExtension />
      <Typography.Title level={2}>
        {strings.beforeFeedbackTitle}
      </Typography.Title>
      <p>{strings.beforeFeedbackDescription}</p>
      <Button
        className={styles.guestInstallButton}
        icon={
          <Logo
            className={styles.buttonIcon}
            alt={currentBrowserOption?.name}
          />
        }
        type="primary"
        onClick={() => {
          openExtensionPage(currentBrowserOption);
        }}
      >
        {strings.getExtension}
      </Button>
    </div>
  );

  const content = (
    <>
      <Typography.Title level={2}>{strings.howToSetupBugHerd}</Typography.Title>
      <p>{strings.chooseHowToSetup}</p>
      <Tabs
        defaultActiveKey="extension"
        items={items}
        className={styles.installModalTabs}
      />
    </>
  );

  return (
    <>
      <Modal
        wrapClassName="antd-container"
        className={showGuestExtensionModal ? '' : styles.installModal}
        open={open}
        getContainer={container || false}
        closable
        onCancel={() => {
          closeInstallModal();
          window.location.reload();
        }}
        title={null}
        footer={null}
        centered
        destroyOnClose
      >
        {showGuestExtensionModal ? contentGuestInstallExtension : content}
      </Modal>
    </>
  );
};

export default InstallModal;
