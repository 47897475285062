import React, { FC, useState } from 'react';
import styles from './styles.module.css';
import { ExternalLink, Menu as MenuIcon, X as Close } from 'lucide-react';
import { Badge, Button, Menu, MenuProps, Space } from 'antd';
import navItems from '../items';
import { View, NavItem } from '../types';
import { ActiveKey } from '../../../new_project_settings/types';
import cx from 'classnames';
import * as translations from '../../strings';
import { getLangKey } from 'appJS/models/Language';
import { useDesignAssetsState } from '../../../../../clients/providers/DesignAssets';
import {
  GUEST_NAV_ITEMS,
  MEMBER_NAV_ITEMS
} from 'jsViews/project_board/project_nav';

const strings = translations[getLangKey()];
const usersKeys = [View.Members, View.Guests, View.Collaborators];

const memberNavItems = [];

type Props = {
  view: View | null;
  setView: (view: View | null) => void;
  setActiveKey: (activeKey: ActiveKey) => void;
  triageCount: number;
  kanbanCount: number;
  gettingStartedCount: string;
  projectSites: { id: number; url: string }[];
  devurl: string;
  isReviewApp?: boolean;
  isAdminUser?: boolean;
  apiKey: string;
  hasNewOnboarding: boolean;
  canAccess: {
    viewKanbanProject: boolean;
  };
  projectId: number;
  organizationId: number;
  showAssetsPage?: () => void;
};

export const MobileNav: FC<Props> = ({
  view,
  triageCount,
  kanbanCount,
  gettingStartedCount,
  setView,
  setActiveKey,
  projectSites,
  devurl,
  isReviewApp,
  isAdminUser,
  apiKey,
  hasNewOnboarding,
  canAccess,
  projectId,
  organizationId,
  showAssetsPage
}) => {
  const [navOpen, setNavOpen] = useState<boolean>(false);
  const { setShowAssets } = useDesignAssetsState();

  const getItem = ({ label, icon, count, key }: NavItem) => ({
    label: (
      <Space>
        <a href={window.location.pathname.replace(/\/[^\/]*$/, `${key}`)}>
          {label}
        </a>
        {!!count && (
          <Badge
            count={count}
            showZero
            color={view === key ? '#d9e7F4' : '#d46060'}
            className={styles.count}
            overflowCount={999}
          />
        )}
      </Space>
    ),
    icon,
    key,
    className: cx(styles.item, { [styles.active]: view === key })
  });

  const items = navItems({
    triageCount,
    kanbanCount,
    gettingStartedCount,
    setView,
    hasNewOnboarding
  })
    .filter(({ key }) => {
      if (!key) {
        return false;
      }
      const hideGettingStarted = !hasNewOnboarding;
      if (key === View.GettingStarted && hideGettingStarted) {
        return false;
      }
      return true;
    })
    .filter(({ key }) => {
      if (canAccess.viewKanbanProject) {
        return MEMBER_NAV_ITEMS.includes(key);
      } else {
        return GUEST_NAV_ITEMS.includes(key);
      }
    });

  const dividerInsertIndex =
    items.findIndex(({ withDivider }) => withDivider?.mobile) + 1;

  // @ts-expect-error
  const menuItems: MenuProps['items'] = items.map(getItem);

  const getCurrentLabel = (): string | undefined =>
    items?.find(({ key }) => key === view)?.label;

  if (menuItems) {
    menuItems.splice(dividerInsertIndex, 0, {
      type: 'divider',
      key: 'divider-1',
      className: styles.divider
    });

    const sitesItem = {
      label: <a href={devurl}>{strings.openWebsite}</a>,
      icon: <ExternalLink className={styles.websiteIcon} />,
      key: View.Website,
      className: styles.sitesSubMenu,
      children: projectSites.length
        ? projectSites.map(({ id, url }) => ({
            label: (
              <a href={url} className={styles.projectSiteLabel}>
                {url}
              </a>
            ),
            key: `site-${id}`
          }))
        : undefined
    };

    if (isReviewApp || isAdminUser) {
      const testSite = {
        label: (
          <a href={`/test/widget?apikey=${apiKey}`} target="_blank">
            test project sidebar
          </a>
        ),
        key: 'test'
      };

      (sitesItem.children?.length && sitesItem.children.push(testSite)) ||
        (sitesItem.children = [testSite]);
    }

    menuItems.push(sitesItem);

    menuItems.unshift(
      {
        label: getCurrentLabel(),
        icon: (
          <Button
            className={styles.closeMenu}
            onClick={() => setNavOpen(false)}
            icon={<Close className={styles.closeIcon} />}
          />
        ),
        key: 'header',
        className: styles.headerItem
      },
      {
        type: 'divider',
        key: 'divider-2',
        className: cx(styles.divider, styles.headerDivider)
      }
    );
  }

  return (
    <div className={styles.mobileNavOuter}>
      <h2>{getCurrentLabel()}</h2>
      <Button
        type="default"
        onClick={() => setNavOpen(true)}
        className={styles.mobileNavButton}
        icon={<MenuIcon className={styles.menuIcon} />}
      />
      <div
        className={cx(styles.mobileNav, {
          [styles.open]: navOpen,
          [styles.closed]: !navOpen
        })}
      >
        {navOpen && (
          <Menu
            mode="inline"
            defaultOpenKeys={[View.Website]}
            selectable={false}
            items={menuItems}
            forceSubMenuRender
            className={styles.menu}
            onClick={({ key, domEvent }) => {
              if (!key.includes('site-')) {
                domEvent.preventDefault();
                domEvent.stopPropagation();
                if (key !== 'header' && !key.includes('divider')) {
                  if (key === View.Assets || View.Home) {
                    if (showAssetsPage) {
                      showAssetsPage();
                    } else {
                      setShowAssets(true);
                    }
                  }
                  if (usersKeys.includes(key)) {
                    window.share_project_props?.setRole?.(key.slice(0, -1));
                    window.share_project_props?.setShow?.(true);
                    return;
                  }
                  if (key === View.Settings) {
                    setActiveKey(ActiveKey.General);
                    setNavOpen(false);
                  }
                  if (key === View.Help) {
                    //@ts-expect-error
                    window._elev?.openHome();
                    return;
                  }
                  setView(key as View | null);
                  setNavOpen(false);
                }
              }
            }}
          />
        )}
      </div>
    </div>
  );
};
