import React, { useState, useEffect } from 'react';
import styles from './microsoftTeamsSettings.module.css';
import { get, post } from '../../utils/fetch';
import { Form, Row, Col, Button, Select, Tooltip, Checkbox } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { message } from 'antd';

interface Props {
  projectId: string;
  organizationId: string;
  integration:
    | {
        enabled: boolean;
        id: string;
        settings: {
          team: Object;
          channel: Object;
          new_tasks: boolean;
          status_updates: boolean;
        };
      }
    | undefined;
}

const MicrosoftTeamsSettings: React.FC<Props> = (props: Props) => {
  const [form] = Form.useForm();

  const { integration, organizationId, projectId } = props;
  const [projectIntegrationId, setProjectIntegrationId] = useState('');
  const [selectedTeam, setSelectedTeam] = useState(integration?.settings?.team);
  const [selectedChannel, setSelectedChannel] = useState(
    integration?.settings?.channel
  );
  const [autoPost, setAutoPost] = useState(
    integration?.settings?.new_tasks.toString() === 'true'
  );
  const [syncStatus, setSyncStatus] = useState(
    integration?.settings?.status_updates.toString() === 'true'
  );
  const [teamOptions, setTeamOptions] = useState([]);
  const [channelOptions, setChannelOptions] = useState([]);

  const getChannelOptions = async (team: string) => {
    if (!integration || !integration?.enabled) return;
    try {
      let results = await get(
        `/organizations/${organizationId}/integrations/teams_channels?id=${integration.id}&team_id=${team}`
      );
      setChannelOptions(results);
    } catch (error) {
      message.error('Something went wrong, please try again.');
    }
  };

  const handleTeamSelect = (value: string, label: Object) => {
    setSelectedTeam(label);
    getChannelOptions(value);
  };

  const handleChannelSelect = (value: string, label: Object) => {
    setSelectedChannel(label);
  };

  const getConnectedState = async () => {
    if (!integration || !integration?.enabled) return;
    try {
      let results = await get(
        `/organizations/${organizationId}/integrations/integration_connection?project_id=${projectId}`
      );
      setProjectIntegrationId(results.project_integration_id);
      setSelectedTeam(results.settings.team);
      setSelectedChannel(results.settings.channel);
      setAutoPost(results.settings.new_tasks.toString() === 'true');
      setSyncStatus(results.settings.status_updates.toString() === 'true');
    } catch (error) {
      message.error('Something went wrong, please try again.');
    }
  };

  const getTeamOptions = async () => {
    if (!integration || !integration?.enabled) return;
    try {
      let results = await get(
        `/organizations/${organizationId}/integrations/teams_teams?id=${integration.id}`
      );
      setTeamOptions(results);
    } catch (error) {
      message.error('Something went wrong, please try again.');
    }
  };

  useEffect(() => {
    getConnectedState();
    getTeamOptions();
    if (selectedTeam) {
      getChannelOptions(selectedTeam.value);
    }
  }, []);

  const saveSettings = async () => {
    try {
      await post(
        `/organizations/${organizationId}/integrations/${integration.id}/project_integrations/save_settings`,
        {
          project_id: projectId,
          project_integration_id: projectIntegrationId,
          settings: {
            team: selectedTeam,
            channel: selectedChannel,
            new_tasks: autoPost,
            status_updates: syncStatus
          }
        }
      );
      message.success('Settings saved', 5);
    } catch (error) {
      console.error('Error saving settings', error);
      message.error('Something went wrong, please try again.');
    }
  };

  const renderConnectButton = () => {
    return (
      <div className={styles.teamsExplanation}>
        <p>
          Connect BugHerd with your Microsoft Teams account to sync your tasks
          and comments. For example:
        </p>
        <ul>
          <li>Post tasks to your Microsoft Teams channel</li>
          <li>Sync new status updates to your Microsoft Teams channel</li>
        </ul>
        <a
          className="btn ant-btn-primary"
          rel="nofollow"
          data-method="post"
          href={`/auth/microsoft_graph?project=${projectId}&organization=${organizationId}&type=Team`}
        >
          <span className={styles.buttonText}>Connect</span>
        </a>
      </div>
    );
  };

  const renderIntegrationSettings = () => {
    return (
      <div>
        <p className={styles.teamsChannelHeader}>
          Microsoft Teams Channel
          <span className={styles.infoContainer}>
            <Tooltip title="Choose the team and channel you want to sync with BugHerd">
              <InfoCircleOutlined style={{ color: '#3F71E0' }} />
            </Tooltip>
          </span>
        </p>
        <Form form={form} onFinish={saveSettings}>
          <Row className={styles.teamsEditRow}>
            <Col span={10}>
              <Form.Item
                name="teams"
                rules={[{ required: true }]}
                initialValue={selectedTeam}
              >
                <Select options={teamOptions} onSelect={handleTeamSelect} />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                name="channel"
                rules={[{ required: true }]}
                initialValue={selectedChannel}
              >
                <Select
                  options={channelOptions}
                  onSelect={handleChannelSelect}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className={styles.teamsEditRow}>
            <Form.Item>
              <Checkbox
                checked={autoPost}
                onChange={e => setAutoPost(e.target.checked)}
              >
                Automatically post new tasks to Microsoft Teams Channel
              </Checkbox>
            </Form.Item>
          </Row>
          <Row className={styles.teamsEditRow}>
            <Form.Item>
              <Checkbox
                checked={syncStatus}
                onChange={e => setSyncStatus(e.target.checked)}
              >
                Sync status updates
              </Checkbox>
            </Form.Item>
          </Row>
          <Row className={styles.teamsEditRow}>
            <Col span={6}>
              <Form.Item>
                <Button
                  type="primary"
                  shape="round"
                  size="default"
                  htmlType="submit"
                  className={styles.save}
                >
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {integration?.enabled
        ? renderIntegrationSettings()
        : renderConnectButton()}
    </div>
  );
};

export default (props: Props) => <MicrosoftTeamsSettings {...props} />;
