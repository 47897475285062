import React, { FC, useState } from 'react';
import { Modal, Button } from 'antd';
import { useGuestProjectState } from '../GuestProjectProvider';
import styles from './styles.module.css';
import { WelcomeContent } from './WelcomeContent';
import { put } from 'appJS/utils/fetch';

export const WelcomeModal: FC = () => {
  const { container, data, viewedGuestProjectBoard } = useGuestProjectState();

  const { name } = data?.currentUser || {};
  const [showWelcomeModal, setShowWelcomeModal] = useState<boolean>(
    !viewedGuestProjectBoard || !name
  );

  const onContinue = () => {
    setShowWelcomeModal(false);
    put(`/users/${data?.currentUser?.id}`, {
      viewed_guest_project_board: true
    });
  };

  return (
    <Modal
      open={showWelcomeModal}
      onCancel={onContinue}
      wrapClassName={styles.welcomeModal}
      closable={false}
      getContainer={container}
      footer={null}
      centered={!!name}
      maskClosable={!!name}
      width={!name ? 485 : 740}
      destroyOnClose
    >
      {!name ? (
        <WelcomeContent />
      ) : (
        <div className={styles.introVideoContainer}>
          <h2 className={styles.introVideoTitle}>
            👋 Welcome to BugHerd, {name}
          </h2>
          <p className={styles.introVideoText}>
            Watch this 1 minute video to see how BugHerd works.
          </p>
          <iframe
            className={styles.introVideo}
            src="https://player.vimeo.com/video/998510460?h=6b68ca4cd8&title=0&byline=0&portrait=0"
            width="640"
            height="360"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          />
          <Button type="primary" onClick={onContinue}>
            Continue
          </Button>
        </div>
      )}
    </Modal>
  );
};
