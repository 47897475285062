export default (blob: Blob, imgContainer: HTMLDivElement | null) => {
  if (!imgContainer) {
    return null;
  }
  const fileReader = new FileReader();
  fileReader.onload = () => {
    const url: string = URL.createObjectURL(blob);
    const video = document.createElement('video') as HTMLVideoElement;
    const snapImage = () => {
      const canvas = document.createElement('canvas') as HTMLCanvasElement;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      // @ts-ignore
      canvas
        .getContext('2d')
        .drawImage(video, 0, 0, canvas.width, canvas.height);
      const image: string = canvas.toDataURL();
      const success: boolean = image.length > 100000;
      if (success && imgContainer) {
        const img = document.createElement('img') as HTMLImageElement;
        img.src = image;
        imgContainer.appendChild(img);
        URL.revokeObjectURL(url);
      }
      return success;
    };
    const timeupdate = () => {
      if (snapImage()) {
        video.removeEventListener('timeupdate', timeupdate);
        video.pause();
      }
    };
    video.addEventListener('loadeddata', () => {
      if (snapImage()) {
        video.removeEventListener('timeupdate', timeupdate);
      }
    });
    video.addEventListener('timeupdate', timeupdate);
    video.preload = 'metadata';
    video.src = url;
    // Load video in Safari / IE11
    video.muted = true;
    video.playsInline = true;
    video.play();
  };
  fileReader.readAsArrayBuffer(blob);
};
