import React, { useContext, createContext, ReactNode } from 'react';
import { User } from '../sidebar/models/User';
import { Comment } from '../sidebar/models/Comment';

// @ts-ignore
const CommentsStateContext = createContext<CommentsState>();

type CommentsState = {
  currentUserId: string | number;
  users: User[];
  usersNotOnProject: User[];
  getContainer: () => ReactNode;
  isAdminView?: boolean;
  createComment: (commentBody: any) => void;
  deleteComment: (commentId: number) => void;
  updateComment: (commentId: number, commentBody: any) => void;
  canViewPrivateComments: boolean;
  canCreatePrivateComments: boolean;
  billingRights: boolean;
  owner: User;
  bugherdUrl: string;
  organizationId: string | number;
  guestVisible: boolean;
  taskId: number;
  isSmallView?: boolean;
  isNewSidebar?: boolean;
  commentsLoading: boolean;
  canEditComment: boolean;
  comments: Comment[];
  children?: ReactNode;
  onPlanUpgradeClick?: () => void;
  projectId: number;
};

const CommentsProvider = ({
  currentUserId,
  users,
  usersNotOnProject,
  getContainer,
  isAdminView,
  createComment,
  deleteComment,
  updateComment,
  canViewPrivateComments,
  canCreatePrivateComments,
  billingRights,
  owner,
  bugherdUrl,
  organizationId,
  guestVisible,
  taskId,
  isSmallView,
  isNewSidebar,
  commentsLoading,
  canEditComment,
  comments,
  children,
  onPlanUpgradeClick,
  projectId
}: CommentsState) => {
  return (
    <CommentsStateContext.Provider
      value={{
        currentUserId,
        users,
        usersNotOnProject,
        getContainer,
        isAdminView,
        createComment,
        deleteComment,
        updateComment,
        canViewPrivateComments,
        canCreatePrivateComments,
        billingRights,
        owner,
        bugherdUrl,
        organizationId,
        guestVisible,
        taskId,
        isSmallView,
        isNewSidebar,
        commentsLoading,
        canEditComment,
        comments,
        onPlanUpgradeClick,
        projectId
      }}
    >
      {children}
    </CommentsStateContext.Provider>
  );
};

const useCommentsState = () => {
  const context = useContext(CommentsStateContext);

  if (context === undefined) {
    throw new Error('useCommentsState must be used within a CommentsProvider');
  }

  return context;
};

export { CommentsProvider, useCommentsState, CommentsState };
