import { ProjectSite } from './types';
import { isEmpty } from 'lodash-es';
import qs from 'query-string';

export function getPageLocation({
  matchedSite,
  browserUrl
}: {
  matchedSite: ProjectSite;
  browserUrl: URL;
}): string {
  const taskPath = browserUrl.pathname.substring(matchedSite.path.length);
  const siteParams = qs.parse(matchedSite.query || '') || {};
  const browserParams = qs.parse(browserUrl.search.substring(1)) || {};
  const paramsDiff = Object.keys(siteParams);
  paramsDiff.forEach(key => {
    delete browserParams[key];
  });

  return (
    (taskPath[0] === '/' ? taskPath : '/' + taskPath) +
    (browserParams && !isEmpty(browserParams)
      ? '?' + qs.stringify(browserParams)
      : '') +
    browserUrl.hash
  );
}

export function getTaskPageUrl({ page }: { page: string }): URL {
  const a = new URL('https://test.com' + page);
  return a;
}

export function matchesPath({
  siteTaskPath,
  taskPagePath,
  browserPath
}: {
  siteTaskPath: string;
  taskPagePath: string;
  browserPath: string;
}): boolean {
  if (siteTaskPath === '/') {
    return taskPagePath === browserPath;
  } else if (siteTaskPath.endsWith('/')) {
    siteTaskPath = siteTaskPath.substring(0, siteTaskPath.length - 1);
  }
  let taskPath = browserPath.substring(siteTaskPath.length);
  if (taskPath === '') {
    taskPath = '/';
  }
  return taskPath === taskPagePath;
}
