import React, { FC, ReactNode } from 'react';
import * as linkify from 'linkifyjs';
// @ts-ignore
import mention from 'linkifyjs/plugins/mention';
import Linkify from 'linkifyjs/react';
import {
  getAllUsers,
  getProjectMembers,
  getProjectUsers
} from '../../models/Project';
import styles from './index.module.css';
import cx from 'classnames';
import * as translations from './strings';
import { User, Props } from './types';
import { getLangKey } from '../../models/Language';

const strings = translations[getLangKey()];

mention(linkify);

const regex: RegExp = /@\[([^\[]+)\]\(([\d\)]+)\)/g;

const HighlightedMentions: FC<Props> = props => {
  const {
    description,
    className,
    isComment,
    isPrivate,
    notMentionInlineStyle,
    mentionInlineStyle
  } = props;
  const justMembers = !isComment;
  const projectUsers = justMembers
    ? props.projectMembers || getProjectMembers()
    : props.projectUsers || getProjectUsers();
  const allUsers = props.allUsers || getAllUsers();

  const isAnId = (id: string): boolean =>
    !id.match(/\D+/g)?.length && !isNaN(parseInt(id, 10));

  const getUser = (id: string, users: User[]) =>
    users.find((user: User) => user.id === parseInt(id, 10));

  const getTitle = (type: string, id: string) => {
    const newId = id.slice(1);
    const projectUser = getUser(newId, projectUsers);
    const user = getUser(newId, allUsers);
    if (type === 'mention') {
      if (projectUser) {
        return `${projectUser.name}: ${projectUser.email}`;
      } else if (user) {
        return strings.noAccess(user.name);
      } else if (isAnId(newId)) {
        return strings.noFoundUser;
      }
    }
    return id;
  };

  const getRemovedUserName = (id: string) => {
    const regexString = `@\\[([^\\[]+)\\]\\(([${id}\\)]+)\\)`;
    const idSpecificRegex = new RegExp(regexString, 'g');
    const matches = description.match(idSpecificRegex);
    const match =
      matches &&
      matches.find(
        (match: string) => match.indexOf(id) === match.length - 1 - id.length
      );
    return match?.replace(idSpecificRegex, '@$1');
  };

  const getNotMention = (id: string) => {
    const userNotOnProject = getUser(id, allUsers);
    let name = `@${id}`;
    if (userNotOnProject) {
      name = userNotOnProject.name;
    } else if (isAnId(id)) {
      name = getRemovedUserName(id) || name;
    }

    return (
      <span
        className={cx(styles.notMention, {
          [styles.isPrivateComment]: isComment && isPrivate,
          [styles.notOnProject]: !!userNotOnProject,
          [styles.noUserFound]: isAnId(id) && !userNotOnProject
        })}
        style={
          notMentionInlineStyle
            ? notMentionInlineStyle(!!userNotOnProject || isAnId(id))
            : {}
        }
      >
        {name}
      </span>
    );
  };

  const mentionFormatter = (mentionId: string): ReactNode => {
    const id = mentionId.replace('@', '');
    const mentionedUser = getUser(id, projectUsers);
    if (!mentionedUser) {
      return getNotMention(id);
    }
    return (
      <span
        className={cx(styles.mention, { [styles.isCommentMention]: isComment })}
        style={mentionInlineStyle ? mentionInlineStyle(!!mentionedUser) : {}}
      >{`@${mentionedUser.name}`}</span>
    );
  };

  return (
    <Linkify
      options={{
        tagName: {
          mention: 'span'
        },
        attributes: (href, type) => {
          return {
            rel: 'noreferrer',
            title: getTitle(type, href)
          };
        },
        target: '_blank',
        events: { click: (event: MouseEvent) => event.stopPropagation() },
        format: {
          // @ts-ignore
          mention: mentionFormatter
        }
      }}
      className={className}
    >
      {description.replace(regex, '@$2')}
    </Linkify>
  );
};

export default HighlightedMentions;
