import React, { FC } from 'react';
import styles from './styles.module.css';
import * as translations from '../strings';
import { getLangKey } from 'appJS/models/Language';
import { Divider } from 'antd';
import SiteCard from './SiteCard';
import { useGuestProjectState } from '../GuestProjectProvider';
import InstallButton from 'appJS/components/onboarding/install_button';

const strings = translations[getLangKey()];

const Websites: FC = () => {
  const { data, extensionInstalled, browserConfig } = useGuestProjectState();

  const guestSites = data?.currentUser.userProject.guestSites;
  const hasNewOnboarding =
    data?.currentUser?.userProject?.organization.experiments?.includes(
      'checklist_onboarding_variant'
    ) ||
    data?.currentUser?.userProject?.organization.experiments?.includes(
      'add_website_onboarding_variant'
    );

  return guestSites?.length ? (
    <div className={styles.container}>
      <h1>{strings.websites}</h1>
      <Divider />
      <div className={styles.sitesContainer}>
        {guestSites.map(projectSite => (
          <SiteCard
            hasNewOnboarding={hasNewOnboarding}
            projectSite={projectSite}
            key={projectSite.id}
          />
        ))}
        {!extensionInstalled && browserConfig && !hasNewOnboarding && (
          <div className={styles.extensionCard}>
            <p className={styles.extensionMessage}>
              {strings.extensionMessage}
            </p>
            <div className={styles.installButtonWrapper}>
              <InstallButton browserConfig={browserConfig} />
            </div>
          </div>
        )}
      </div>
    </div>
  ) : null;
};

export default Websites;
