import React, {
  useState,
  useEffect,
  createContext,
  ReactNode,
  useContext
} from 'react';
import { get } from '../../javascript/utils/fetch';
import camelCaseKeys from 'camelcase-keys';

type Organization = {
  id: number;
  experiments: string[];
};

type OrganizationState = {
  loading: boolean;
  error: any;
  organization: Organization | null;
  hasOrganizationExperiment: (experiment: string) => boolean;
};

// @ts-ignore
const OrganizationStateContext = createContext<OrganizationState>();

const OrganizationProvider = ({
  apiDomain = '',
  id,
  children
}: {
  apiDomain?: string;
  id: number;
  children: ReactNode;
}) => {
  const [organization, setOrganization] = useState<Organization | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    // Adjust this to fetch organization data
    const fetchOrganization = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await get(`${apiDomain}/organizations/${id}`);
        const data = camelCaseKeys(response, { deep: true });
        setOrganization(data);
      } catch (err) {
        console.error('Failed to fetch organization:', err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchOrganization();
  }, [id, apiDomain]);

  const hasOrganizationExperiment = (experiment: string) => {
    return !!organization?.experiments.includes(experiment);
  };

  return (
    <OrganizationStateContext.Provider
      value={{ organization, loading, error, hasOrganizationExperiment }}
    >
      {children}
    </OrganizationStateContext.Provider>
  );
};

const useOrganizationState = (): OrganizationState => {
  const context = useContext(OrganizationStateContext);

  if (context === undefined) {
    throw new Error(
      'useOrganizationState must be used within a OrganizationProvider'
    );
  }

  // @ts-ignore
  return context;
};

// Export the context and provider
export { OrganizationStateContext, OrganizationProvider, useOrganizationState };
