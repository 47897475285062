import * as React from 'react';
import { message } from 'antd';
import { Navigate } from 'react-router-dom';

import Error from 'components/Error';
import UserPageLayout from 'components/UserPageLayout';
import { getLangKey } from 'models/Language';
import { useCurrentOrganizationIdParam } from 'models/Organization';

import UserProjectsList from './UserProjectsList';
import { useGetCurrentUserQuery } from './queries';
import RedirectToSubscription from './RedirectToSubscription';
import Loader from './Loader';
import * as translations from './strings';

const UserProjects = ({ container }: { container: HTMLDivElement }) => {
  const strings = translations[getLangKey()];
  const organizationId = useCurrentOrganizationIdParam();

  const { data, loading, error } = useGetCurrentUserQuery({ organizationId });

  if (loading) return <Loader />;
  if (error) {
    message.error(strings.refreshPageErrorMessage);
    return <Error error={error} />;
  }

  if (!data) {
    return <Navigate replace to="/" />;
  }

  const hasUnreadCommentsExperiment = true;

  return (
    <UserPageLayout>
      {data.currentUser.organization.isActive ? (
        <UserProjectsList
          currentUser={data.currentUser}
          accessTo={data.currentUser.accessTo}
          hasUnreadCommentsExperiment={hasUnreadCommentsExperiment}
          container={container}
        />
      ) : (
        <RedirectToSubscription organizationId={organizationId}>
          <UserProjectsList
            currentUser={data.currentUser}
            accessTo={data.currentUser.accessTo}
            container={container}
            disabled
            hasUnreadCommentsExperiment={hasUnreadCommentsExperiment}
          />
        </RedirectToSubscription>
      )}
    </UserPageLayout>
  );
};

export default UserProjects;
