import React, { FC, MutableRefObject, useRef, useState } from 'react';
import { AutoComplete, Input, Tag } from 'antd';
import Avatar from '../../../../../shared/Avatar';
import { User, UserPlus, Slash } from 'lucide-react';
import DetailsLabel from '../DetailsLabel';
import * as translations from './strings';
import { getLangKey } from '../../../../models/Language';
import { User as Assignee } from '../../../../models/User';

// @ts-ignore
import styles from './styles.module.css';
import cx from 'classnames';

const strings = translations[getLangKey()];

type Props = {
  canEditAssignees: boolean;
  assignees: any[];
  updateAssignees: (assigneeIds: number[]) => void;
  assignableUsers: any[];
};

const Assignees: FC<Props> = ({
  canEditAssignees,
  assignees,
  updateAssignees,
  assignableUsers
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [showInput, setShowInput] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');
  const inputRef: MutableRefObject<null | Input> = useRef(null);

  const placeholder =
    (!assignees.length && strings.unassigned) ||
    (showDropdown && strings.searchForUser) ||
    '';

  const handleUnassignUser = (assignee: Assignee) =>
    updateAssignees(
      assignees
        .filter((user: any) => user.id !== assignee.id)
        .map((user: any) => user.id)
    );

  const getDataSource = () => {
    let query = value;
    let data = assignableUsers.filter(
      (user: Assignee) =>
        !assignees.find((assignee: Assignee) => assignee.name === user.name)
    );

    if (query) {
      query = query.toLowerCase();
      data = data.filter(
        (user: Assignee) =>
          user.name.slice(0, query.length).toLowerCase() === query
      );
    }

    if (data.length) {
      return data.map((user: Assignee) => ({
        value: user.name,
        label: (
          <div className={styles.userContainer}>
            <Avatar size="small" member={user} />
            <span className={styles.userName}>{user.name}</span>
          </div>
        )
      }));
    } else {
      return [
        {
          value: strings.noResults,
          label: (
            <div className={styles.userContainer}>
              <Slash className={styles.slashIcon} />
              <span className={styles.userName}>{strings.noMatchingUsers}</span>
            </div>
          )
        }
      ];
    }
  };

  const getAssignees = () =>
    !!assignees?.length &&
    assignees.map((assignee: Assignee) => (
      <span className={styles.tagHolder} key={assignee.id + assignee.name}>
        <Tag
          className={styles.tag}
          closable={canEditAssignees}
          onClose={() => handleUnassignUser(assignee)}
        >
          <span className={styles.tagContent}>
            <User className={styles.userIcon} />
            <span className={styles.innerTag}>{assignee.name}</span>
          </span>
        </Tag>
      </span>
    ));

  const blurInput = () => {
    setShowInput(false);
    setValue('');
  };

  const handleOnBlur = (inputValue: number | string = value) => {
    if (!inputValue) return blurInput();

    let updatedAssignees = assignees.map((user: Assignee) => user.id);

    if (typeof inputValue !== 'string') {
      updatedAssignees.push(inputValue);
    } else if (assignableUsers.length) {
      let newAssignee = assignableUsers.find(
        (user: any) =>
          user.name.slice(0, inputValue.length).toLowerCase() ===
          inputValue.toLowerCase()
      );
      if (newAssignee) {
        updatedAssignees.push(newAssignee.id);
      }
    }
    if (assignees.length < updatedAssignees.length)
      updateAssignees(updatedAssignees);

    blurInput();
  };

  const escapeCode: number = 27;

  const handleOnKeyDown = (event: any) =>
    event?.keyCode === escapeCode && blurInput();

  return (
    <div className={styles.assigneesOuter}>
      <DetailsLabel label={strings.assignedTo} />
      <div className={styles.assigneesInner}>
        <div className={styles.assigneesBox}>{getAssignees()}</div>
        <div className={styles.inputBox}>
          {showInput ? (
            <AutoComplete
              className={cx(styles.assigneesAutoComplete, 'assigneesInput')}
              getPopupContainer={trigger => trigger.parentElement}
              options={getDataSource()}
              value={value}
              onChange={setValue}
              onBlur={() => handleOnBlur()}
              dropdownMatchSelectWidth
              // @ts-ignore
              onSelect={inputValue => {
                setValue(inputValue);
                handleOnBlur(inputValue);
              }}
              onDropdownVisibleChange={setShowDropdown}
              open={showDropdown}
              disabled={!canEditAssignees}
            >
              <Input
                ref={inputRef}
                className={styles.assigneesInput}
                type="text"
                onKeyDown={handleOnKeyDown}
                placeholder={placeholder}
                onPressEnter={() => handleOnBlur()}
                disabled={!canEditAssignees}
              />
            </AutoComplete>
          ) : (
            <div className={styles.tagAndAlertBox}>
              <Tag
                onClick={() => {
                  setShowInput(true);
                  setTimeout(() => {
                    inputRef?.current?.focus();
                    setShowDropdown(true);
                  }, 300);
                }}
                className={cx(styles.tag, styles.addTag)}
              >
                <UserPlus className={styles.assignUserIcon} />{' '}
                {strings.assignUser}
              </Tag>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Assignees;
