import { matchesTaskFragment } from './matchFragment';
import {
  matchProjectSite,
  getHostnamePathfromSite,
  getBestMatchedProjectSite
} from './matchProjectSite';
import { matchesTaskQueryString } from './matchQuery';
import { getPageLocation, getTaskPageUrl, matchesPath } from './pageLocation';
import { composeTaskLocation } from './composeTaskLocation';
import { TaskLocation, TaskLocationProps, ProjectSite } from './types';
import getAssetUrlForTask from '../filterOutAssetParams';

export function getTaskLocation({
  browserLocation,
  projectSites
}: TaskLocationProps): TaskLocation {
  try {
    const browserLocationUrl = new URL(browserLocation);
    const matchedProjectSites: ProjectSite[] = projectSites.filter(
      (site: ProjectSite) =>
        matchProjectSite({ site: site, browserUrl: browserLocationUrl })
    );

    if (matchedProjectSites.length > 0) {
      const matchedProjectSite: ProjectSite | undefined =
        matchedProjectSites.length > 1
          ? getBestMatchedProjectSite({
              matchedSites: matchedProjectSites,
              browserLocationUrl: browserLocationUrl
            })
          : matchedProjectSites[0];
      if (matchedProjectSite) {
        return {
          projectSite: getHostnamePathfromSite(matchedProjectSite),
          pageLocation: getPageLocation({
            matchedSite: matchedProjectSite,
            browserUrl: browserLocationUrl
          })
        };
      }
    }
    return {
      projectSite: browserLocationUrl.origin,
      pageLocation:
        browserLocationUrl.pathname +
        browserLocationUrl.search +
        browserLocationUrl.hash
    };
  } catch {
    return { projectSite: '', pageLocation: '' };
  }
}

export function isTaskLocation({
  projectSites,
  taskSite,
  taskPage,
  browserLocation
}: {
  projectSites: ProjectSite[];
  taskSite: string;
  taskPage: string;
  browserLocation: string;
}): boolean {
  try {
    browserLocation = getAssetUrlForTask(browserLocation);

    const browserLocationUrl = new URL(browserLocation);
    const taskPageUrl: URL = getTaskPageUrl({ page: taskPage });
    const taskSiteUrl: URL = new URL(taskSite);

    if (
      (!!taskSiteUrl.search || !!taskPageUrl.search) &&
      !matchesTaskQueryString({
        siteQs: taskSiteUrl.searchParams,
        pageQs: taskPageUrl.search ? taskPageUrl.search.slice(1) : '',
        browserQs: browserLocationUrl.searchParams
      })
    ) {
      return false;
    }

    if (
      (!!taskSiteUrl.hash || !!taskPageUrl.hash) &&
      !matchesTaskFragment({
        taskFragment: taskPageUrl.hash,
        browserFragment: browserLocationUrl.hash
      })
    ) {
      return false;
    }

    const matchedProjectSites: ProjectSite[] = projectSites.filter(
      (site: ProjectSite) =>
        matchProjectSite({ site: site, browserUrl: browserLocationUrl })
    );

    if (matchedProjectSites.length > 0) {
      const matchedProjectSite: ProjectSite | undefined =
        matchedProjectSites.length > 1
          ? getBestMatchedProjectSite({
              matchedSites: matchedProjectSites,
              browserLocationUrl: browserLocationUrl
            })
          : matchedProjectSites[0];
      if (matchedProjectSite && taskPage) {
        return matchesPath({
          siteTaskPath: matchedProjectSite.path,
          taskPagePath: taskPageUrl.pathname,
          browserPath: browserLocationUrl.pathname
        });
      }
    } else {
      return matchesPath({
        siteTaskPath: '/',
        taskPagePath: taskPageUrl.pathname,
        browserPath: browserLocationUrl.pathname
      });
    }

    return true;
  } catch (TypeError) {
    return false;
  }
}

export { composeTaskLocation };
