export const en = {
  generateInviteUrl: 'Generating invite url',
  generatedInviteUrl: 'Generated invite url',
  errorGeneratingInviteUrl: 'Error generating invite url',
  disablingInviteUrl: 'Disabling invite url',
  disabledInviteUrl: 'Disabled invite url',
  errorDisablingInviteUrl: 'Error disabling invite url',
  guestInviteLink: 'Guest Invite Link',
  shareThisLink:
    'Share this link with your guests to allow them to access your project.',
  copy: 'Copy',
  disableLink: 'Disable Link',
  createInviteLink: 'Create Invite Link',
  followingUsersHaveAccess: 'The following users have access to this project.',
  hasBeenInvited: 'has been invited to ',
  hasBeenResent: 'has been resent an invite to ',
  hasBeenRemoved: 'has been removed from ',
  copiedToClipboard: 'Copied to clipboard!',
  copyLink: 'Copy the link below and share it with anyone.',
  shareLink: 'Share link'
};

export type Translations = typeof en;
