import React, { FC } from 'react';
import styles from './styles.module.css';
import WelcomeBanner from './WelcomeBanner';
import QuickLinks from './QuickLinks';
import { useGuestProjectState } from '../GuestProjectProvider';

export const ProjectHeader: FC = () => {
  const { data } = useGuestProjectState();

  const name = data?.currentUser.userProject.name;
  return (
    <>
      <h1 className={styles.header} title={name}>
        {name}
      </h1>
      <div className={styles.welcomeAndLinks}>
        <WelcomeBanner />
        <QuickLinks />
      </div>
    </>
  );
};
