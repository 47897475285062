import { useQuery, gql } from '@apollo/client';
import { PlanInterval } from './types';

export type Plan = {
  id: string;
  name: string;
  price: number;
  handle: string;
  interval: PlanInterval;
  maxMembers: number;
};

export type SubscriptionPlan = {
  __typename: 'SubscriptionPlan';
  state: string;
  price: number;
  plan: {
    id: string;
    name: string;
    handle: string;
    interval: PlanInterval;
    price: number;
    maxMembers: number;
  };
  additionalMembers: number;
  renewalDate: string;
  paymentExtension: string;
  maxMembers: number;
  interval: PlanInterval;
  subscriptionPrice: number;
  addonPremiumIntegrations: boolean;
};

export type SubscriptionTrial = {
  __typename: 'SubscriptionTrial';
  trialExpiryAt: string;
  state: string;
};

interface Query {
  currentUser: {
    organization: {
      id: string;
      name: string;
      validPartnerCoupon: boolean;
      activePartnerCouponDescription: string;
      apiKey: string;
      owner: {
        name: string;
        email: string;
      };
      numMembers: number;
      subscription: SubscriptionPlan | SubscriptionTrial;
      availablePlans: {
        id: string;
        name: string;
        handle: string;
        price: number;
        maxMembers: number;
        interval: PlanInterval;
      }[];
      experiments: string[];
      pausePlan: Plan;
    };
  };
}
interface Variables {
  organizationId: string;
}

const GET_SUBSCRIPTION = gql`
  query getSubscription($organizationId: ID!) {
    currentUser {
      organization(organizationId: $organizationId) {
        id
        name
        apiKey
        validPartnerCoupon
        activePartnerCouponDescription
        owner {
          name
          email
        }
        numMembers
        subscription {
          __typename
          ... on SubscriptionTrial {
            state
            trialExpiryAt
          }
          ... on SubscriptionPlan {
            state
            price
            interval
            plan {
              name
              handle
              interval
              maxMembers
              price
            }
            additionalMembers
            addonPremiumIntegrations
            renewalDate
            paymentExtension
            maxMembers
            subscriptionPrice
          }
        }
        availablePlans {
          id
          name
          handle
          price
          maxMembers
          interval
        }
        experiments
        pausePlan {
          id
          name
          handle
          price
          maxMembers
          interval
        }
      }
    }
  }
`;

export const useSubscriptionQuery = (organizationId: string) =>
  useQuery<Query, Variables>(GET_SUBSCRIPTION, {
    variables: {
      organizationId: organizationId
    }
  });
