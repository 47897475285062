export const en = {
  goToYourOrganizations: 'Go to your organizations',
  goToYourWebsite: 'Go to your website',
  goToYourProject: 'Go to your project',
  goToYourProxy: 'Go to your website ✨ VIA PROXY ✨',
  viewYourProjects: 'View your projects',
  youreReady: 'You’re ready to use BugHerd ✨ VIA PROXY ✨',
  quickVideo: 'To get you up to speed we’ve put together a quick video. Enjoy!'
};

export type Translations = typeof en;
