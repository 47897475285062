import './webVitals';
import React, { FC, useEffect, useState } from 'react';
import { trackPage } from 'appJS/utils/analytics';
import { post } from 'utils/fetch';
import { createPortal } from 'react-dom';
import { message } from 'antd';
import { getLangKey } from 'models/Language';
import styles from './styles.module.css';
import { ProjectNav } from './project_nav';
import { ProjectHeader } from './project_header';
import { DesignAssetFeedbackProps } from '../../../clients/design_assets';
import {
  DesignAssetsProvider,
  useDesignAssetsState
} from '../../../clients/providers/DesignAssets';
import {
  ParagonIntegrationsProvider,
  useParagonIntegrationsState
} from '../../../clients/providers/ParagonIntegrations';
import useMediaQuery from '../../../../clients/sidebar/views/CreateTask/create_task/useMediaQuery';
import { Tag } from '../../../clients/sidebar/models/Project';
import {
  ProjectSitesProvider,
  useProjectSitesState
} from '../../../clients/providers/ProjectSites';
import { User, View } from './project_nav/types';
import { AntdWrapper } from '../../../clients/shared/AntdWrapper';
import { getContainers } from './getContainers';
import { PlaceHolder } from '../../../clients/design_assets/ComingSoonModal';
import { MainScreen } from '../../../clients/design_assets/MainScreen';
import { MobileNav } from './project_nav/MobileNav';
import GettingStarted from './GettingStarted/index';
import { AddTaskOrAssetButton } from './project_header/AddTaskOrAssetButton';
import { ActiveKey, NewSettingsProps } from '../new_project_settings/types';
import NewProjectSettings from '../new_project_settings';
import { getCollapsedState, setCollapsedState } from './utils';
import FeedbackBanner from './FeedbackBanner';
import { ShareProject } from '../share_project';
import { Props as ShareProps } from '../share_project/types';
import { checkForExtension } from 'utils/checkForBugherdExtension';
import * as translations from './strings';
import {
  OrganizationProvider,
  useOrganizationState
} from '../../../clients/providers/Organization';
import { Provider, useDispatch } from 'react-redux';

const strings = translations[getLangKey()];

type PathView = {
  path: string;
  view: View;
};

const projectBoardTabs: PathView[] = [
  { path: 'getting-started', view: View.GettingStarted },
  { path: 'assets', view: View.Assets },
  { path: 'triage', view: View.Triage },
  { path: 'home', view: View.Home },
  { path: 'kanban', view: View.Kanban },
  { path: 'tasks', view: View.Kanban },
  { path: 'archive', view: View.Archive },
  { path: 'settings', view: View.Settings },
  { path: 'integrations', view: View.Integrations },
  { path: 'members', view: View.Members },
  { path: 'guests', view: View.Guests },
  { path: 'collaborators', view: View.Collaborators },
  { path: 'tags', view: View.Tags }
];

type Props = {
  triageCount: number;
  kanbanCount: number;
  devurl: string;
  tags: Tag[];
  canAccess: {
    billing: boolean;
    viewKanbanProject: boolean;
    viewGuestKanban: boolean;
  };
  users: User[];
  assetFeedbackProps: DesignAssetFeedbackProps;
  settingsProps: NewSettingsProps;
  apiKey: string;
  isAdminUser: boolean;
  isReviewApp: boolean;
  organizations: Object;
  hasLegacyIntegrations: boolean;
  showGettingStarted: boolean;
  hasNewOnboardingExperiment: boolean;
  sharingProps: ShareProps;
  proxyHost: string;
};

const ProjectBoard: FC<Props> = ({
  triageCount: initialTriageCount,
  kanbanCount: initialKanbanCount,
  tags,
  canAccess,
  users,
  devurl,
  settingsProps,
  isAdminUser,
  isReviewApp,
  apiKey,
  assetFeedbackProps,
  hasLegacyIntegrations,
  showGettingStarted,
  sharingProps,
  proxyHost
}) => {
  const projectBoardOnboardingCompleted =
    assetFeedbackProps.project?.projectBoardOnboardingCompleted ||
    localStorage.getItem('finished_project_onboarding');
  const { hasOrganizationExperiment } = useOrganizationState();
  const hasNewOnboardingExperiment =
    assetFeedbackProps.project.hasNewOnboardingExperiment;
  const hasChecklistOnboardingExperiment = hasOrganizationExperiment(
    'checklist_onboarding_variant'
  );
  const hasAddWebsiteOnboardingExperiment = hasOrganizationExperiment(
    'add_website_onboarding_variant'
  );
  const hasNewOnboarding =
    hasNewOnboardingExperiment ||
    hasChecklistOnboardingExperiment ||
    hasAddWebsiteOnboardingExperiment;

  const [view, _setView] = useState<View | null>(null);
  const [activeKey, setActiveKey] = useState<ActiveKey>(ActiveKey.General);
  const [collapsed, setCollapsed] = useState<boolean>(
    () => getCollapsedState()['ProjectNav'] || false
  );
  const [gettingStartedCompleted, setGettingStartedCompleted] = useState(false);

  const isNoLoginGuestExperienceEnabled = hasOrganizationExperiment(
    'no_login_guest_experience'
  );

  const isMobileView = useMediaQuery('(max-width: 640px)');
  const {
    setShowAssets,
    showAssets,
    boardContainer,
    data: assets
  } = useDesignAssetsState();

  const {
    paneHead,
    headerContainer,
    settingsBoard,
    gettingStartedContainer
  } = getContainers(isMobileView);
  const [triageCount, setTriageCount] = useState<number>(
    initialTriageCount || 0
  );
  const [kanbanCount, setKanbanCount] = useState<number>(
    initialKanbanCount || 0
  );
  const [hasExtensionInstalled, setHasExtensionInstalled] = useState(false);
  const [hasScriptInstalled, setHasScriptInstalled] = useState(false);
  const [hasDeliverables, setHasDeliverables] = useState(false);
  const [hasIntegrations, setHasIntegrations] = useState(hasLegacyIntegrations);
  const { project } = assetFeedbackProps || {};

  const { projectSites } = useProjectSitesState();
  const { paragonIntegrations } = useParagonIntegrationsState();

  const hasSeenOnboardingVideo = localStorage.getItem(
    'checklist_onboarding_video_seen'
  );

  const gettingStartedCheck =
    hasChecklistOnboardingExperiment || hasAddWebsiteOnboardingExperiment
      ? {
          hasSeenOnboardingVideo,
          hasDeliverables,
          hasManagedFeedback: projectBoardOnboardingCompleted,
          hasInvitedUsers: users.length > 1,
          hasIntegrations
        }
      : {
          hasExtensionOrScript: hasExtensionInstalled || hasScriptInstalled,
          hasDeliverables,
          hasInvitedUsers: users.length > 1,
          hasIntegrations
        };
  const totalCompletedSteps = Object.values(gettingStartedCheck).filter(Boolean)
    .length;
  const totalStepsCount = Object.keys(gettingStartedCheck).length;
  const gettingStartedCount = `${totalCompletedSteps}/${totalStepsCount}`;

  const dispatch = useDispatch();

  const setView = (view: View | null) => {
    _setView(view);
    dispatch({
      type: 'SET_MODE',
      payload: {
        mode: view,
        navigate: true
      }
    });
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const showAssetsPage = () => {
    //@ts-expect-error
    window.bugherd.applicationView.showAssets();
    setShowAssets(true);
    setView(canAccess.viewGuestKanban ? View.Home : View.Assets);
  };

  const handleChecklistComplete = async () => {
    if (gettingStartedCompleted) return;
    setGettingStartedCompleted(true);
    try {
      await post(`/projects/${project.id}/complete_getting_started`, {});
      message.success(strings.completeGettingStartedSuccess, 5);
      if (view !== View.GettingStarted) return;
      setTimeout(() => {
        showAssetsPage();
      }, 2500);
    } catch (error) {
      console.error({ error });
      message.error(strings.completeGettingStartedError);
      setGettingStartedCompleted(false);
    }
  };

  const checkForDeliverables = () => {
    setHasDeliverables(projectSites?.length > 0 || assets.length > 0);
  };

  const checkForScriptInstalled = () => {
    setHasScriptInstalled(project.javascriptInstalled);
  };

  const checkForExtensionInstalled = async () => {
    const extensionResponse = await checkForExtension();
    if (extensionResponse) {
      setHasExtensionInstalled(!!extensionResponse);
    }
  };

  useEffect(() => {
    const appWrapper = document.getElementById('app-wrapper');
    if (appWrapper) {
      appWrapper.classList.toggle('collapsed', collapsed);
    }
    setCollapsedState('ProjectNav', collapsed);
  }, [collapsed]);

  useEffect(() => {
    if (!showGettingStarted || !hasNewOnboarding || gettingStartedCompleted)
      return;
    checkForDeliverables();
    if (!hasNewOnboardingExperiment) return;
    checkForExtensionInstalled();
    checkForScriptInstalled();
  }, [hasNewOnboarding, hasNewOnboardingExperiment, view]);

  useEffect(() => {
    if (!showGettingStarted || !hasNewOnboarding || gettingStartedCompleted)
      return;
    checkForDeliverables();
  }, [assets, projectSites]);

  useEffect(() => {
    if (!showGettingStarted || !hasNewOnboarding || gettingStartedCompleted)
      return;
    checkForScriptInstalled();
  }, [project]);

  useEffect(() => {
    if (totalCompletedSteps === totalStepsCount) {
      handleChecklistComplete();
    }
  }, [gettingStartedCheck]);

  useEffect(() => {
    if (!showGettingStarted || !hasNewOnboarding || gettingStartedCompleted)
      return;
    setHasIntegrations(
      Object.values(paragonIntegrations).some(
        integration => integration.enabled
      ) || hasLegacyIntegrations
    );
  }, [paragonIntegrations]);

  const shouldAddClass =
    !!view &&
    [View.Home, View.Settings, View.Integrations, View.Tags].includes(view);

  const toggleSubActionsClass = (add?: boolean) => {
    const paneCenter = document.getElementsByClassName('pane-center')[0];
    if (paneCenter)
      paneCenter.classList[add ? 'add' : 'remove']('noSubActions');
  };

  useEffect(() => {
    if (view === 'integrations') {
      trackPage('Integration settings popup', {
        //ts-expect-error
        //eslint-disable-next-line no-underscore-dangle
        organization_id: window._bugHerd?.data?.project_data?.organization?.id,
        //ts-expect-error
        //es-lint-disable-next-line
        project_id: window._bugHerd?.data?.project_data?.id
      });
    }

    toggleSubActionsClass(shouldAddClass);
  }, [view]);

  useEffect(() => {
    // @ts-expect-error
    if (!window?.project_board_props) return;
    // @ts-expect-error
    setTriageCount(window?.project_board_props?.triageCount);
    // @ts-expect-error
    setKanbanCount(window?.project_board_props?.kanbanCount);
  }, [
    // @ts-expect-error
    window?.project_board_props?.triageCount,
    // @ts-expect-error
    window?.project_board_props?.kanbanCount
  ]);

  useEffect(() => {
    // @ts-expect-error
    if (window.project_board_props) {
      // @ts-expect-error
      window.project_board_props.setActiveKey = setActiveKey;
    }
    // set initial view based on pathname
    const currentTab = projectBoardTabs.find(tab =>
      window.location.pathname.includes(tab.path)
    );
    if (currentTab) {
      _setView(currentTab.view);
    }
  }, []);

  return (
    <div className={styles.projectBoard}>
      <ProjectNav
        triageCount={triageCount}
        kanbanCount={kanbanCount}
        gettingStartedCount={gettingStartedCount}
        tags={tags}
        users={users}
        collapsed={collapsed}
        toggleCollapsed={toggleCollapsed}
        setView={setView}
        view={view}
        activeKey={activeKey}
        setActiveKey={setActiveKey}
        showGettingStartedChecklist={
          hasNewOnboarding && showGettingStarted && !gettingStartedCompleted
        }
        hasNewOnboarding={hasNewOnboarding}
        canAccess={canAccess}
        projectId={assetFeedbackProps.project.id}
        organizationId={assetFeedbackProps.project.organizationId}
      />
      {headerContainer &&
        createPortal(
          <ProjectHeader
            projectSites={projectSites}
            devurl={devurl}
            view={view}
            isMobileView={isMobileView}
            isReviewApp={isReviewApp}
            isAdminUser={isAdminUser}
            apiKey={apiKey}
            project={assetFeedbackProps?.project}
            hasNewOnboardingExperiment={hasNewOnboardingExperiment}
            viewGuestKanban={canAccess.viewGuestKanban}
          />,
          headerContainer
        )}
      {settingsBoard &&
        createPortal(
          <NewProjectSettings
            {...settingsProps}
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            setView={setView}
            view={view}
            showAssetsPage={showAssetsPage}
          />,
          settingsBoard
        )}
      {paneHead &&
        isMobileView &&
        createPortal(
          <div className={styles.mobileProjectHeader}>
            <MobileNav
              setActiveKey={setActiveKey}
              setView={setView}
              view={view}
              triageCount={triageCount}
              kanbanCount={kanbanCount}
              gettingStartedCount={gettingStartedCount}
              projectSites={projectSites}
              devurl={devurl}
              isReviewApp={isReviewApp}
              isAdminUser={isAdminUser}
              apiKey={apiKey}
              hasNewOnboarding={hasNewOnboarding}
              canAccess={canAccess}
              projectId={assetFeedbackProps.project.id}
              organizationId={assetFeedbackProps.project.organizationId}
              showAssetsPage={showAssetsPage}
            />
            <AddTaskOrAssetButton view={view} />
          </div>,
          paneHead
        )}
      <PlaceHolder />
      {boardContainer &&
        showAssets &&
        createPortal(<MainScreen />, boardContainer)}
      {gettingStartedContainer &&
        view === 'getting-started' &&
        createPortal(
          <GettingStarted
            setView={setView}
            project={assetFeedbackProps.project}
            gettingStartedCheck={gettingStartedCheck}
            canAccessBilling={canAccess.billing}
            handleChecklistComplete={handleChecklistComplete}
            showAssetsPage={showAssetsPage}
            container={gettingStartedContainer}
            kanbanCount={kanbanCount}
            proxyHost={proxyHost}
          />,
          gettingStartedContainer
        )}
      {hasNewOnboarding && view === 'kanban' && (
        <FeedbackBanner
          setView={setView}
          setShowAssets={setShowAssets}
          assets={assets}
          projectSites={projectSites}
        />
      )}
      <ShareProject
        show={sharingProps.show}
        organization={sharingProps.organization}
        users={sharingProps.users}
        endpoints={sharingProps.endpoints}
        authenticityToken={sharingProps.authenticityToken}
        project={sharingProps.project}
        canAccess={sharingProps.canAccess}
        container={paneHead}
        isNoLoginGuestExperienceEnabled={isNoLoginGuestExperienceEnabled}
        refreshOnClose={hasNewOnboarding && showGettingStarted}
        showAssetsPage={showAssetsPage}
      />
    </div>
  );
};

export default (props: Props) => {
  const { boardContainer, container } = getContainers();

  return (
    <AntdWrapper>
      <Provider store={window.bugherdStore}>
        <OrganizationProvider id={props.sharingProps.organization.id}>
          <ProjectSitesProvider
            apiKey={props.apiKey}
            projectId={props.assetFeedbackProps.project.id}
            proxyHost={props.proxyHost}
          >
            <DesignAssetsProvider
              {...props.assetFeedbackProps}
              container={container}
              boardContainer={boardContainer}
              viewGuestKanban={props.canAccess.viewGuestKanban}
            >
              <ParagonIntegrationsProvider
                paragonProjectId={
                  props.settingsProps.integrations.paragonProjectId
                }
                projectId={props.assetFeedbackProps.project.id}
              >
                <ProjectBoard {...props} />
              </ParagonIntegrationsProvider>
            </DesignAssetsProvider>
          </ProjectSitesProvider>
        </OrganizationProvider>
      </Provider>
    </AntdWrapper>
  );
};
