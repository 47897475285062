import React, { useState } from 'react';
import { getLangKey } from 'models/Language';
import styles from './index.module.css';
import cx from 'classnames';
import * as placeholders from '../../onboardingSurvey/Placeholders';
import { JobRole } from './userSurveyOptions';
import OptionalUserSurvey from 'models/OptionalUserSurvey';
import api, { completeOnboarding } from 'api/CommandApi';
import { Button, Select, Form, message } from 'antd';
import track from 'utils/analytics';
import * as translations from './strings';

interface UserSurveyProps {
  onFinish: () => void;
  organizationId: number;
}

const UserSurvey: React.FC<UserSurveyProps> = ({
  organizationId,
  onFinish
}) => {
  const strings = translations[getLangKey()];
  const [jobRole, setJobRole] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const [form] = Form.useForm();
  const checkNullValidation: boolean = !!jobRole;

  const submitSurvey = async () => {
    if (checkNullValidation) {
      setLoading(true);
      const optionalUserSurvey: OptionalUserSurvey = {
        organizationId,
        jobRole
      };

      try {
        await api.submitOptionalUserSurvey(optionalUserSurvey);
        track('Signup Survey Submitted', optionalUserSurvey);
        setLoading(false);
        await completeOnboarding().then(() => onFinish());
      } catch (e) {
        console.error(e);
        message.error(strings.completeOnboardingError);
      } finally {
        setLoading(false);
      }
    }
  };

  const skipSurvey = async () => {
    track('Onboarding Skip link clicked', { page_url: window.location.href });
    await completeOnboarding().then(() => onFinish());
  };

  return (
    <div>
      <div className={cx(styles.marginTop, styles.surveyHeaders)}>
        {strings.aboutYou}
      </div>
      <p className={styles.surveyHeaders}>{strings.jobRole}</p>
      <Form
        name="user_survey"
        form={form}
        onFinish={submitSurvey}
        className={styles.userForm}
      >
        <Form.Item
          name="job_role"
          rules={[{ required: true, message: 'Please select your job role' }]}
        >
          <Select
            placeholder={placeholders.JobRole}
            options={JobRole}
            onChange={setJobRole}
          />
        </Form.Item>
        <div>
          <Button
            className={styles.submitButton}
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Submit
          </Button>
          <Button
            className={styles.surveyButton}
            type="link"
            onClick={skipSurvey}
          >
            skip
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default UserSurvey;
