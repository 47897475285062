import { gql, useQuery } from '@apollo/client';

type Vars = {
  organizationId: string;
  offset: number;
  limit: number;
  filter?: {
    name: string;
  };
};

type UserProject = {
  id: string;
  name: string;
};

type CurrentUser = {
  id: string;
  userProjects: UserProject[];
  userProjectsCount: number;
};

type Data = {
  currentUser: CurrentUser;
};

const GET_USER_PROJECTS = gql`
  query getUserProjects(
    $organizationId: ID!
    $offset: Int!
    $limit: Int!
    $filter: ProjectFilter
  ) {
    currentUser {
      __typename
      id
      userProjectsCount(organizationId: $organizationId)
      userProjects(
        organizationId: $organizationId
        offset: $offset
        limit: $limit
        filter: $filter
      ) {
        id
        name
      }
    }
  }
`;

export const useGetUserProjectQuery = ({
  organizationId,
  offset,
  limit,
  filter
}: {
  organizationId: string;
  offset: number;
  limit: number;
  filter?: {
    name: string;
  };
}) =>
  useQuery<Data, Vars>(GET_USER_PROJECTS, {
    variables: {
      organizationId,
      offset,
      limit,
      filter
    }
  });
