import React, {
  useState,
  useRef,
  useEffect,
  MutableRefObject,
  FC
} from 'react';

import { findDOMNode } from 'react-dom';

import { Smile as Emoji } from 'lucide-react';
import { NimblePicker } from 'emoji-mart';
import data from 'emoji-mart/data/apple.json';

import 'emoji-mart/css/emoji-mart.css';
import { UploadFile } from 'antd/lib/upload/interface';

import * as Types from '../types';
import { Task } from '../types/task';

import Preview from '../preview';
import AttachFile from '../attach_file';
import Description from '../description';

import cx from 'classnames';
import styles from './index.module.css';

type Props = {
  setUploadingState: (state: boolean) => void;
  container: HTMLDivElement;
  task: Task;
  accessTo: Types.AccessTo;
  modalContainer: HTMLDivElement;
  modalOuter: HTMLDivElement;
  attachmentsUrl: string;
  removeFile: (file: UploadFile<any>) => void;
  fileList: any[];
  onFileListChange: (fileList: any[]) => void;
  description: string;
  onDescriptionChange: (description: string) => void;
  submitTask: () => any;
  severity: Types.Severity;
  setOverlay: (overlay: Types.Overlay) => void;
  onAnnotationChange: (dataURI: Blob) => void;
  dataURI?: Blob | null;
  isAdminView: boolean;
  hideEmojiIcon?: boolean;
  subscriptionUrl: string;
  owner: {
    id: number;
    email?: string;
  };
};

const LeftPanel: FC<Props> = props => {
  const {
    setUploadingState,
    container,
    task,
    accessTo,
    modalContainer,
    modalOuter,
    attachmentsUrl,
    removeFile,
    fileList,
    onFileListChange,
    description,
    onDescriptionChange,
    submitTask,
    severity,
    setOverlay,
    onAnnotationChange,
    dataURI,
    isAdminView,
    subscriptionUrl,
    owner,
    hideEmojiIcon
  } = props;

  const showScreenshotPreview = !isAdminView;

  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);
  const [caretPosition, setCaretPosition] = useState<number>(0);
  const pickerRef: MutableRefObject<null | NimblePicker> = useRef(null);

  const handleClick = (event: MouseEvent) => {
    try {
      const current = pickerRef.current;
      if (current == null) {
        return;
      }
      const node = findDOMNode(current);
      const target = event.target as Element;
      if (!node?.contains(target)) {
        setShowEmojiPicker(false);
      }
    } catch (err) {
      return;
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => document.removeEventListener('mousedown', handleClick);
  }, []);

  const handleEmojiSelect = (e: any) => {
    onDescriptionChange(
      `${description.slice(0, caretPosition)} ${e.native} ${description.slice(
        caretPosition
      )} `
    );
  };

  return (
    <div className={cx(styles.leftPanel)}>
      <div
        className={cx({
          [styles.descriptionOuter]: true,
          [styles.shortDescriptionOuter]: !!task.screenshotPolyfill
        })}
      >
        <Description
          submitTask={submitTask}
          description={description}
          onDescriptionChange={onDescriptionChange}
          setCaretPosition={setCaretPosition}
          fullHeight={!!task.screenshotPolyfill}
        />
        <div className={styles.descriptionFooter}>
          <div
            className={cx(styles.footerRight, {
              [styles.showPicker]: showEmojiPicker
            })}
          >
            {!hideEmojiIcon && (
              <>
                <div className={styles.emojiOuter}>
                  <Emoji
                    className={styles.emojiIcon}
                    onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                  />
                </div>
                {showEmojiPicker && (
                  <div className={styles.pickerContainer}>
                    <div
                      className={cx(styles.inner, {
                        [styles.adminInner]: isAdminView
                      })}
                    >
                      <NimblePicker
                        autoFocus
                        ref={pickerRef}
                        onSelect={handleEmojiSelect}
                        emojiSize={24}
                        data={data as any}
                        native
                        set="apple"
                        perLine={6}
                        color="#ae65c5"
                        sheetSize={20}
                        showPreview={false}
                        showSkinTones={false}
                        notFoundEmoji="sleuth_or_spy"
                        emojiTooltip
                      />
                    </div>
                  </div>
                )}
              </>
            )}
            <AttachFile
              {...{
                endpoint: attachmentsUrl,
                setUploadingState,
                fileList,
                onFileListChange,
                removeFile,
                modalContainer,
                modalOuter
              }}
            />
          </div>
        </div>
      </div>
      {showScreenshotPreview && task && (
        <Preview
          {...{
            task,
            dataURI,
            container,
            accessTo,
            severity,
            setOverlay,
            owner,
            subscriptionUrl,
            onAnnotationChange
          }}
        />
      )}
    </div>
  );
};

export default LeftPanel;
