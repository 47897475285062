import React, { useState } from 'react';
import { Button, Form, Input, message } from 'antd';

import { getLangKey } from 'models/Language';
import { put } from 'utils/fetch';

import { Project, User } from './types';

import * as translations from './strings';
import styles from './index.module.css';
import Icon from './projectBoardIcon';
import track from 'jsUtilities/analytics';

const strings = translations[getLangKey()];

const NameYourProject = ({
  project,
  user,
  organization
}: {
  project: Project;
  user: User;
  organization: { id: number };
}) => {
  document.title = 'Welcome | BugHerd';
  const intialProjectName = project?.name || strings.namePlaceholder;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [projectName, setProjectName] = useState<string>(intialProjectName);
  const [form] = Form.useForm();
  const firstName =
    user?.name.charAt(0).toUpperCase() + user?.name.slice(1) || 'there';

  const handleSubmit = async () => {
    track('Onboarding named project', {
      modifiedName: projectName !== intialProjectName,
      user: user?.id,
      organization: organization.id
    });

    // if the projectName is empty, redirect to the project kanban without updating the name
    if (projectName.length === 0) {
      window.location.href = `/projects/${project.id}/assets`;
    }
    try {
      setIsSubmitting(true);
      await form.validateFields(['projectName']);
      const response = await put(`/projects/${project.id}`, {
        name: projectName
      });
      if (response) {
        window.location.href = `/projects/${project.id}/assets`;
      }
    } catch (error) {
      console.error(error);
      message.error(strings.error);
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.flexContainerWrapper}>
        <div className={styles.contentWrapper}>
          <h3>
            <span className={styles.waveHand}>👋</span>
            Hi {firstName}
            {strings.welcomeToBugHerd}
          </h3>
          <h4>{strings.setupYourProject}</h4>
          <p className={styles.setupDescription}>
            {strings.createYourFirstProjectDescription}
          </p>
          <p className={styles.projectName}>{strings.projectName}:</p>
          <Form
            form={form}
            className={styles.inputButtonContainer}
            onFinish={handleSubmit}
          >
            <Input
              placeholder={intialProjectName}
              className={styles.nameInput}
              autoFocus
              name="projectName"
              onChange={event => setProjectName(event.target.value)}
              defaultValue={intialProjectName}
            />
            <Button
              type="primary"
              htmlType="submit"
              className={styles.continueButton}
              loading={isSubmitting}
            >
              {strings.continue}
            </Button>
          </Form>
        </div>
        <div className={styles.imageWrapper}>
          <Icon projectName={projectName} />
        </div>
      </div>
    </div>
  );
};

export default NameYourProject;
