import React, { useRef } from 'react';
import { Alert, Button, Tag, Tooltip } from 'antd';
import GreenTick from './images/ic-green-tick.svg';
import styles from './index.module.css';
import { post } from 'utils/fetch';
import track from 'utils/analytics';
import { MemberRole } from 'appJS/models/MemberRole';
import { useCurrentUserState } from '../../../clients/providers/CurrentUser';
import { HarvestIntegration } from 'appJS/components/Integrations/types';
import { useParagonIntegrationsState } from 'appClients/providers/ParagonIntegrations';
import Hint from 'components/Hint';

interface Props {
  project: {
    organizationId: string;
    id: string;
  };
  zapier: {
    id: string;
  };
  legacyIntegrations: object;
  paragonProjectId: string | null;
  editingAllowed: boolean;
  msTeamsEnabled: boolean;
  bugherdUrl: string;
  ownerEmail: string;
  harvestIntegration: HarvestIntegration;
  trialUser: boolean;
  switchModal: Function;
  connectors: object[];
}

declare var paragon: any;

const ParagonSettings: React.FC<Props> = (props: Props) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const betaIntegrations = ['teams'];

  const integrationsWithModals = ['webhooks', 'harvest', 'teams'];

  const { currentUser } = useCurrentUserState();
  const {
    paragonIntegrations,
    setParagonIntegrations
  } = useParagonIntegrationsState();

  const enabledIntegrations = (integrations: any) => {
    return Object.keys(integrations || [])
      .sort()
      .reduce((filtered: string[], integrationName) => {
        const integration = integrations[integrationName];
        if (integration.enabled) {
          filtered.push(integrationName);
        }
        return filtered;
      }, []);
  };

  const updateParagonEnabled = async (enabled: boolean) => {
    const projectId = props.project.id;

    await post(`/projects/${projectId}/update_paragon_status`, { enabled });
  };

  const paragonEnabledState = async () => {
    const ints = Object.assign({}, paragon.getUser().integrations);
    const enabled = enabledIntegrations(ints).filter(
      integration => integration
    );

    setParagonIntegrations(ints);

    if (enabled.length > 0) {
      await updateParagonEnabled(true);
    } else if (enabled.length < 1) {
      await updateParagonEnabled(false);
    }
  };

  const connectIntegration = async (integration: any) => {
    track('Integration connected', {
      integration: integration.integrationType,
      project_id: props.project.id
    });

    await paragonEnabledState();
  };

  const disconnectIntegration = async (integration: any) => {
    track('Integration disconnected', {
      integration: integration.integrationType,
      project_id: props.project.id
    });

    await paragonEnabledState();
  };

  const showIntegration = async (
    integration: string,
    paragonIntegration: boolean
  ) => {
    const legacyIntegrations: any = props.legacyIntegrations;

    if (paragonIntegration) {
      paragon.connect(integration, {
        onSuccess: (event: any) => {
          connectIntegration(event);
        },
        onUninstall: (event: any) => {
          disconnectIntegration(event);
        }
      });
    } else if (integrationsWithModals.includes(integration)) {
      props.switchModal(integration);
      return;
    } else if (
      legacyIntegrations[integration] &&
      legacyIntegrations[integration].enabled
    ) {
      window.location.pathname = `organizations/${props.project.organizationId}/integrations/${legacyIntegrations[integration].id}/edit`;
    } else if (integration === 'zapier' && props.zapier.id) {
      window.location.pathname = `settings/organizations/${props.project.organizationId}/integrations/${props.zapier.id}/edit`;
    } else {
      window.location.pathname = `organizations/${props.project.organizationId}/integrations/new/${integration}`;
    }
  };

  const integrations = () => {
    const integrations: any = props.legacyIntegrations;
    const integrationList = {
      basecamp: {
        id: integrations['basecamp'] && integrations['basecamp'].id,
        enabled: !!integrations['basecamp']
      },
      github: {
        id: integrations['github'] && integrations['github'].id,
        enabled: !!integrations['github']
      },
      slack: {
        id: integrations['slack'] && integrations['slack'].id,
        enabled: !!integrations['slack']
      },
      zapier: {
        id: integrations['zapier'] && integrations['zapier'].id,
        enabled: !!integrations['zapier']
      },
      webhooks: { id: 'webhooks', enabled: true },
      teams: { id: 'teams', enabled: !!integrations['team'] },
      trello: { id: 'trello', enabled: false, loaded: false },
      linear: { id: 'linear', enabled: false, loaded: false },
      asana: { id: 'asana', enabled: false, loaded: false },
      jira: { id: 'jira', enabled: false, loaded: false },
      clickup: { id: 'clickup', enabled: false, loaded: false }
    };
    // add harvest integration if connector exists
    if (props.connectors.find(connector => connector['code'] === 'harvest')) {
      return {
        ...integrationList,
        harvest: {
          id: integrations['harvest'] && integrations['harvest'].id,
          enabled: !!props.harvestIntegration.id
        }
      };
    }
    return integrationList;
  };

  const enabledText = (enabled: any, integrationName: string) => {
    if (enabled && integrationName !== 'webhooks') {
      return (
        <div className={styles.greenTickContainer}>
          <GreenTick className={styles.greenTick} />
          Enabled
        </div>
      );
    }
  };

  const betaText = (integrationName: string) => {
    const betaIntegrationsSet = new Set(
      betaIntegrations.map(item => item.toLowerCase())
    );

    if (betaIntegrationsSet.has(integrationName.toLowerCase())) {
      return (
        <div className={styles.betaContainer}>
          <Tooltip title="This integration is free to use while in Beta. Try it and let us know what you think.">
            <Tag style={{ color: '#3F70E0' }} color="#D9E7F4">
              BETA
            </Tag>
          </Tooltip>
        </div>
      );
    }
  };

  const renderPermissionsMessage = () => {
    if (!props.editingAllowed) {
      return (
        <>
          <Alert
            showIcon
            type="info"
            message="Some integrations require “Manager” permissions to configure."
          />
        </>
      );
    }
  };

  const isButtonEnabled = (loaded: boolean, integrationName: string) => {
    if (!currentUser) {
      return false;
    }
    const currentRole = currentUser.role;
    if (paragonIntegrations !== undefined) {
      const isParagonIntegration = integrationName in paragonIntegrations;
      if (currentRole === MemberRole.MEMBER) {
        return isParagonIntegration;
      }
    }
    return props.editingAllowed && loaded;
  };

  const renderIntegrationList = () => {
    let integrationList = {
      ...integrations(),
      ...paragonIntegrations
    };

    const renderIntegrations = Object.keys(integrationList)
      .sort()
      .map(integrationName => {
        if (integrationName === 'teams' && !props.msTeamsEnabled) {
          return;
        }

        const integration = integrationList[integrationName];
        const enabled = integration.enabled;
        const buttonText = enabled ? 'Manage' : 'Connect';
        const paragonIntegration = paragonIntegrations
          ? Object.keys(paragonIntegrations).includes(integrationName)
          : false;
        const loaded = !!(
          integration.loaded ||
          integration.loaded === undefined ||
          integration.loaded === null
        );

        const integrationNameParsed = integrationName.startsWith('custom.')
          ? integrationName.charAt(7).toUpperCase() + integrationName.slice(8)
          : integrationName.charAt(0).toUpperCase() + integrationName.slice(1);

        return (
          <li key={integrationNameParsed} className={styles.bulletPoint}>
            <span
              className={`integration-icon icon-${integrationName.replace(
                /\./g,
                ''
              )}`}
            />
            {integrationNameParsed}
            <span className={styles.buttonContent}>
              <Button
                type="primary"
                className={styles.connectButton}
                onClick={() =>
                  showIntegration(integrationName, paragonIntegration)
                }
                disabled={!isButtonEnabled(loaded, integrationName)}
              >
                <span className={styles.buttonText}>{buttonText}</span>
              </Button>
              {enabledText(enabled, integrationName)}
              {betaText(integrationName)}
            </span>
          </li>
        );
      });

    return (
      <div ref={modalRef} className={styles.container}>
        {renderPermissionsMessage()}
        <ul>{renderIntegrations}</ul>
      </div>
    );
  };

  const renderIntegrationSurvey = () => {
    if (!currentUser) {
      return false;
    }
    const typeformUrl = `https://bugherd.typeform.com/to/e9bhE6vO#ref=${props.project.organizationId}:${currentUser.id}`;
    return (
      <Hint
        message={
          <div className={styles.suggestIntegrationTooltip}>
            <span>What are we missing?</span>
            <a href={typeformUrl} target="_blank">
              Suggest an integration
            </a>
          </div>
        }
      />
    );
  };

  return (
    <div>
      {renderIntegrationList()}
      {renderIntegrationSurvey()}
    </div>
  );
};

export default (props: Props) => <ParagonSettings {...props} />;
