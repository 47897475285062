import React, { FC, useState } from 'react';
import { detect, BrowserInfo, Browser } from 'detect-browser';
import styles from './index.module.css';
import * as translations from './strings';
import { getLangKey } from 'appJS/models/Language';

import {
  browserConfig,
  BrowserConfigType
} from '../../javascript/components/onboarding/extensionConfig';
import ExtensionInstallButton from '../../javascript/components/onboarding/install_button';
import AddWebsiteModal from './AddWebsiteModal';

const strings = translations[getLangKey()];

type Props = {
  description?: string;
  showJavascriptOption: boolean;
  showExtensionOption: boolean;
};

const InstallExtension: FC<Props> = ({
  description,
  showJavascriptOption,
  showExtensionOption
}) => {
  const browser: BrowserInfo = detect();
  const currentBrowser: Browser = browser.name;
  const [isWebsiteModalOpen, setIsWebsiteModalOpen] = useState(false);
  const container = document.body;

  const getBrowserConfig: () => BrowserConfigType | null = () =>
    currentBrowser === null
      ? null
      : browserConfig.find(browserInfo => browserInfo.name === currentBrowser);

  const handleOpenWebsiteModal = () => {
    setIsWebsiteModalOpen(true);
  };

  const handleCloseWebsiteModal = () => {
    setIsWebsiteModalOpen(false);
  };

  return (
    <div className={styles.installExtension}>
      <div className={styles.pulseIndicator} />
      <span className={styles.textWrapper}>
        <p>{description || strings.dontForget} </p>
        <a onClick={e => e.preventDefault} data-elevio-article="84883">
          <b>{strings.why}</b>
        </a>
      </span>

      {showExtensionOption && (
        <ExtensionInstallButton browserConfig={getBrowserConfig()} />
      )}
      {showJavascriptOption && (
        <b onClick={handleOpenWebsiteModal}>{strings.javascriptCode}</b>
      )}
      <AddWebsiteModal
        container={container}
        isWebsiteModalOpen={isWebsiteModalOpen}
        handleCloseWebsiteModal={handleCloseWebsiteModal}
        activeItem={'javascript'}
      />
    </div>
  );
};

export default InstallExtension;
