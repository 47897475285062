import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import ForgotPassword from './forgot_password';
import Login from './login';
import SingleSignOn from './single_sign_on';
import SignUp from './register_sign_up';
import SignUpEmail from './sign_up_email';
import RegisterSso from './register_sso_page';
import ResetPassword from 'views/reset_password';
import Header from '../../components/user_header';
import { EmailSent } from './login/EmailSent';
import { LoginLink } from './login/LoginLink';

import styles from './index.module.css';
export default (props: any) => {
  return (
    <BrowserRouter basename="/users">
      <div className={styles.container}>
        <div>
          <div>
            <Routes>
              <Route
                path="/passwords/new"
                element={
                  <>
                    <Header />
                    <div className={styles.hero}>
                      <div className={styles.inner}>
                        <ForgotPassword {...props} />
                      </div>
                    </div>
                  </>
                }
              />
              <Route
                path="/sign_in"
                element={
                  <>
                    <Header />
                    <div className={styles.hero}>
                      <div className={styles.inner}>
                        <Login {...props} />
                      </div>
                    </div>
                  </>
                }
              />

              <Route
                path="/sign_in/invite_link"
                element={
                  <>
                    <Header />
                    <div className={styles.hero}>
                      <div className={styles.inner}>
                        <Login {...props} expiredInviteLink />
                      </div>
                    </div>
                  </>
                }
              />

              <Route
                path="/sign_in/sso"
                element={
                  <>
                    <Header />
                    <div className={styles.hero}>
                      <div className={styles.inner}>
                        <SingleSignOn {...props} />
                      </div>
                    </div>
                  </>
                }
              />
              <Route
                path="/sign_in/login_link/email_sent"
                element={
                  <>
                    <Header />
                    <div className={styles.hero}>
                      <div className={styles.inner}>
                        <EmailSent />
                      </div>
                    </div>
                  </>
                }
              />

              {props.loginLinkEnabled && (
                <Route
                  path="/sign_in/login_link"
                  element={
                    <>
                      <Header />
                      <div className={styles.hero}>
                        <div className={styles.inner}>
                          <LoginLink />
                        </div>
                      </div>
                    </>
                  }
                />
              )}

              <Route
                path="/registrations/new"
                element={
                  <div className={styles.signUpExperiment}>
                    <SignUp {...props} />
                  </div>
                }
              />

              <Route
                path="/registrations/sign-up-email"
                element={
                  <div className={styles.signUpExperiment}>
                    <SignUpEmail {...props} />
                  </div>
                }
              />

              <Route
                path="/registrations/sso_sign_up_form"
                element={
                  <>
                    <Header />
                    <div className={styles.hero}>
                      <div className={styles.inner}>
                        <RegisterSso {...props} />
                      </div>
                    </div>
                  </>
                }
              />
              <Route
                path="/passwords/:id/edit"
                element={
                  <>
                    <Header />
                    <div className={styles.hero}>
                      <div className={styles.inner}>
                        <ResetPassword {...props} />
                      </div>
                    </div>
                  </>
                }
              />
            </Routes>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
};
