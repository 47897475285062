import React, { FC } from 'react';
import styles from './styles.module.css';
import Emoji from 'appJS/components/Emoji';
import * as translations from '../../strings';
import { getLangKey } from 'appJS/models/Language';

const strings = translations[getLangKey()];

type Props = {};

const WelcomeBanner: FC<Props> = ({}) => {
  return (
    <div className={styles.welcome}>
      <div className={styles.welcomeAccent} />
      <h2 className={styles.welcomeHeader}>
        <Emoji symbol="👋" />
        {strings.welcomeHeader}
      </h2>
      <p>{strings.sharedWithYou}</p>
      <p>{strings.useBugHerd}</p>
      <p>{strings.getStarted}</p>
    </div>
  );
};

export default WelcomeBanner;
