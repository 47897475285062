import React from 'react';

type ErrorProps = {
  error: any;
};

const openElevio = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
  e.preventDefault();
  //@ts-expect-error
  // eslint-disable-next-line no-unused-expressions
  window._elev?.openHome();
};

const Error = ({ error }: ErrorProps) => {
  //eslint-disable-next-line
  if (window.bugsnagClient) {
    window.bugsnagClient.notify(error);
  } else {
    console.error(error);
  }

  return (
    <>
      <h4 style={{ paddingLeft: '12px' }}>
        Do not worry 😎 and{' '}
        <a onClick={openElevio} href="">
          contact support
        </a>{' '}
        .
      </h4>
      {error && (
        <pre style={{ padding: '12px', maxHeight: '75px', overflow: 'hidden' }}>
          {error.message}
          {error.stack}
        </pre>
      )}
    </>
  );
};

export default Error;
