import * as React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import client from 'api/apollo/client';

import UserActivities from 'views/user_activities';
import UserProjects from 'views/user_projects';
import { GuestProject } from '../views/user_projects/GuestProject';

import * as routes from './routes';
import { App, ConfigProvider } from 'antd';
import { antdTheme } from '../../clients/shared/theme';
import { GuestProjectProvider } from 'appJS/views/user_projects/GuestProject/GuestProjectProvider';
import { Props as SharingProps } from '../views/share_project/types';
import { OrganizationProvider } from 'appClients/providers/Organization';

type Props = {
  container: HTMLDivElement;
  bugherdUrl: string;
  organizationId: number;
  isGuest: boolean;
  sharingProps: SharingProps;
  viewedGuestProjectBoard: boolean;
  viewGuestKanban?: boolean;
};

const DashboardRouter = ({
  container,
  bugherdUrl,
  organizationId,
  isGuest,
  sharingProps,
  viewedGuestProjectBoard,
  viewGuestKanban
}: Props) => {
  return (
    <ApolloProvider client={client}>
      <Router>
        <Routes>
          <Route
            path="/"
            element={() => {
              window.location.href = '/';
              return null;
            }}
          />

          <Route
            path={routes.userActivitiesPath({
              organizationId: ':organizationId'
            })}
            element={<UserActivities />}
          />

          <Route
            path={routes.userProjectsPath({
              organizationId: ':organizationId'
            })}
            element={<UserProjects container={container} />}
          />
          <Route
            path={routes.clientProjectPortalPath({
              projectId: ':projectId',
              organizationId: ':organizationId'
            })}
            element={
              isGuest ? (
                <OrganizationProvider id={organizationId}>
                  <GuestProjectProvider
                    container={container}
                    bugherdUrl={bugherdUrl}
                    organizationId={organizationId}
                    viewedGuestProjectBoard={viewedGuestProjectBoard}
                    viewGuestKanban={!!viewGuestKanban}
                  >
                    <GuestProject sharingProps={sharingProps} />
                  </GuestProjectProvider>
                </OrganizationProvider>
              ) : (
                <Navigate to={`/organizations/${organizationId}/projects`} />
              )
            }
          />
        </Routes>
      </Router>
    </ApolloProvider>
  );
};

const Dashboard = (props: Props) => {
  return (
    <ConfigProvider theme={antdTheme}>
      <App>
        <DashboardRouter {...props} />
      </App>
    </ConfigProvider>
  );
};

export default Dashboard;
