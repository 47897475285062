import React, { useState, FC } from 'react';
import { Switch, message } from 'antd';
import styles from './index.module.css';
import HelpArticleButton from 'utils/HelpArticleButton';
import * as translations from './strings';
import { getLangKey } from 'appJS/models/Language';
import { put } from 'appJS/utils/fetch';
import snakecaseKeys from 'snakecase-keys';
import ArrowUp from '../../../../clients/sidebar/assets/images/ic-upgrade-circle.svg';

const strings = translations[getLangKey()];

interface Project {
  id: number;
  organizationId: number;
  allowProjectSummaryEmail: boolean;
  allowTaskDoneEmail: boolean;
  guestPermission: string;
  allowGuestsChangeTaskStatus: boolean;
}

type Props = {
  project: Project;
  guestsSeeGuestsAccess: boolean;
  guestsEditTaskStatusAllowed: boolean;
};

enum GuestPermissionTypes {
  Permission = 'permission',
  AllowTaskDoneEmail = 'allowTaskDoneEmail',
  AllowProjectSummaryEmail = 'allowProjectSummaryEmail',
  AllowGuestsChangeTaskStatus = 'allowGuestsChangeTaskStatus'
}

type Loading = {
  [key in GuestPermissionTypes]: boolean;
};

const GuestPermissions: FC<Props> = ({
  project,
  guestsSeeGuestsAccess,
  guestsEditTaskStatusAllowed
}) => {
  if (!project) return null;

  const {
    id,
    organizationId,
    guestPermission,
    allowTaskDoneEmail: _allowTaskDoneEmail,
    allowProjectSummaryEmail: _allowProjectSummaryEmail,
    allowGuestsChangeTaskStatus: _allowGuestsChangeTaskStatus
  } = project;

  const [loading, setLoading] = useState<Loading>({
    [GuestPermissionTypes.Permission]: false,
    [GuestPermissionTypes.AllowTaskDoneEmail]: false,
    [GuestPermissionTypes.AllowProjectSummaryEmail]: false,
    [GuestPermissionTypes.AllowGuestsChangeTaskStatus]: false
  });

  const [permission, setPermission] = useState(guestPermission);
  const [
    allowGuestsChangeTaskStatus,
    setallowGuestsChangeTaskStatus
  ] = useState(_allowGuestsChangeTaskStatus ?? true);
  const [allowProjectSummaryEmail, setAllowProjectSummaryEmail] = useState(
    _allowProjectSummaryEmail ?? true
  );
  const [allowTaskDoneEmail, setAllowTaskDoneEmail] = useState(
    _allowTaskDoneEmail ?? true
  );

  const updateProject = async (
    body: {
      allowTaskDoneEmail?: boolean;
      allowProjectSummaryEmail?: boolean;
      permission?: 'guests_see_guests' | 'guests_see_self';
      allowGuestsChangeTaskStatus?: boolean;
    },
    loading: GuestPermissionTypes,
    onError
  ) => {
    setLoading(previousValue => ({ ...previousValue, [loading]: true }));
    const response = await put(
      `/projects/${id}.json`,
      snakecaseKeys(body, { deep: true })
    );
    if (!response.ok) {
      onError();
      message.error(
        'Something went wrong while updating the project, please try again.'
      );
    }
    setLoading(previousValue => ({ ...previousValue, [loading]: false }));
  };

  const subscriptionEndpoint = `/organizations/${organizationId}/subscription`;

  const canEditGuestAccess =
    guestsSeeGuestsAccess && guestsEditTaskStatusAllowed;

  return (
    <div className={styles.notifications}>
      <b>{strings.guestCollaboration}</b>
      <div className={styles.guestAccessContainer}>
        <div className={styles.seeOtherFeedback}>
          {!canEditGuestAccess && (
            <div
              className={styles.upgradeAlert}
              onClick={() => window.open(subscriptionEndpoint, '_self')}
            >
              <ArrowUp className={styles.upgradeIcon} />
              <span className={styles.upgradeText}>
                {strings.onlyAvailableOnPremium}
                <a href={subscriptionEndpoint}>{strings.upgradeNow}</a>
              </span>
            </div>
          )}
          <div className={styles.switchWrapper}>
            <Switch
              disabled={!canEditGuestAccess}
              loading={loading[GuestPermissionTypes.Permission]}
              className={styles.switch}
              onChange={checked => {
                const newPermission = checked
                  ? 'guests_see_guests'
                  : 'guests_see_self';
                setPermission(newPermission);
                updateProject(
                  { permission: newPermission },
                  GuestPermissionTypes.Permission,
                  () =>
                    setPermission(
                      checked ? 'guests_see_self' : 'guests_see_guests'
                    )
                );
              }}
              checked={permission === 'guests_see_guests'}
            />
            <span className={styles.spanLabel}>
              {strings.allowGuestSeeGuestsFeedback}{' '}
              <HelpArticleButton articleId="84854" />
            </span>
          </div>
        </div>
        <div className={styles.changeTaskStatus}>
          <div className={styles.switchWrapper}>
            <Switch
              disabled={!canEditGuestAccess}
              className={styles.switch}
              loading={
                loading[GuestPermissionTypes.AllowGuestsChangeTaskStatus]
              }
              checked={allowGuestsChangeTaskStatus}
              onChange={checked => {
                setallowGuestsChangeTaskStatus(checked);
                updateProject(
                  { allowGuestsChangeTaskStatus: checked },
                  GuestPermissionTypes.AllowGuestsChangeTaskStatus,
                  () => setallowGuestsChangeTaskStatus(!checked)
                );
              }}
            />
            <span className={styles.spanLabel}>
              {strings.allowGuestChangeTaskStatus}{' '}
              <HelpArticleButton articleId="85000" />
            </span>
          </div>
        </div>
      </div>

      <b>{strings.guestNotifications}</b>
      <span className={styles.helpArticle}>
        <HelpArticleButton articleId="85000" />
      </span>

      <div className={styles.switchWrapper}>
        <Switch
          checked={allowProjectSummaryEmail}
          loading={loading[GuestPermissionTypes.AllowProjectSummaryEmail]}
          onChange={checked => {
            setAllowProjectSummaryEmail(checked);
            updateProject(
              { allowProjectSummaryEmail: checked },
              GuestPermissionTypes.AllowProjectSummaryEmail,
              () => setAllowProjectSummaryEmail(!checked)
            );
          }}
        />
        <span className={styles.switchText}>{strings.projectSummaryEmail}</span>
      </div>
      <div className={styles.switchWrapper}>
        <Switch
          checked={allowTaskDoneEmail}
          loading={loading[GuestPermissionTypes.AllowTaskDoneEmail]}
          onChange={checked => {
            setAllowTaskDoneEmail(checked);
            updateProject(
              { allowTaskDoneEmail: checked },
              GuestPermissionTypes.AllowTaskDoneEmail,
              () => setAllowTaskDoneEmail(!checked)
            );
          }}
        />
        <span className={styles.switchText}>{strings.taskDoneEmail}</span>
      </div>
    </div>
  );
};

export default GuestPermissions;
