export const userActivitiesPath = ({
  organizationId
}: {
  organizationId: string | number;
}) => `/organizations/${organizationId}/dashboard`;

export const userProjectsPath = ({
  organizationId
}: {
  organizationId: string | number;
}) => `/organizations/${organizationId}/projects`;

export const newProjectPath = ({
  organizationId
}: {
  organizationId: string | number;
}) => `/organizations/${organizationId}/projects/new`;

export const kanbanTaskPath = ({
  projectId,
  localTaskId
}: {
  projectId: number | string;
  localTaskId: number | string;
}) => `/projects/${projectId}/tasks/${localTaskId}`;

export const subscriptionOrganizationPath = ({
  organizationId
}: {
  organizationId: string | number;
}) => `/organizations/${organizationId}/subscription`;

export const clientProjectPortalPath = ({
  projectId,
  organizationId
}: {
  projectId: string | number;
  organizationId: string | number;
}) => `/organizations/${organizationId}/projects/${projectId}`;
