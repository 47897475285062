import { post, get, put, deleteItem } from 'appJS/utils/fetch';
import { message } from 'antd';

export const getProjectSites = async (
  apiKey: number | string,
  projectId: string
) => {
  try {
    const response = await get(
      `/projects/${projectId}/project_sites?apikey=${apiKey}`
    );
    return response;
  } catch (e) {
    message.info('There was an error getting your website urls');
  }
};

export const addProjectSites = async (
  apiKey: number,
  projectUrl: string,
  projectId: string
) => {
  try {
    const response = await post(`/projects/${projectId}/project_sites`, {
      url: projectUrl,
      apikey: apiKey
    });
    return response;
  } catch (e) {
    console.error(e);
    message.error(
      'There was an error with adding your website url. Please try again.'
    );
  }
};

export const editProjectSites = async (
  apiKey: string,
  updatedSite: string,
  id: string,
  projectId: string
) => {
  try {
    const response = await put(`/projects/${projectId}/project_sites/${id}`, {
      url: updatedSite,
      apikey: apiKey,
      id
    });
    return response;
  } catch (e) {
    console.error(e);
    message.error(
      'There was an error with editing your website url. Please try again.'
    );
  }
};

export const deleteProjectSites = async (
  apiKey: string,
  id: string,
  projectId: string
) => {
  try {
    const response = await deleteItem(
      `/projects/${projectId}/project_sites/${id}?apikey=${apiKey}`
    );
    return response;
  } catch (e) {
    console.error(e);
    message.error('There was an error with deleting your website url.');
  }
};
