import React, { FC } from 'react';
import styles from './styles.module.css';
import { Button, Input, Tooltip, Space } from 'antd';
import * as translations from '../../strings';
import { getLangKey } from 'appJS/models/Language';
import Avatar, { Member } from '../../../../../../clients/shared/Avatar';
import cx from 'classnames';
import { handleCopy } from '../../handleCopy';
import { useGuestProjectState } from '../../GuestProjectProvider';

const strings = translations[getLangKey()];

const QuickLinks: FC = () => {
  const { data, bugherdUrl, container } = useGuestProjectState();
  const shareKey = data?.currentUser?.userProject?.shareKey;
  const owner = data?.currentUser?.userProject?.organization?.owner;

  return (
    <div className={styles.links}>
      <h2 className={styles.linksHeader}>{strings.linksHeader}</h2>
      <div
        className={cx(styles.linksInner, {
          [styles.hasShareKey]: shareKey
        })}
      >
        {owner && (
          <div className={styles.owner}>
            <div className={styles.ownerInfo}>
              <Avatar
                member={owner as Member}
                size="small"
                className={styles.avatar}
              />
              <span className={styles.ownerName}>
                {owner.name} ({strings.owner})
              </span>
            </div>
            <Button
              type="link"
              className={styles.contact}
              href={`mailto:${owner.email}`}
            >
              {strings.contact}
            </Button>
          </div>
        )}
        {shareKey && (
          <div className={styles.shareProject}>
            <h4 className={styles.shareHeader}>{strings.shareProject}</h4>
            <Space.Compact className={styles.inputGroup}>
              <Input
                readOnly
                value={`${bugherdUrl}/invitation/${shareKey}`}
                className={styles.input}
              />
              <Tooltip
                title={strings.copyShareUrl}
                getPopupContainer={() => container}
              >
                <Button
                  type="default"
                  onClick={() =>
                    handleCopy({
                      text: `${bugherdUrl}/invitation/${shareKey}`,
                      successMessage: strings.copyInvitationSuccess
                    })
                  }
                  className={styles.copyButton}
                >
                  {strings.copy}
                </Button>
              </Tooltip>
            </Space.Compact>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuickLinks;
