import * as React from 'react';
import Video from '../../../../../../javascript/components/video';
import { CloseOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import styles from './style.module.css';

type Props = {
  showVideo: boolean;
  videoBlob: Blob;
  container: HTMLElement;
  onClose: () => void;
};

const VideoPreview: React.FC<Props> = ({
  showVideo,
  videoBlob,
  container,
  onClose,
  children
}) => (
  <div>
    {children}
    <Modal
      className={styles.videoPreview}
      open={showVideo}
      getContainer={() => container}
      onCancel={onClose}
      destroyOnClose
      footer={null}
      width="80%"
      centered
      closeIcon={<CloseOutlined className={styles.closeIcon} />}
    >
      <Video url={URL.createObjectURL(videoBlob)} />
    </Modal>
  </div>
);

export default VideoPreview;
