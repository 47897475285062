import { getCurrentProjectInstallVerification } from '../models/Project';

export async function pollInstallJsVerified() {
  return await new Promise(resolve => {
    let totalLoops = 20;
    let installVerificationTimer = setInterval(() => {
      totalLoops--;
      const projectData = getCurrentProjectInstallVerification();
      if (projectData.javascriptInstalled) {
        clearInterval(installVerificationTimer);
        resolve({
          javascriptInstalled: projectData.javascriptInstalled,
          siteErrors: projectData.siteErrors
        });
      }
      if (projectData.siteErrors && projectData.siteErrors.length > 0) {
        clearInterval(installVerificationTimer);
        resolve({
          javascriptInstalled: projectData.javascriptInstalled,
          siteErrors: projectData.siteErrors
        });
      }
      if (totalLoops === 0) {
        clearInterval(installVerificationTimer);
        resolve({});
      }
    }, 200);
  });
}
