import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';

const useNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const ignoreEvent = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleOrgClick = (event, href: string) => {
    ignoreEvent(event);
    const url = new URL(href);
    navigate(`${url.pathname}/projects`);
  };

  const handleProjectClick = (event, href: string) => {
    ignoreEvent(event);
    const url = new URL(href);
    navigate(location.pathname.replace(/(\/projects\/\d+)/g, url.pathname));
  };

  const getLinkElements = (selector: string) =>
    Array.from(document.querySelectorAll(selector)) as HTMLAnchorElement[];

  useEffect(() => {
    const orgAndMyProjectsLinks = getLinkElements('.org-link').concat(
      getLinkElements('.my-projects-link')
    );
    const projectLinks = getLinkElements('.my-project-link');

    orgAndMyProjectsLinks.forEach(orgLink =>
      orgLink.addEventListener('click', event =>
        handleOrgClick(event, orgLink.href)
      )
    );

    projectLinks.forEach(projectLink =>
      projectLink.addEventListener('click', event =>
        handleProjectClick(event, projectLink.href)
      )
    );

    return () => {
      orgAndMyProjectsLinks.forEach(orgLink =>
        orgLink.removeEventListener('click', event =>
          handleOrgClick(event, orgLink.href)
        )
      );

      projectLinks.forEach(projectLink =>
        projectLink.removeEventListener('click', event =>
          handleProjectClick(event, projectLink.href)
        )
      );
    };
  }, []);
};

export default useNavigation;
